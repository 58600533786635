"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMPTY_LAYOUT = exports.nameLayout = exports.ROWS = void 0;
exports.ROWS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14];
function nameLayout(layout) {
    return `${layout.sbd.join(",")}SBD ${layout.new_gen.join(",")}NewGen`;
}
exports.nameLayout = nameLayout;
exports.EMPTY_LAYOUT = {
    sbd: [],
    new_gen: [],
};
