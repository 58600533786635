import axios, { AxiosRequestConfig } from "axios";
import { assign }  from "lodash";
import { Auth } from "aws-amplify";

const getAPI = async (config: AxiosRequestConfig = {}) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: token && `Bearer ${token}`,
  };
  const headers = assign({}, defaultHeaders, config.headers)
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    ...config,
    headers: headers,
  });
};

export default getAPI;
