import _ from "lodash";
import { FC, useEffect, useRef } from "react";
import { Timeline as VisTimeline, DataSet } from "vis-timeline/standalone";
import { Moment } from "moment";
import * as d3 from "d3";

interface TimelineProps {
  day: Moment;
  data: Array<{
    rowName: string;
    events: Array<{
      from: Date;
      to: Date;
      color: string;
      label: string;
    }>;
  }>;
}
const TimelineDetails: FC<TimelineProps> = ({ data, day }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    new VisTimeline(
      ref.current,
      new DataSet(
        _(data)
          .map((d, i) => d.events.map((e, j) => ({ id: `${i}-${j}`, content: e.label, start: e.from, end: e.to, group: d.rowName })))
          .flatten()
          .value()
      ),
      new DataSet(
        _(data)
          .map((d, i) => ({ value: d.rowName, id: d.rowName, content: d.rowName }))
          .value()
      ),
      {
        zoomable: false,
        min: day.startOf("day").toDate(),
        max: day.endOf("day").toDate(),
        height: 500,
        verticalScroll: true,
      }
    );
    return () => {
      d3.select(ref.current).selectAll("*").remove();
    };
  }, [data, ref]);
  return (
    <div>
      <div ref={ref} />
    </div>
  );
};

export default TimelineDetails;
