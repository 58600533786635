import React from "react";
import LocationMap from "../../LocationMap/LocationMap";

function OperationalSimulationsSelector() {
  return (
    <div style={{ maxHeight: "calc(100% - 64px)", height: "100%", backgroundColor: "white" }}>
      <LocationMap />
    </div>
  );
}

export default OperationalSimulationsSelector;
