"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInfrastructureId = exports.findInfrastructureTemplate = exports.infrastructureTemplates = void 0;
const current_1 = require("./current");
const future_1 = require("./future");
const generic_1 = require("./generic");
const screening_1 = require("./screening");
const strategic_1 = require("./strategic");
const taxi_1 = require("./taxi");
exports.infrastructureTemplates = [
    current_1.CURRENT_INFRASTRUCTURE,
    future_1.FUTURE_INFRASTRUCTURE,
    screening_1.SCREENING_INFRASTRUCTURE,
    strategic_1.STRATEGIC_INFRASTRUCTURE,
    ...taxi_1.TAXI_INFRASTRUCTURES,
    ...generic_1.GENERIC_INFRASTRUCTURES,
];
const findInfrastructureTemplate = (id) => exports.infrastructureTemplates.find((i) => i.id === id);
exports.findInfrastructureTemplate = findInfrastructureTemplate;
function isInfrastructureId(str) {
    return exports.infrastructureTemplates.map((i) => i.id).includes(str);
}
exports.isInfrastructureId = isInfrastructureId;
__exportStar(require("./misc"), exports);
__exportStar(require("./defaults"), exports);
