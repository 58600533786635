"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_SCREENING_FASTLANE_PROPORTION = exports.DEFAULT_SCREENING_HANDLING_TIME = exports.DEFAULT_SCREENING_THROUGHPUT = exports.DEFAULT_PLANNING = exports.DEFAULT_PLANNING__HOUR = exports.DEFAULT_PLANNING_HALF_HOUR = exports.DEFAULT_SCREENING_PASSENGER_PROPORTION = exports.DEFAULT_EU_PAX_PROPORTION = exports.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE = exports.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION = exports.DEFAULT_DEPARTURE_BORDER_A_T_PASSENGER_PROPORTION = exports.DEFAULT_ARRIVAL_PASSENGER_PROPORTION = exports.DEFAULT_ARRIVAL_PIER_B_PASSENGER_PROPORTION = exports.DEFAULT_TRANSFER_NOSS_PASSENGER_PROPORTION = void 0;
exports.DEFAULT_TRANSFER_NOSS_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: 0.2,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 0.8,
        label: "Third Country Nationals",
    },
];
exports.DEFAULT_ARRIVAL_PIER_B_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: 0.7,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 0.3,
        label: "Third Country Nationals",
    },
];
exports.DEFAULT_ARRIVAL_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: 0.55,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 0.45,
        label: "Third Country Nationals",
    },
];
exports.DEFAULT_DEPARTURE_BORDER_A_T_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: 0.4,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 0.6,
        label: "Third Country Nationals",
    },
];
exports.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION = [
    {
        id: "bag",
        proportion: 0.65,
        label: "With bags",
        subgroups: [
            {
                id: "ci",
                proportion: 0.8,
                label: "Off-hall check-in",
                subgroups: [
                    {
                        id: "bagtag",
                        proportion: 0.67,
                        label: "Off-hall bag tag",
                    },
                    {
                        id: "nobagtag",
                        proportion: 0.33,
                        label: "In-hall bag tag",
                    },
                ],
            },
            {
                id: "noci",
                proportion: 0.2,
                label: "In-hall check-in",
                subgroups: [
                    {
                        id: "convci",
                        proportion: 0.33,
                        label: "Conventional Check-in",
                    },
                    {
                        id: "diyci",
                        proportion: 0.67,
                        label: "DIY Check-in",
                    },
                ],
            },
        ],
    },
    {
        id: "nobag",
        proportion: 0.35,
        label: "Without bags",
    },
];
exports.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE = [
    {
        id: "bag_ci_bagtag_pax_leisure",
        label: "Leisure pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_leisure",
        label: "Leisure pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_leisure",
        label: "Leisure pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_leisure",
        label: "Leisure pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_longhaul",
        label: "Long haul pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_longhaul",
        label: "Long haul pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_longhaul",
        label: "Long haul pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_longhaul",
        label: "Long haul pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_shorthaul",
        label: "Short haul pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_shorthaul",
        label: "Short haul pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_shorthaul",
        label: "Short haul pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_shorthaul",
        label: "Short haul pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_hclonghaul",
        label: "Long haul HC pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_hclonghaul",
        label: "Long haul HC pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_hclonghaul",
        label: "Long haul HC pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_hclonghaul",
        label: "Long haul HC pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_hcshorthaul",
        label: "Short haul HC pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_hcshorthaul",
        label: "Short haul HC pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_hcshorthaul",
        label: "Short haul HC  pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_hcshorthaul",
        label: "Short haul HC pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_lowcost",
        label: "Low cost pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_lowcost",
        label: "Low cost pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_lowcost",
        label: "Low cost pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_lowcost",
        label: "Low cost pax, conventional check-in",
    },
];
exports.DEFAULT_EU_PAX_PROPORTION = 0.7;
exports.DEFAULT_SCREENING_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: exports.DEFAULT_EU_PAX_PROPORTION,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 1 - exports.DEFAULT_EU_PAX_PROPORTION,
        label: "Third Country Nationals",
    },
];
exports.DEFAULT_PLANNING_HALF_HOUR = Array.from({ length: 48 }, (_, i) => ({
    hour: i,
    open_items: 0,
}));
exports.DEFAULT_PLANNING__HOUR = Array.from({ length: 24 }, (_, i) => ({
    hour: i,
    open_items: 0,
}));
exports.DEFAULT_PLANNING = [
    {
        hour: 0,
        open_items: 0,
    },
    {
        hour: 1,
        open_items: 0,
    },
    {
        hour: 2,
        open_items: 0,
    },
    {
        hour: 3,
        open_items: 0,
    },
    {
        hour: 4,
        open_items: 0,
    },
    {
        hour: 5,
        open_items: 0,
    },
    {
        hour: 6,
        open_items: 0,
    },
    {
        hour: 7,
        open_items: 0,
    },
    {
        hour: 8,
        open_items: 0,
    },
    {
        hour: 9,
        open_items: 0,
    },
    {
        hour: 10,
        open_items: 0,
    },
    {
        hour: 11,
        open_items: 0,
    },
    {
        hour: 12,
        open_items: 0,
    },
    {
        hour: 13,
        open_items: 0,
    },
    {
        hour: 14,
        open_items: 0,
    },
    {
        hour: 15,
        open_items: 0,
    },
    {
        hour: 16,
        open_items: 0,
    },
    {
        hour: 17,
        open_items: 0,
    },
    {
        hour: 18,
        open_items: 0,
    },
    {
        hour: 19,
        open_items: 0,
    },
    {
        hour: 20,
        open_items: 0,
    },
    {
        hour: 21,
        open_items: 0,
    },
    {
        hour: 22,
        open_items: 0,
    },
    {
        hour: 23,
        open_items: 0,
    },
    {
        hour: 24,
        open_items: 0,
    },
    {
        hour: 24,
        open_items: 0,
    },
];
exports.DEFAULT_SCREENING_THROUGHPUT = 96; // pax / 30'
exports.DEFAULT_SCREENING_HANDLING_TIME = (60 * 30) / exports.DEFAULT_SCREENING_THROUGHPUT;
exports.DEFAULT_SCREENING_FASTLANE_PROPORTION = 0.08;
