"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfrastructureFactory = exports.InfrastructureCategories = void 0;
var InfrastructureCategories;
(function (InfrastructureCategories) {
    InfrastructureCategories[InfrastructureCategories["Operational"] = 0] = "Operational";
    InfrastructureCategories[InfrastructureCategories["Planification"] = 1] = "Planification";
})(InfrastructureCategories = exports.InfrastructureCategories || (exports.InfrastructureCategories = {}));
/**
 * The Factory map a behaviour for each infrastructure
 * It has the responsability to fail if an insfrastructure is not handled
 */
const InfrastructureFactory = (behaviours) => (i) => {
    return behaviours[i];
};
exports.InfrastructureFactory = InfrastructureFactory;
