"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FUTURE_INFRASTRUCTURE = void 0;
const defaults_1 = require("./defaults");
const misc_1 = require("./misc");
exports.FUTURE_INFRASTRUCTURE = {
    id: "departure-hall-future",
    category: misc_1.InfrastructureCategories.Planification,
    name: "Check-in future",
    airport_component: "DepartureFuture",
    start_point: "entry",
    passenger_proportions: defaults_1.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION,
    passenger_types: defaults_1.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE,
    entities: [
        {
            name: "checkin-kiosk",
            process_order: 0,
            display_order: 2,
            label: "Check-in Kiosks",
            type: "entity",
            elements: [
                {
                    name: "checkin-kiosk-element",
                    label: "Check-in Kiosk",
                    n_entities: 14,
                    min_entities: 0,
                    max_entities: 100,
                    planning: [],
                    planning_unit: "half-hour",
                    passenger_types: [
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 60,
                            passenger_type: "bag_noci_diyci_pax_leisure",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 60,
                            passenger_type: "bag_noci_diyci_pax_longhaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 60,
                            passenger_type: "bag_noci_diyci_pax_shorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 60,
                            passenger_type: "bag_noci_diyci_pax_hclonghaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 60,
                            passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 60,
                            passenger_type: "bag_noci_diyci_pax_lowcost",
                        },
                    ],
                },
            ],
            queues: {
                "checkin-kiosk-element": ["checkin-kiosk-element"],
            },
        },
        {
            name: "bagtag-kiosk",
            label: "Bag Tag Printing kiosk",
            process_order: 1,
            display_order: 1,
            type: "entity",
            elements: [
                {
                    name: "bagtag-kiosk-elements",
                    label: "Bag Tag Printing kiosk",
                    n_entities: 88,
                    min_entities: 0,
                    max_entities: 200,
                    planning: [],
                    planning_unit: "half-hour",
                    passenger_types: [
                        {
                            source: "checkin-kiosk",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_noci_diyci_pax_leisure",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_ci_nobagtag_pax_leisure",
                        },
                        {
                            source: "checkin-kiosk",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_noci_diyci_pax_longhaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_ci_nobagtag_pax_longhaul",
                        },
                        {
                            source: "checkin-kiosk",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_noci_diyci_pax_shorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                        },
                        {
                            source: "checkin-kiosk",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_noci_diyci_pax_hclonghaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                        },
                        {
                            source: "checkin-kiosk",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                        },
                        {
                            source: "checkin-kiosk",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_noci_diyci_pax_lowcost",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 90,
                            passenger_type: "bag_ci_nobagtag_pax_lowcost",
                        },
                    ],
                },
            ],
            queues: {
                "bagtag-kiosk-elements": ["bagtag-kiosk-elements"],
            },
        },
        {
            name: "bagdrop",
            process_order: 2,
            display_order: 0,
            label: "Bag Drop Desk",
            type: "entity",
            elements: [
                {
                    name: "bagdrop-all",
                    label: "Bag Drop Desk - All airlines",
                    n_entities: 52,
                    min_entities: 0,
                    max_entities: 200,
                    planning: [],
                    planning_unit: "half-hour",
                    passenger_types: [
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_bagtag_pax_leisure",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_nobagtag_pax_leisure",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_noci_diyci_pax_leisure",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_bagtag_pax_longhaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_nobagtag_pax_longhaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_noci_diyci_pax_longhaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_bagtag_pax_shorthaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_noci_diyci_pax_shorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_bagtag_pax_hclonghaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_noci_diyci_pax_hclonghaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_bagtag_pax_hcshorthaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_bagtag_pax_lowcost",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_ci_nobagtag_pax_lowcost",
                        },
                        {
                            source: "bagtag-kiosk",
                            frac: 1,
                            handling_time: 45,
                            passenger_type: "bag_noci_diyci_pax_lowcost",
                        },
                    ],
                },
            ],
            queues: {
                "bagdrop-all": ["bagdrop-all"],
            },
        },
        {
            name: "conv-ci",
            process_order: 3,
            display_order: 3,
            label: "Conventional Check-in Desk",
            type: "entity",
            elements: [
                {
                    name: "conv-ci-desk",
                    label: "Conventional Check-in Desk",
                    n_entities: 77,
                    min_entities: 0,
                    max_entities: 200,
                    planning: [],
                    planning_unit: "half-hour",
                    passenger_types: [
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 109,
                            passenger_type: "bag_noci_convci_pax_leisure",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 109,
                            passenger_type: "bag_noci_convci_pax_longhaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 109,
                            passenger_type: "bag_noci_convci_pax_shorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 109,
                            passenger_type: "bag_noci_convci_pax_hclonghaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 109,
                            passenger_type: "bag_noci_convci_pax_hcshorthaul",
                        },
                        {
                            source: "entry",
                            frac: 1,
                            handling_time: 109,
                            passenger_type: "bag_noci_convci_pax_lowcost",
                        },
                    ],
                },
            ],
            queues: {
                "conv-ci-desk": ["conv-ci-desk"],
            },
        },
    ],
};
