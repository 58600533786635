import {
  CheckCircleOutlined,
  DeleteTwoTone,
  EyeOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { Button, Popconfirm, Table, Tag } from "antd";
import { useNavigate } from "react-router";
import { AVAILABLE, FAILED, NEW, PROCESSING } from "shared/Simulation";
import { useSearchParams } from "react-router-dom";
import "./SimulationParameters.css";
import ParameterForm from "./ParameterForm/ParameterForm";
import useStrategicSimulations from "../../hooks/useStrategicSimulations";
import getAPI from "../../services/api";

function SimulationStatusInfo({ status }) {
  return (
    {
      [AVAILABLE]: (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Available
        </Tag>
      ),
      [PROCESSING]: (
        <Tag icon={<SyncOutlined spin />} color="processing">
          Processing
        </Tag>
      ),
      [FAILED]: <Tag color="red">Error</Tag>,
    }[status] || (
      <Tag icon={<QuestionCircleOutlined />} color="grey">
        Unknown
      </Tag>
    )
  );
}

const SimulationTable = () => {
  const [searchParams] = useSearchParams();
  const infrastructureId = searchParams.get("infrastructureId");

  const {
    isLoading: isSimulationsLoading,
    simulations,
    refetch: refetchSimulationList,
    error: simulationsError,
    key: simulationsQueryKey,
  } = useStrategicSimulations();

  if (!!simulationsError) {
    throw simulationsError;
  }

  const deleteSimulation = async (id) => {
    const api = await getAPI();
    await api.delete(`/strategic-simulation/${id}`);
    await refetchSimulationList();
  };

  const [selectedSimulation, setSelectedSimulation] = useState<any>();

  const newSimulation = {
    name: "",
    status: NEW,
    parameters: {
      date: "",
      sbd_allocations: {},
      conventional_checkin_handling_time: 107,
      self_checkin_handling_time: 60,
      bag_tag_handling_time: 50,
      bag_drop_handling_time: 60,
      assistance_desk_handling_time: 150,
      assistance_self_checkin_ratio: 0.07,
      assistance_bag_tag_ratio: 0.07,
      assistance_bag_drop_ratio: 0.07,
      business_pax_ratio: 0.05,
      online_checkin_ratio: 0.2,
      pax_having_bag_ratio: 0.7,
      airline_handling_times: {
        "SN - Short Haul": 120,
        "SN - Long Haul": 180,
        default: 109,
      },
      sbd_zone_n_servers: {
        "check-in-kiosk": 3,
        "bag-tag-kiosk": 10,
        "bag-drop": 6,
        "assistance-desk": 1,
      },
      flight_allocation_path: "",
    },
  };

  const navigate = useNavigate();

  return (
    <div style={{ padding: 20, marginBottom: 30 }}>
      {!infrastructureId && (
        <div style={{ textAlign: "center", marginTop: 100 }}>
          <h5>Select an infrastructure!</h5>
        </div>
      )}
      {infrastructureId && (
        <>
          <Button
            style={{ marginBottom: 15, float: "right" }}
            onClick={() => {
              setSelectedSimulation(newSimulation);
            }}
          >
            <PlusOutlined />
            Create new simulation
          </Button>

          <Table
            loading={isSimulationsLoading}
            dataSource={(simulations || []).map((d) => ({ ...d, key: d.id }))}
            columns={[
              {
                dataIndex: "simulationName",
                width: 20,
                render: (name, simulation: any) => (
                  <Button type="link" onClick={() => setSelectedSimulation(simulation)}>
                    <FormOutlined />
                  </Button>
                ),
              },
              {
                dataIndex: "parameters",
                width: 20,
                render: (parameters, simulation) => {
                  return (
                    <Button
                      type="link"
                      onClick={() =>
                        setSelectedSimulation({
                          status: NEW,
                          name: "Copy from " + simulation.name,
                          id: undefined,
                          parameters,
                        })
                      }
                    >
                      <FileAddOutlined />
                    </Button>
                  );
                },
              },
              {
                dataIndex: "status",
                width: 20,
                render: (status, simulation) => (
                  <Button
                    type="link"
                    onClick={() => navigate(`/simulations/${simulation.id}?infrastructureId=check-in-strategic`)}
                    disabled={status !== AVAILABLE}
                  >
                    <EyeOutlined />
                  </Button>
                ),
              },
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (status, { id }) => <SimulationStatusInfo status={status} />,
              },
              {
                title: "Last updated by",
                dataIndex: "author",
                key: "author",
              },
              {
                dataIndex: "id",
                width: 20,
                render: (id) => (
                  <Popconfirm title="Are you sure ?" placement="left" onConfirm={() => deleteSimulation(id)} okText="Yes" cancelText="No">
                    <DeleteTwoTone twoToneColor="red" />
                  </Popconfirm>
                ),
              },
            ]}
          />
        </>
      )}
      {selectedSimulation && (
        <ParameterForm
          initialValues={selectedSimulation}
          simulations={simulations}
          onClose={async () => {
            setSelectedSimulation(null);
            await refetchSimulationList();
          }}
        />
      )}
    </div>
  );
};

export default SimulationTable;
