import React, { useContext, useEffect, useMemo } from "react";
import { SimulationStrategic } from "shared/Simulation";
import * as d3 from "d3";
import { SocialDistancingSelectionContext } from "../Selections/SocialDistancingSelection";
import * as AirportSchemas from "../plots/QueueChart/AirportSchemas.figma";
import { findInfrastructureTemplate } from "shared/Infrastructure";
import { useResizeDetector } from "react-resize-detector";
import { ROWS } from "shared/StrategicLayout";
import { getPaxPosition, getQueueCapacity } from "../plots/QueueChart/helpers/queueSvg";
import { OVERFLOW_AREA_BINDING } from "../plots/QueueChart/helpers/overflow";

const selectId = (id: string) =>
  function (this: unknown) {
    return d3.select(this).select(`[id='${id}'], [id^='${id}_']`).node();
  };

type SBDQueueChartProps = {
  simulation?: SimulationStrategic | null;
  timestamp?: Date;
  layout?: any;
  colorScale?: d3.ScaleOrdinal<string, string>;
  stackBy: string;
};
const SBDQueueChart: React.FC<SBDQueueChartProps> = ({ simulation, timestamp, colorScale, layout, stackBy }) => {
  const [socialDistancing] = useContext(SocialDistancingSelectionContext);
  const queues: Array<{
    row: number;
    desk: number | "checkin" | "bagtag" | "bagdrop";
    color?: string;
    amount: number;
  }> = useMemo(() => {
    if (!simulation?.result || !timestamp) return [];
    const allocations = simulation.result.allocations;
    const queues = simulation.result.dataPoints
      .filter((dp) => {
        return dp.timestamp.getTime() === Math.round(timestamp.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000);
      })
      .filter((dataPoint) => typeof dataPoint !== "undefined")
      .map((dataPoint) => {
        const allocation = allocations.find((allocation) => {
          return allocation.allocationIndex === dataPoint.allocationIndex && allocation.allocationType === dataPoint.allocationType;
        });
        if (!allocation) {
          return [];
        }
        if (allocation.allocationType === "sbd") {
          let row = 0;
          const matchRow = dataPoint.elementType.match(/-(\d+)-/);

          if (matchRow && matchRow[1]) {
            row = parseInt(matchRow[1]);
          }

          const elementType = dataPoint.elementType.replace(/sbd-\d+-/g, "");

          const deskMap = {
            "check-in-kiosk-queue": "checkin",
            "assistance-desk-queue": "assistance",
            "bag-drop-queue": "bagdrop",
            "bag-tag-kiosk-queue": "bagtag",
          };

          return {
            row: row,
            desk: deskMap[elementType],
            color: stackBy === "allocation" ? colorScale(allocation.company) : colorScale(row),
            amount: Math.ceil(dataPoint.queueSize),
          };
        } else {
          /** dispatching pax ; return number of pax for the desk, rounding value correctly */
          return allocation.rowDesk
            .map(([row, desk], i, rowDesk) => ({
              row: row,
              desk: desk,
              color: stackBy === "allocation" ? colorScale(allocation.company) : colorScale(row),
              // @ts-ignore
              amount: Math.ceil(dataPoint.queueSize / rowDesk.length),
            }))
            .filter((d) => d.amount > 0);
        }
      })
      .flat();

    return queues;
  }, [colorScale, simulation, timestamp, stackBy]);

  const AirportComponent = AirportSchemas[findInfrastructureTemplate("check-in-strategic").airport_component];
  const { ref, width, height } = useResizeDetector();

  useEffect(() => {
    if (ref.current && width && height) {
      const svg = d3.select(ref.current).select("svg");
      const ratio = svg.attr("height") / svg.attr("width");
      svg.attr("width", width).attr("height", width * ratio);
      svg.selectAll("[transform]").each(function (this: unknown) {
        const transform = d3.select(this).attr("transform");
        const matrix = transform.match(/matrix\(([^ ]+) ([^ ]+) ([^ ]+) ([^ ]+) ([^ ]+) ([^ ]+)\)/)?.map(Number);
        if (!matrix) return;
      });
    }
  }, [ref, width, height]);

  useEffect(() => {
    if (ref.current) {
      const svg = d3.select(ref.current).select("svg");
      for (const row of ROWS) {
        const rowEl = svg.select(`#row${row}`);
        const isNewGen = !!layout?.new_gen?.find((d) => d.split("-").map(Number).includes(row));
        const isSbd = !isNewGen && layout.sbd.includes(row);
        const isConventional = !isSbd && !isNewGen;
        rowEl.select("[id^='sbd']").attr("visibility", isSbd ? "visible" : "hidden");
        rowEl.select("[id^='conventional_queues']").attr("visibility", isConventional ? "visible" : "hidden");
        rowEl.select("[id^='queues']").attr("visibility", isNewGen ? "hidden" : "visible");
        rowEl.select(`[id^='desks']`).attr("visibility", isNewGen ? "hidden" : "visible");
        rowEl.select(`[id^='newGen']`).attr("visibility", isNewGen ? "visible" : "hidden");
      }
    }
  }, [ref, layout]);

  useEffect(() => {
    if (ref.current) {
      const svg = d3.select(ref.current).select("svg");
      const retain_for_overflow: Record<string /* areaId */, Record<string /* color */, number>> = {};
      svg.selectAll(".pax").remove();
      for (const row of ROWS) {
        const queuesForThisRow = queues.filter((queue) => queue.row === row);
        const rowEl = svg.select(`#row${row}`);
        for (const queue of queuesForThisRow) {
          const isNewGen = !!layout?.new_gen?.find((d) => d.split("-").map(Number).includes(row));
          const idToSelect = isNewGen ? "newGen" : "sbd";

          const mapping = {
            checkin: rowEl.select(`[id^='${idToSelect}']`).select("[id^='queue_checkin']").node(),
            bagdrop: rowEl.select(`[id^='${idToSelect}']`).select("[id^='bagdrop_queue']").node(),
            bagtag: rowEl.select(`[id^='${idToSelect}']`).select("[id^='queue_bagtag']").node(),
            assistance: rowEl.select(`[id^='${idToSelect}']`).select("[id^='assistance_queue']").node(),
          };

          const queueEl = (mapping[queue.desk] ?? rowEl.select(selectId(`r${row}_queue${queue.desk}`)).node()) as SVGPathElement | SVGRectElement;
          if (!queueEl || !(queueEl instanceof SVGPathElement || queueEl instanceof SVGRectElement)) continue;
          const queueCapacity = getQueueCapacity(queueEl, {
            paxDiameter: 0.5,
            distance: 0.7,
          });

          let overflowAreaId: string | undefined =
            OVERFLOW_AREA_BINDING[Object.keys(OVERFLOW_AREA_BINDING).find((key) => new RegExp(key).test(d3.select(queueEl).attr("id")))!] ??
            rowEl.select(`[id^='${idToSelect}']`).select("[id^='bagtag_queue']").attr("id");
          if (queue.amount > queueCapacity) {
            const retain = queue.amount - queueCapacity;
            const overflowArea = retain_for_overflow[overflowAreaId];
            const color = queue.color ?? "lightgrey";
            retain_for_overflow[overflowAreaId] = {
              ...retain_for_overflow[overflowAreaId],
              [color]: (overflowArea?.[color] || 0) + retain,
            };
          }
          drawQueue(queueEl, Math.min(queue.amount, queueCapacity), socialDistancing, queue.color);
        }
      }
      for (const [overflowAreaId, amountByColor] of Object.entries(retain_for_overflow)) {
        if (!overflowAreaId) continue;
        const overflowArea = svg.select(selectId(`${overflowAreaId}`)).node();
        for (const [color, amount] of Object.entries(amountByColor)) {
          drawQueue(overflowArea, amount, socialDistancing, color);
        }
      }
    }
  }, [ref, queues, socialDistancing, layout?.new_gen]);

  return <AirportComponent ref={ref} width={width} height={height} />;
};

function drawQueue(
  el: SVGPathElement | SVGRectElement,
  pax: number,
  socialDistancing: {
    distance: number;
    diameter: number;
  },
  color: string = "lightgrey"
) {
  if (Number.isNaN(pax)) return;

  const id = d3.select(el).attr("id");
  const isRotated = d3.select(el).attr("transform")?.includes("rotate");
  const isMatrixed = d3.select(el).attr("transform")?.includes("matrix");
  const transform = isMatrixed && d3.select(el).attr("transform");

  const randomQueue = id?.startsWith("queue_bagtag") || id?.startsWith("queue_checkin") || id?.startsWith("bagtag_queue") || id?.includes("overflow");

  d3.select(el.parentNode)
    .selectAll(`.pax_${id}`.replace("/", "\\/"))
    .data(
      Array(pax)
        .fill(0)
        .map((x, i) =>
          getPaxPosition(
            el,
            {
              paxDiameter: socialDistancing.diameter,
              distance: socialDistancing.distance,
            },
            i,
            {
              rectFillingMode: randomQueue ? "random" : isRotated ? "left" : "right",
              lineFillingMode: randomQueue ? "random" : "start",
              invert: isRotated,
            }
          )
        )
        .filter((x) => !!x)
    )
    .join("circle")
    .attr("class", `pax pax_${id}`)
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("fill", color || "lightgrey")
    .attr("r", socialDistancing.diameter / 2)
    .attr("transform", transform || null);
}

export default SBDQueueChart;
