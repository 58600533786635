import { Table, Select } from "antd";
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { mapIataCode } from "shared/constants/iata_airlines";
import { ROWS, StrategicLayout } from "shared/StrategicLayout";

const ISLANDS = ["1-2", "3-4", "5-6", "7-8", "9-10", "11-12"] as const;

const Radio = styled.input`
  cursor: pointer;
`;

type LayoutBuilderProps = {
  value?: StrategicLayout;
  onChange?: (value) => void;
  disabled?: boolean;
  disabledSbdRows?: Array<number>;
  disabledNewGenRows?: Array<number>;
  allocations: any;
  flightSchedule: any;
  layout: any;
  setLayout: any;
};

const LayoutBuilder: React.FC<LayoutBuilderProps> = ({
  value,
  onChange,
  disabled,
  disabledSbdRows,
  disabledNewGenRows,
  allocations,
  flightSchedule,
  layout,
  setLayout,
}) => {
  const flightsNotAssigned = (flightSchedule || []).filter(
    (flight) => !(allocations || []).find((allocation) => allocation.flightNumber === flight.flightNumber)
  );
  const airlinesToAssign = _.uniq(flightsNotAssigned?.map((flight) => flight.flightNumber.split(" ")[0]));

  const changeRowToConventional = (row: number) => {
    const previousIsland = layout.new_gen.find((r) => r.split("-").map(Number).includes(row));
    setLayout({
      sbd: [...(layout.sbd || [])].filter((r) => r !== row),
      new_gen: [...(layout.new_gen || []).filter((r) => !r.split("-").map(Number).includes(row))],
    });
    if (value) {
      if (previousIsland) {
        delete value[previousIsland.split("-")[0]];
        delete value[previousIsland.split("-")[1]];
      }
      delete value[row];
    }
    onChange && onChange({ ...value });
  };
  const changeRowToSbd = (row: number) => {
    const previousIsland = layout.new_gen.find((r) => r.split("-").map(Number).includes(row));
    setLayout({
      sbd: [...(layout.sbd || []), row],
      new_gen: [...(layout.new_gen || []).filter((r) => !r.split("-").map(Number).includes(row))],
    });
    if (value) {
      if (previousIsland) {
        delete value[previousIsland.split("-")[0]];
        delete value[previousIsland.split("-")[1]];
      }
      delete value[row];
    }
    onChange && onChange({ ...value });
  };
  const changeIslandToNewGen = (island: (typeof ISLANDS)[number]) => {
    const previousIsland = layout.new_gen.find((r) => r.split("-").map(Number).includes(island.split("-").map(Number)[0]));
    setLayout({
      sbd: [...(layout.sbd || []).filter((r) => !island.split("-").map(Number).includes(r))],
      new_gen: [...(layout.new_gen || []), island],
    });
    if (value) {
      if (previousIsland) {
        delete value[previousIsland.split("-")[0]];
        delete value[previousIsland.split("-")[1]];
      }
      delete value[island.split("-")[0]];
      delete value[island.split("-")[1]];
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "label",
      key: "label",
      fixed: "left",
      align: "center",
      width: 150,
    },
    ...ROWS.map((row) => ({
      title: row.toString(),
      dataIndex: `row_${row}`,
      width: 120,
      align: "center",
      key: `row_${row}`,
      render: (text, record) => {
        if (record.label === "New Gen" && row % 2 === 1) {
          return {
            children: record[`row_${row}`],
            props: {
              colSpan: 2,
            },
          };
        }
        if (record.label === "New Gen" && row % 2 === 0) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return record[`row_${row}`];
      },
    })),
  ];

  const dataSource = [
    {
      key: "1",
      label: "Conventional",
      ...ROWS.reduce((acc, row) => {
        acc[`row_${row}`] = (
          <Radio
            type="radio"
            disabled={disabled || disabledSbdRows?.includes(row)}
            name={`row_${row}_conventional`}
            checked={!layout.sbd.includes(row) && !layout?.new_gen.find((r) => r.split("-").map(Number).includes(row))}
            onChange={(e) => {
              if (e.target.checked) changeRowToConventional(row);
            }}
          />
        );
        return acc;
      }, {}),
    },
    {
      key: "2",
      label: "SBD",
      ...ROWS.reduce((acc, row) => {
        acc[`row_${row}`] = (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Radio
              type="radio"
              disabled={disabled || disabledSbdRows?.includes(row)}
              name={`row_${row}_sbd`}
              checked={layout.sbd.includes(row)}
              onChange={(e) => {
                if (e.target.checked) changeRowToSbd(row);
              }}
            />
            {layout.sbd.includes(row) && (
              <Select
                style={{ width: 100, marginTop: 8 }}
                value={value?.[row.toString()]}
                onChange={(v) => {
                  onChange &&
                    onChange({
                      ...value,
                      [row.toString()]: v,
                    });
                }}
                mode="multiple"
                options={airlinesToAssign.map((airline) => {
                  // @ts-ignore
                  return { label: mapIataCode(airline), value: airline };
                })}
              />
            )}
          </div>
        );
        return acc;
      }, {}),
    },
    {
      key: "3",
      label: "New Gen",
      ...ISLANDS.reduce((acc, island) => {
        const [start, end] = island.split("-").map(Number);
        acc[`row_${start}`] = (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Radio
              type="radio"
              disabled={disabled || disabledNewGenRows?.some((r) => island.split("-").map(Number).includes(r))}
              name={`row_${start}_island_${island}`}
              checked={layout.new_gen.includes(island)}
              onChange={(e) => {
                if (e.target.checked) changeIslandToNewGen(island);
              }}
            />
            {layout.new_gen.includes(island) && (
              <Select
                style={{ width: 100, marginTop: 8 }}
                value={value?.[start]}
                onChange={(v) => {
                  onChange &&
                    onChange({
                      ...value,
                      [start]: v,
                      [end]: v,
                    });
                }}
                mode="multiple"
                options={airlinesToAssign.map((airline) => {
                  // @ts-ignore
                  return { label: mapIataCode(airline), value: airline };
                })}
              />
            )}
          </div>
        );
        acc[`row_${end}`] = null;
        return acc;
      }, {}),
    },
  ];

  // @ts-ignore
  return <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ x: "max-content" }} />;
};

export default LayoutBuilder;
