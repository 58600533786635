import { Col, Row } from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import { FC, ReactElement, createElement, useEffect, useMemo, useRef } from "react";
import { FixedLengthArray } from "shared/utils";
import * as d3 from "d3";
import { useResizeDetector } from "react-resize-detector";

const colorScale = (maxValue) =>
  d3
    .scaleLinear()
    .domain([1, maxValue / 2, maxValue])
    .range(["#1a9850", "#fee08b", "#d73027"]);

const HeatBar: FC<{ data: FixedLengthArray<number, 240>; maxValue: number }> = ({ data, maxValue }) => {
  return (
    <div style={{ height: "100%", display: "flex", width: "100%" }}>
      {data.map((value, i) => (
        <div key={i} style={{ flex: 1, height: "100%", backgroundColor: value ? colorScale(maxValue)(value) : "white" }} />
      ))}
    </div>
  );
};

const Axis = ({ domain }) => {
  const { ref: container, width } = useResizeDetector();

  const ref = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    const scale = d3.scaleTime().domain(domain).range([0, width]);
    const axis = d3.axisTop().scale(scale).tickFormat(d3.timeFormat("%H:%M"));
    d3.select(ref.current).append("g").call(axis).attr("transform", "translate(0, 30)");
    d3.select(ref.current).select("path").remove();
    return () => {
      d3.select(ref.current).selectAll("*").remove();
    };
  }, [domain, ref, width]);
  return (
    <div ref={container}>
      <svg ref={ref} width={width} height={30} style={{ margin: 0, padding: 0 }} />
    </div>
  );
};

export const Legend = ({ text, maxValue, colors }) => {
  return (
    <div style={{ marginBottom: 20, display: "flex", gap: 10 }}>
      <div>{text}</div>
      <div>1</div>
      <div style={{ background: `linear-gradient(90deg, ${colors.join(",")})`, width: 100 }} />
      <div>{maxValue}</div>
    </div>
  );
};

interface TimelineProps {
  day: Moment;
  legend?: ({ maxValue, colors }) => ReactElement;
  data: Array<{
    rowName: string;
    events: Array<{
      from: Date;
      to: Date;
      color: string;
      label: string;
    }>;
  }>;
}
const Timeline: FC<TimelineProps> = ({ data, day, legend = () => null }) => {
  const heatByQuarterByRow: { [row: string]: FixedLengthArray<number, 240> } = useMemo(() => {
    return _(data)
      .keyBy("rowName")
      .map((v, k) => [
        k,
        _(Array.from({ length: 24 * 4 }).map((_, i) => i))
          .map((i) => moment(day).add(i * 15, "minutes"))
          .map((m, i) => v.events.filter((e) => m.isBetween(moment(e.from), moment(e.to))).length)
          .value(),
      ])
      .fromPairs()
      .value();
  }, [data, day]);
  const maxValue = _.max(_.values(heatByQuarterByRow).map((v) => _.max(v) || 0)) || 0;
  const colors = colorScale(maxValue).range();

  return (
    <div>
      <Row justify="end">{createElement(legend, { maxValue, colors })}</Row>
      <Row justify="end">
        <Col span={1}></Col>
        <Col span={23}>
          <Axis domain={[moment(day).startOf("day"), moment(day).endOf("day")]} />
        </Col>
      </Row>
      {data.map((d) => (
        <Row key={d.rowName} style={{ marginBottom: 0 }}>
          <Col span={1}>{d.rowName}</Col>
          <Col span={23}>
            <HeatBar data={heatByQuarterByRow[d.rowName]} maxValue={maxValue} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default Timeline;
