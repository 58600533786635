import { FlightSchedule } from "shared/FlightSchedule";
import { useQuery } from "react-query";
import getAPI from "../services/api";
import dayjs from "dayjs";

export const useFlightSchedule = (date: Date) => {
  return useQuery(
    ["flight-schedule", dayjs(date).format("YYYY-MM-DD")],
    async () => {
      const api = await getAPI();
      const { data } = await api.get<FlightSchedule>(`/flight-schedule/${dayjs(date).format("YYYY-MM-DD")}`);
      return data;
    },
    {
      enabled: date instanceof Date && !isNaN(date.getTime()),
      retry: 3,
      retryDelay: 1000,
    }
  );
};

export const useFlightSchedules = () => {
  return useQuery(["flight-schedules"], async () => {
    const api = await getAPI();
    const { data } = await api.get<Array<{ date: string }>>(`/flight-schedules`);
    return data;
  });
};
