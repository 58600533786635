import React from "react";

import { useResizeDetector } from "react-resize-detector";

const WithWidth: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}> = ({ children, style, wrapperStyle }) => {
  const { ref, width } = useResizeDetector();
  return (
    <div ref={ref} style={wrapperStyle}>
      <>
        {width && width > 0 && (
          <div key={width > 0 ? "1" : "0"} style={{ ...style, width: "100%" }}>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child as React.ReactElement, { width });
            })}
          </div>
        )}
      </>
    </div>
  );
};

export default WithWidth;
