import React, { useEffect } from "react";
import { Input, Button, Select, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

type AirlineHandlingTimesProps = {
  value?: Record<string, string>;
  onChange?: (value: Record<string, string>) => void;
  airlineOptions: { label: string; value: string }[];
};

const AirlineHandlingTimes: React.FC<AirlineHandlingTimesProps> = ({ value = {}, onChange, airlineOptions }) => {
  const handleAdd = () => {
    const newValue = { ...value, "": "" };
    onChange && onChange(newValue);
  };

  const handleRemove = (key) => {
    const { [key]: _, ...newValue } = value;
    onChange && onChange(newValue);
  };

  const handleChange = (key, field, val) => {
    let newValue;
    if (field === "airline") {
      newValue = { ...Object.fromEntries(Object.entries(value).filter(([k]) => k !== key)), [val]: value[key] };
    } else {
      newValue = { ...value, [key]: val };
    }
    if (!newValue.hasOwnProperty("default")) {
      newValue.default = value.default || "107";
    }
    onChange && onChange(newValue);
  };

  useEffect(() => {
    if (!value.hasOwnProperty("default")) {
      handleChange("default", "time", value.default || 109);
    }
  }, [value]);

  return (
    <>
      <Input.Group compact style={{ marginBottom: 8, display: "none" }}>
        <Input style={{ width: 200, background: "white", color: "black" }} value="default" disabled />
        <InputNumber style={{ width: 80 }} placeholder="Time" value={value.default || 107} onChange={(val) => handleChange("default", "time", val)} />
      </Input.Group>
      {Object.entries(value).map(([key, time], index) => {
        if (key === "default") {
          return null;
        }
        return (
          <Input.Group compact key={index} style={{ marginBottom: 8 }}>
            <Select
              style={{ width: 200 }}
              placeholder="Select Airline"
              value={key}
              onChange={(val) => handleChange(key, "airline", val)}
              options={airlineOptions}
            />
            <InputNumber style={{ width: 80 }} placeholder="Time" value={time} onChange={(value) => handleChange(key, "time", value)} />
            <MinusCircleOutlined className="dynamic-delete-button" onClick={() => handleRemove(key)} style={{ margin: "8px 0 0 8px" }} />
          </Input.Group>
        );
      })}
      <Button type="dashed" onClick={handleAdd} style={{ width: 280 }} icon={<PlusOutlined />}>
        Add airline
      </Button>
    </>
  );
};

export default AirlineHandlingTimes;
