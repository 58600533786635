import { Collapse } from "antd";
import _ from "lodash";
import moment from "moment";
import { useMemo } from "react";
import Timeline, { Legend } from "../../plots/Timeline/Timeline";
import TimelineDetails from "../../plots/Timeline/TimelineDetails";
import { Allocation } from "shared/Allocation";

const AllocationRendering: React.FC<{
  allocations: Allocation[];
}> = ({ allocations }) => {
  const data = useMemo(
    () =>
      _(allocations || [])
        .groupBy("check-inRow")
        .map((allocations, checkInDesk) => {
          return {
            rowName: checkInDesk,
            events: _(allocations)
              .uniqBy("flightNumber")
              .map((allocations) => ({
                from: moment(allocations.openTime).toDate(),
                to: moment(allocations.closeTime).toDate(),
                color: "lightgrey",
                label: allocations.flightNumber,
              }))
              .value(),
          };
        })
        .value(),
    [allocations]
  );
  return (
    <div>
      <Timeline
        data={data}
        day={moment(allocations?.[0]?.openTime).startOf("day")}
        legend={({ maxValue, colors }) => (
          <>
            <Legend maxValue={maxValue} colors={colors} text={`Number of flights allocated to row: `} />
          </>
        )}
      />
      <Collapse ghost>
        <Collapse.Panel key="1" header="Details">
          <TimelineDetails data={data} day={moment(allocations?.[0]?.openTime).startOf("day")} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default AllocationRendering;
