import { useEffect, useState } from "react";
import { ScenarioDay } from "shared/ScenarioDay";
import { useQuery } from "react-query";
import getAPI from "../services/api";

export function useSize(ref = window as any) {
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    if (!ref) return;

    function handleResize() {
      setSize({
        width: ref.clientWidth,
        height: ref.clientHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);
  return size;
}

export const useScenarioDays = (infrastructureId) =>
  useQuery<Array<ScenarioDay>>(["scenario-days", infrastructureId], () =>
    getAPI()
      .then((api) => {
        if (infrastructureId === "security-screening-and-border") {
          return api.get("scenario-days/security-screening-and-border").then((response) => {
            let data = response.data.map((sd) => ({
              name: sd.name,
              infrastructure: sd.infrastructure,
              period: sd.period,
              type: sd.type,
              version: sd.version,
              date: new Date(new Date(sd.date).setHours(12)).toISOString().split("T")[0],
            }));
            return data as Array<ScenarioDay>;
          });
        } else {
          return api.get("files").then((response) => {
            let data = response.data.map((sd) => ({
              ...sd,
              name: sd.name,
              infrastructure: sd.infrastructure,
              date: new Date(new Date(sd.date).setHours(12)).toISOString().split("T")[0],
            }));
            return data as Array<ScenarioDay>;
          });
        }
      })
      .catch(() => [])
  );

async function fetchPlanningFiles(bucketFolder: string | null, date: string | null, version: string | null) {
  const api = await getAPI();
  if (!date || !version || !bucketFolder) return [];
  const urlsResponse = await api.get(`planning-files/${bucketFolder}/${date}/${version}`);
  const urls = urlsResponse.data;
  const files = await Promise.all(
    urls.map(async (url) => {
      const response = await fetch(url.url);
      const jsonContent = await response.json();
      const { key } = url;
      const splittedKey = key.split("/");
      const name = splittedKey[splittedKey.length - 1];
      return {
        name: name,
        planning: jsonContent,
      };
    })
  );
  return files;
}

export const useFilePlannings = (bucketFolder: string | null, date: string | null, version: string | null) => {
  return useQuery(["file-plannings", bucketFolder, date, version], () => fetchPlanningFiles(bucketFolder, date, version));
};

export const useFiles = (infrastructureId) =>
  useQuery<Array<ScenarioDay>>(["files"], () =>
    getAPI()
      .then((api) => {
        const url = infrastructureId === "security-screening-and-border" ? "files?infrastructureId=security-screening-and-border" : "files";
        return api.get(url).then((response) => {
          let data = response.data.map((sd) => ({
            ...sd,
            name: sd.name,
            date: new Date(new Date(sd.date).setHours(12)).toISOString().split("T")[0],
          }));
          return data as Array<ScenarioDay>;
        });
      })
      .catch(() => [])
  );
