"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CURRENT_INFRASTRUCTURE = void 0;
const misc_1 = require("./misc");
exports.CURRENT_INFRASTRUCTURE = {
    id: "departure-hall-current",
    name: "Check-in current",
    category: misc_1.InfrastructureCategories.Operational,
    airport_component: "DepartureCurrent",
};
