"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCREENING_INFRASTRUCTURE = void 0;
const defaults_1 = require("./defaults");
const misc_1 = require("./misc");
exports.SCREENING_INFRASTRUCTURE = {
    id: "security-screening-and-border",
    name: "Screening & Border Connector",
    airport_component: "Screening",
    start_point: "start",
    category: misc_1.InfrastructureCategories.Planification,
    passenger_proportions: defaults_1.DEFAULT_SCREENING_PASSENGER_PROPORTION,
    specific_passenger_proportions: [
        {
            start_hour: "03:00",
            end_hour: "07:00",
            passenger_proportions: [
                {
                    id: "eu",
                    proportion: 0.95,
                    label: "EU Pax",
                },
                {
                    id: "tcn",
                    proportion: 1 - 0.95,
                    label: "Third Country Nationals",
                },
            ],
        },
    ],
    passenger_types: [
        {
            id: "eu_pax_departure",
            label: "EU Pax Departure Pier B",
        },
        {
            id: "tcn_pax_departure",
            label: "TCN Departure Pier B",
        },
        {
            id: "eu_pax_screening_only",
            label: "EU Pax Departure Pier A/T",
        },
        {
            id: "tcn_pax_screening_only",
            label: "TCN Departure Pier A/T",
        },
        {
            id: "eu_pax_transfer",
            label: "EU Pax Transfer A->B",
        },
        {
            id: "tcn_pax_transfer",
            label: "TCN Transfer A->B",
        },
    ],
    entities: [
        {
            name: "screening",
            glass_door: true,
            process_order: 0,
            display_order: 2,
            label: "Screening Connector",
            type: "multi-queue-entity",
            elements: [
                {
                    name: "screening-lane",
                    label: "Screening Lane",
                    n_entities: 22,
                    min_entities: 0,
                    max_entities: 22,
                    throughput: defaults_1.DEFAULT_SCREENING_THROUGHPUT,
                    fastlane_proportion: defaults_1.DEFAULT_SCREENING_FASTLANE_PROPORTION,
                    planning_unit: "half-hour",
                    planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    mapping: [
                        {
                            n_elements: 4,
                            queue: "screening-section-1",
                            order: 0,
                        },
                        {
                            n_elements: 5,
                            queue: "screening-section-2",
                            order: 1,
                        },
                        {
                            n_elements: 8,
                            queue: "screening-section-3",
                            order: 2,
                        },
                        {
                            n_elements: 8,
                            queue: "screening-section-4",
                            order: 3,
                        },
                    ],
                    passenger_types: [
                        {
                            source: "start",
                            frac: 1,
                            handling_time: defaults_1.DEFAULT_SCREENING_HANDLING_TIME,
                            passenger_type: "eu_pax_departure",
                        },
                        {
                            source: "start",
                            frac: 1,
                            handling_time: defaults_1.DEFAULT_SCREENING_HANDLING_TIME,
                            passenger_type: "tcn_pax_departure",
                        },
                        {
                            source: "start",
                            frac: 1,
                            handling_time: defaults_1.DEFAULT_SCREENING_HANDLING_TIME,
                            passenger_type: "eu_pax_screening_only",
                        },
                        {
                            source: "start",
                            frac: 1,
                            handling_time: defaults_1.DEFAULT_SCREENING_HANDLING_TIME,
                            passenger_type: "tcn_pax_screening_only",
                        },
                    ],
                },
            ],
            queues: {
                "screening-section-1": ["screening-section-1"],
                "screening-section-2": ["screening-section-2"],
                "screening-section-3": ["screening-section-3"],
                "screening-section-4": ["screening-section-4"],
            },
        },
        {
            name: "post-screening-dwelling",
            process_order: 1,
            display_order: 0,
            label: "Dwelling zone",
            type: "transit",
            passenger_types: [
                {
                    source: "screening",
                    frac: 1,
                    handling_time: 60,
                    passenger_type: "eu_pax_departure",
                },
                {
                    source: "screening",
                    frac: 1,
                    handling_time: 60,
                    passenger_type: "tcn_pax_departure",
                },
                {
                    source: "screening",
                    frac: 1,
                    handling_time: 60,
                    passenger_type: "eu_pax_screening_only",
                },
                {
                    source: "screening",
                    frac: 1,
                    handling_time: 60,
                    passenger_type: "tcn_pax_screening_only",
                },
            ],
        },
        {
            name: "border",
            process_order: 2,
            display_order: 1,
            label: "Border Connector",
            max_total_entities: 8,
            type: "entity",
            elements: [
                {
                    name: "eu-booth",
                    label: "EU Booths",
                    n_entities: 5,
                    min_entities: 0,
                    max_entities: 8,
                    planning_unit: "half-hour",
                    planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    passenger_types: [
                        {
                            source: "post-screening-dwelling",
                            frac: Math.ceil(10000 * 0.9 * 0.25) / 10000,
                            handling_time: 35,
                            passenger_type: "eu_pax_departure",
                        },
                        {
                            source: "start",
                            frac: Math.ceil(10000 * 0.9 * 0.25) / 10000,
                            handling_time: 35,
                            passenger_type: "eu_pax_transfer",
                        },
                    ],
                },
                {
                    name: "tcn-booth",
                    label: "TCN Booths",
                    n_entities: 3,
                    min_entities: 0,
                    max_entities: 8,
                    planning_unit: "half-hour",
                    planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    passenger_types: [
                        {
                            source: "post-screening-dwelling",
                            frac: 0.8,
                            handling_time: 50,
                            passenger_type: "tcn_pax_departure",
                        },
                        {
                            source: "start",
                            frac: 0.8,
                            handling_time: 50,
                            passenger_type: "tcn_pax_transfer",
                        },
                    ],
                },
            ],
            queues: {
                "eu-booth": ["eu-booth", "tcn-booth"],
                "tcn-booth": ["tcn-booth", "eu-booth"],
            },
        },
        {
            name: "eu-egates",
            process_order: 3,
            display_order: 3,
            label: "EU eGates",
            type: "entity",
            queues: {
                "eu-egates": ["eu-egates"],
            },
            elements: [
                {
                    name: "eu-egates",
                    label: "EU eGates",
                    n_entities: 6,
                    min_entities: 0,
                    max_entities: 6,
                    planning_unit: "half-hour",
                    planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    passenger_types: [
                        {
                            source: "post-screening-dwelling",
                            frac: 0.75,
                            handling_time: 35,
                            passenger_type: "eu_pax_departure",
                        },
                        {
                            source: "start",
                            frac: 0.75,
                            handling_time: 35,
                            passenger_type: "eu_pax_transfer",
                        },
                    ],
                },
            ],
        },
        {
            name: "tcn-egates",
            process_order: 4,
            display_order: 4,
            label: "TCN eGates",
            type: "entity",
            queues: {
                "tcn-egates": ["tcn-egates"],
            },
            elements: [
                {
                    name: "tcn-egates",
                    label: "TCN eGates",
                    n_entities: 6,
                    min_entities: 0,
                    max_entities: 6,
                    planning_unit: "half-hour",
                    planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    passenger_types: [
                        {
                            source: "post-screening-dwelling",
                            frac: 0.2,
                            handling_time: 35,
                            passenger_type: "tcn_pax_departure",
                        },
                        {
                            source: "start",
                            frac: 0.2,
                            handling_time: 35,
                            passenger_type: "tcn_pax_transfer",
                        },
                    ],
                },
            ],
        },
        {
            name: "bidir-booths",
            process_order: 5,
            display_order: 5,
            label: "Bi-directional Booths",
            type: "entity",
            queues: {
                "bidir-booths": ["bidir-booths"],
            },
            elements: [
                {
                    name: "bidir-booths",
                    label: "Bi-directional Booths",
                    n_entities: 4,
                    min_entities: 0,
                    max_entities: 6,
                    planning_unit: "half-hour",
                    planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    passenger_types: [
                        {
                            source: "post-screening-dwelling",
                            relative_frac: 0.1,
                            frac: Math.ceil(10000 * 0.1 * 0.25) / 10000,
                            handling_time: 35,
                            passenger_type: "eu_pax_departure",
                        },
                        {
                            source: "start",
                            relative_frac: 0.1,
                            frac: Math.ceil(10000 * 0.1 * 0.25) / 10000,
                            handling_time: 35,
                            passenger_type: "eu_pax_transfer",
                        },
                    ],
                },
            ],
        },
    ],
    screening: {
        optimal_balancing: false,
        pax_error_assignment: 0.05,
        partition_dimensions: [
            { name: "pier", selected: true },
            { name: "destination", selected: false },
            { name: "flight_type", selected: false },
            { name: "pax_type", selected: true },
        ],
        partition_assignment: [
            {
                assignment: null,
                pier: "A",
                destination: null,
                flight_type: null,
                pax_type: "business",
                extra_handling_time: 10,
            },
            {
                assignment: null,
                pier: "T",
                destination: null,
                flight_type: null,
                pax_type: "business",
                extra_handling_time: 10,
            },
            {
                assignment: null,
                pier: "A",
                destination: null,
                flight_type: null,
                pax_type: "economy",
                extra_handling_time: 10,
            },
            {
                assignment: null,
                pier: "T",
                destination: null,
                flight_type: null,
                pax_type: "economy",
                extra_handling_time: 10,
            },
            {
                assignment: null,
                pier: "A",
                destination: null,
                flight_type: null,
                pax_type: "PRM",
                extra_handling_time: 10,
            },
            {
                assignment: null,
                pier: "B",
                destination: null,
                flight_type: null,
                pax_type: "economy",
                extra_handling_time: 10,
            },
            {
                assignment: null,
                pier: "B",
                destination: null,
                flight_type: null,
                pax_type: "business",
                extra_handling_time: 10,
            },
            {
                assignment: null,
                pier: "B",
                destination: null,
                flight_type: null,
                pax_type: "PRM",
                extra_handling_time: 10,
            },
        ],
    },
};
