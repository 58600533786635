/* eslint-disable react/jsx-pascal-case */
import { Button, Select, Divider } from "antd";
import { InfrastructureCategories, infrastructureTemplates } from "shared/Infrastructure";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { useQueryParam, StringParam } from "use-query-params";

const InfrastructureSelection = () => {
  const navigate = useNavigate();

  const [infrastructureId, setInfrastructureId] = useQueryParam("infrastructureId", StringParam);
  const operationalInfrastructures = infrastructureTemplates.filter((d) => d.category === InfrastructureCategories.Operational);
  const planificationInfrastructures = infrastructureTemplates.filter((d) => d.category === InfrastructureCategories.Planification);

  return (
    <>
      <div>
        Infrastructure:
        <Select
          style={{ width: 270, marginLeft: 10 }}
          onChange={(infrastructureId) => {
            navigate(`/simulations?infrastructureId=${infrastructureId}`);
          }}
          value={infrastructureTemplates.find((infrastructure) => infrastructure.id === infrastructureId)?.id}
        >
          <Select.Option key="operational-simulations" disabled>
            Operational simulations
          </Select.Option>
          {operationalInfrastructures.map(({ name, id }) => {
            return (
              <Select.Option key={name} value={id}>
                {name}
              </Select.Option>
            );
          })}
          <Select.Option key="planification-simulations" disabled>
            Planification simulations
          </Select.Option>
          {planificationInfrastructures.map(({ name, id }) => {
            return (
              <Select.Option key={name} value={id}>
                {name}
              </Select.Option>
            );
          })}
        </Select>
        <Button type="link" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faMap} />
        </Button>
      </div>
    </>
  );
};

export default InfrastructureSelection;
