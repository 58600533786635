"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TAXI_INFRASTRUCTURES = void 0;
const misc_1 = require("./misc");
exports.TAXI_INFRASTRUCTURES = [
    {
        id: "taxi-current",
        category: misc_1.InfrastructureCategories.Planification,
        name: "Taxi current",
        airport_component: "TaxiCurrent",
    },
    {
        id: "taxi-future",
        category: misc_1.InfrastructureCategories.Planification,
        name: "Taxi future",
        airport_component: "TaxiFuture",
    },
];
