import getAPI from "../services/api";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Simulation } from "shared/Simulation";

const SIMULATIONS_KEY = "strategic-check-in-simulations";

export default function useStrategicSimulations() {
  const {
    data: simulations,
    isLoading,
    error,
    refetch,
  } = useQuery(SIMULATIONS_KEY, () => {
    return getAPI().then((api) => api.get("/strategic-simulations").then((res) => res.data));
  });
  const key = SIMULATIONS_KEY;
  return { simulations, isLoading, refetch, error, key };
}
