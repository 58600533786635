import { Col, Divider, Row, Statistic } from "antd";
import _ from "lodash";
import moment from "moment";
import { FlightSchedule } from "shared/FlightSchedule";
import { extractCompanyFromFlightNumber, ScenarioDay } from "shared/ScenarioDay";
import numeral from "numeral";
import FlightStats from "../../ScenarioDay/FlightStats";
import CompanyRepartition from "../../ScenarioDay/CompanyRepartition";
import TrafficChart from "../../ScenarioDay/TrafficChart";

const FlightScheduleRendering: React.FC<{
  flightSchedule: FlightSchedule;
}> = ({ flightSchedule }) => {
  return (
    <>
      <FlightStats scenarioDay={{ data: { flightSchedule } }} />
      <Divider />
      <CompanyRepartition scenarioDay={{ data: { flightSchedule } }} />
      <TrafficChart scenarioDay={{ data: { flightSchedule } }} />
    </>
  );
};

export default FlightScheduleRendering;
