"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GENERIC_INFRASTRUCTURES = void 0;
const defaults_1 = require("./defaults");
const misc_1 = require("./misc");
exports.GENERIC_INFRASTRUCTURES = [
    {
        id: "departure-hall-future-by-sectors",
        category: misc_1.InfrastructureCategories.Operational,
        name: "Check-in future by sectors",
        airport_component: "DepartureFutureBySectors",
        start_point: "entry",
        passenger_proportions: defaults_1.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION,
        passenger_types: defaults_1.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE,
        entities: [
            {
                name: "checkin-kiosk",
                process_order: 0,
                display_order: 2,
                label: "Check-in Kiosks",
                type: "entity",
                elements: [
                    {
                        name: "checkin-kiosk-element-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 1,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 2,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-shorthaul",
                        label: "Short Haul",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-longhaul",
                        label: "Long Haul",
                        n_entities: 2,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-leisure",
                        label: "Leisure",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-lowcost",
                        label: "Lowcost",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "checkin-kiosk-element-lowcost": ["checkin-kiosk-element-lowcost"],
                    "checkin-kiosk-element-leisure": ["checkin-kiosk-element-leisure"],
                    "checkin-kiosk-element-longhaul": ["checkin-kiosk-element-longhaul"],
                    "checkin-kiosk-element-shorthaul": ["checkin-kiosk-element-shorthaul"],
                    "checkin-kiosk-element-hcshorthaul": ["checkin-kiosk-element-hcshorthaul"],
                    "checkin-kiosk-element-hclonghaul": ["checkin-kiosk-element-hclonghaul"],
                },
            },
            {
                name: "bagtag-kiosk",
                label: "Bag Tag Printing Kiosk",
                process_order: 1,
                display_order: 1,
                type: "entity",
                elements: [
                    {
                        name: "bagtag-kiosk-elements-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 18,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 7,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-shorthaul",
                        label: "Short Haul",
                        n_entities: 23,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-longhaul",
                        label: "Long Haul",
                        n_entities: 9,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-leisure",
                        label: "Leisure",
                        n_entities: 15,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-lowcost",
                        label: "Lowcost",
                        n_entities: 16,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "bagtag-kiosk-elements-lowcost": ["bagtag-kiosk-elements-lowcost"],
                    "bagtag-kiosk-elements-leisure": ["bagtag-kiosk-elements-leisure"],
                    "bagtag-kiosk-elements-longhaul": ["bagtag-kiosk-elements-longhaul"],
                    "bagtag-kiosk-elements-shorthaul": ["bagtag-kiosk-elements-shorthaul"],
                    "bagtag-kiosk-elements-hclonghaul": ["bagtag-kiosk-elements-hclonghaul"],
                    "bagtag-kiosk-elements-hcshorthaul": ["bagtag-kiosk-elements-hcshorthaul"],
                },
            },
            {
                name: "bagdrop",
                process_order: 2,
                display_order: 0,
                label: "Bag Drop",
                type: "entity",
                elements: [
                    {
                        name: "bagdrop-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 10,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_hcshorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 4,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_hclonghaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-shorthaul",
                        label: "Short Haul",
                        n_entities: 13,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_shorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-longhaul",
                        label: "Long Haul",
                        n_entities: 6,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_longhaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-leisure",
                        label: "Leisure",
                        n_entities: 9,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_leisure",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-lowcost",
                        label: "Lowcost",
                        n_entities: 10,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_lowcost",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "bagdrop-lowcost": ["bagdrop-lowcost"],
                    "bagdrop-leisure": ["bagdrop-leisure"],
                    "bagdrop-longhaul": ["bagdrop-longhaul"],
                    "bagdrop-shorthaul": ["bagdrop-shorthaul"],
                    "bagdrop-hclonghaul": ["bagdrop-hclonghaul"],
                    "bagdrop-hcshorthaul": ["bagdrop-hcshorthaul"],
                },
            },
            {
                name: "conv-ci",
                process_order: 3,
                display_order: 3,
                label: "Conventional Check-in",
                type: "entity",
                elements: [
                    {
                        name: "conv-ci-desk-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 16,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 6,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-shorthaul",
                        label: "Short Haul",
                        n_entities: 20,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-longhaul",
                        label: "Long Haul",
                        n_entities: 8,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-leisure",
                        label: "Leisure",
                        n_entities: 13,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-lowcost",
                        label: "Lowcost",
                        n_entities: 14,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        planning_unit: "half-hour",
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "conv-ci-desk-lowcost": ["conv-ci-desk-lowcost"],
                    "conv-ci-desk-leisure": ["conv-ci-desk-leisure"],
                    "conv-ci-desk-longhaul": ["conv-ci-desk-longhaul"],
                    "conv-ci-desk-shorthaul": ["conv-ci-desk-shorthaul"],
                    "conv-ci-desk-hclonghaul": ["conv-ci-desk-hclonghaul"],
                    "conv-ci-desk-hcshorthaul": ["conv-ci-desk-hcshorthaul"],
                },
            },
        ],
    },
    {
        id: "arrival-pier-b",
        name: "Border Arrival",
        airport_component: "Arrival_Pier_B",
        category: misc_1.InfrastructureCategories.Operational,
        start_point: "gate",
        passenger_proportions: defaults_1.DEFAULT_ARRIVAL_PIER_B_PASSENGER_PROPORTION,
        passenger_types: [
            {
                id: "eu_pax",
                label: "EU Pax Arrival",
            },
            {
                id: "tcn_pax",
                label: "TCN Arrival",
            },
        ],
        entities: [
            {
                name: "egate",
                label: "eGates",
                type: "entity",
                display_order: 3,
                process_order: 3,
                elements: [
                    {
                        name: "eu-egate",
                        label: "eGates",
                        min_entities: 1,
                        max_entities: 9,
                        n_entities: 9,
                        passenger_types: [
                            {
                                source: "gate",
                                frac: 0.7,
                                handling_time: 35,
                                passenger_type: "eu_pax",
                            },
                            {
                                source: "gate",
                                frac: 0,
                                handling_time: 35,
                                passenger_type: "tcn_pax",
                            },
                        ],
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    },
                ],
                queues: {
                    "eu-egate": ["eu-egate"],
                },
            },
            {
                name: "booth",
                label: "Booths",
                type: "entity",
                display_order: 0,
                process_order: 1,
                elements: [
                    {
                        name: "eu-booth",
                        label: "EU Booths",
                        min_entities: 1,
                        max_entities: 10,
                        n_entities: 3,
                        passenger_types: [
                            {
                                source: "gate",
                                frac: 0.3,
                                handling_time: 35,
                                passenger_type: "eu_pax",
                            },
                        ],
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    },
                    {
                        name: "tcn-booth",
                        label: "TCN Booths",
                        min_entities: 1,
                        max_entities: 10,
                        n_entities: 7,
                        passenger_types: [
                            {
                                source: "gate",
                                frac: 1,
                                handling_time: 50,
                                passenger_type: "tcn_pax",
                            },
                        ],
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    },
                ],
                queues: {
                    "tcn-booth": ["tcn-booth", "eu-booth"],
                    "eu-booth": ["eu-booth", "tcn-booth"],
                },
            },
        ],
    },
    {
        id: "transfer-b-a-oss",
        name: "Border Transfer OSS",
        category: misc_1.InfrastructureCategories.Operational,
        airport_component: "Transfer_BA_OSS",
        start_point: "gate",
        passenger_proportions: defaults_1.DEFAULT_ARRIVAL_PASSENGER_PROPORTION,
        passenger_types: [
            {
                id: "eu_pax",
                label: "EU Pax Transfer B->A (OSS)",
            },
            {
                id: "tcn_pax",
                label: "TCN Transfer B->A (OSS)",
            },
        ],
        entities: [
            {
                name: "border",
                label: "Border",
                type: "entity",
                display_order: 0,
                process_order: 1,
                elements: [
                    {
                        name: "eu-booth",
                        label: "Booths",
                        min_entities: 1,
                        max_entities: 2,
                        n_entities: 2,
                        passenger_types: [
                            {
                                source: "gate",
                                frac: 1,
                                handling_time: 35,
                                passenger_type: "eu_pax",
                            },
                            {
                                source: "gate",
                                frac: 1,
                                handling_time: 35,
                                passenger_type: "tcn_pax",
                            },
                        ],
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                    },
                ],
                queues: {
                    "eu-booth": ["eu-booth"],
                },
            },
        ],
    },
    {
        id: "transfer-b-a-noss",
        name: "Border Transfer NOSS",
        airport_component: "Transfer_BA_NOSS",
        category: misc_1.InfrastructureCategories.Operational,
        start_point: "gate",
        passenger_proportions: defaults_1.DEFAULT_TRANSFER_NOSS_PASSENGER_PROPORTION,
        passenger_types: [
            {
                id: "eu_pax",
                label: "EU Pax Transfer B->A (NOSS)",
            },
            {
                id: "tcn_pax",
                label: "TCN Transfer B->A (NOSS)",
            },
        ],
        entities: [
            {
                name: "border",
                label: "Border",
                display_order: 2,
                process_order: 2,
                type: "entity",
                max_total_entities: 6,
                elements: [
                    {
                        name: "eu-booth",
                        label: "EU Booths",
                        n_entities: 2,
                        min_entities: 1,
                        max_entities: 6,
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                        passenger_types: [
                            {
                                source: "gate",
                                frac: 1,
                                handling_time: 35,
                                passenger_type: "eu_pax",
                            },
                        ],
                    },
                    {
                        name: "tcn-booth",
                        label: "TCN Booths",
                        n_entities: 4,
                        min_entities: 1,
                        max_entities: 6,
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                        passenger_types: [
                            {
                                source: "gate",
                                frac: 1,
                                handling_time: 50,
                                passenger_type: "tcn_pax",
                            },
                        ],
                    },
                ],
                queues: {
                    "tcn-booth": ["tcn-booth", "eu-booth"],
                    "eu-booth": ["eu-booth", "tcn-booth"],
                },
            },
        ],
    },
    {
        id: "border-a-t-departure",
        name: "Border T",
        airport_component: "border_AT_departures",
        category: misc_1.InfrastructureCategories.Operational,
        start_point: "pier-a",
        passenger_proportions: defaults_1.DEFAULT_DEPARTURE_BORDER_A_T_PASSENGER_PROPORTION,
        passenger_types: [
            {
                id: "eu_pax",
                label: "EU Pax Departure Pier T",
            },
            {
                id: "tcn_pax",
                label: "TCN Departure Pier T",
            },
        ],
        entities: [
            {
                name: "booth",
                process_order: 2,
                display_order: 0,
                max_total_entities: 4,
                label: "Booths",
                type: "entity",
                elements: [
                    {
                        name: "eu-booth",
                        label: "EU Booths",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 4,
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                        passenger_types: [
                            {
                                source: "pier-a",
                                frac: 1,
                                handling_time: 35,
                                passenger_type: "eu_pax",
                            },
                        ],
                    },
                    {
                        name: "tcn-booth",
                        label: "TCN Booths",
                        n_entities: 1,
                        min_entities: 0,
                        max_entities: 4,
                        planning_unit: "half-hour",
                        planning: defaults_1.DEFAULT_PLANNING_HALF_HOUR,
                        passenger_types: [
                            {
                                source: "pier-a",
                                frac: 1,
                                handling_time: 50,
                                passenger_type: "tcn_pax",
                            },
                        ],
                    },
                ],
                queues: {
                    "tcn-booth": ["tcn-booth", "eu-booth"],
                    "eu-booth": ["eu-booth", "tcn-booth"],
                },
            },
        ],
    },
];
