import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { InfrastructureCategories, infrastructureTemplates } from "shared/Infrastructure";

function PlanificationSimulationsSelector() {
  const planificationInfrastructures = infrastructureTemplates.filter((d) => d.category === InfrastructureCategories.Planification);
  return (
    <div style={{ margin: 24, padding: 24, backgroundColor: "white", borderRadius: 12 }}>
      <h2>Available infrastructures for planification simulations</h2>
      <div>
        <ol>
          {planificationInfrastructures.map((infrastructure) => (
            <li>
              <div key={infrastructure.id}>
                <Link to={`/simulations?infrastructureId=${infrastructure.id}`}>{infrastructure.name}</Link>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}

export default PlanificationSimulationsSelector;
