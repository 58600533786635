"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapIataCode = void 0;
const data = [
    {
        iata_code: "0B",
        icao_code: "BMS",
        name: "Blue Air",
        alias: "",
    },
    {
        iata_code: "0C",
        icao_code: "",
        name: "Catovair",
        alias: "",
    },
    {
        iata_code: "0J",
        icao_code: "",
        name: "Premium Jet AG",
        alias: "",
    },
    {
        iata_code: "0O",
        icao_code: "",
        name: "STA Travel",
        alias: "",
    },
    {
        iata_code: "0P",
        icao_code: "",
        name: "Palau Pacific Airways",
        alias: "",
    },
    {
        iata_code: "0V",
        icao_code: "VFC",
        name: "Vietnam Air Services Company",
        alias: "",
    },
    {
        iata_code: "0W",
        icao_code: "",
        name: "W Caribbean Costa Rica",
        alias: "",
    },
    {
        iata_code: "1A",
        icao_code: "AGT",
        name: "Amadeus",
        alias: "",
    },
    {
        iata_code: "1B",
        icao_code: "",
        name: "Abacus",
        alias: "Abacus",
    },
    {
        iata_code: "1C",
        icao_code: "",
        name: "EDS Information Business",
        alias: "Gemini",
    },
    {
        iata_code: "1D",
        icao_code: "",
        name: "Raddix Solutions",
        alias: "",
    },
    {
        iata_code: "1E",
        icao_code: "",
        name: "Travelsky Technology",
        alias: "Civil China",
    },
    {
        iata_code: "1F",
        icao_code: "TTF",
        name: "Infini Travel Information",
        alias: "",
    },
    {
        iata_code: "1G",
        icao_code: "",
        name: "Travelport Galileo",
        alias: "Galileo",
    },
    {
        iata_code: "1H",
        icao_code: "",
        name: "Sirena Travel",
        alias: "",
    },
    {
        iata_code: "1I",
        icao_code: "",
        name: "Netjets Aviation",
        alias: "",
    },
    {
        iata_code: "1J",
        icao_code: "",
        name: "Axess International",
        alias: "",
    },
    {
        iata_code: "1K",
        icao_code: "",
        name: "Sutra",
        alias: "Southern",
    },
    {
        iata_code: "1L",
        icao_code: "",
        name: "Navitaire Open Skies",
        alias: "",
    },
    {
        iata_code: "1M",
        icao_code: "",
        name: "Sirena Jsc",
        alias: "Sertel",
    },
    {
        iata_code: "1N",
        icao_code: "",
        name: "Navitaire New Skies",
        alias: "",
    },
    {
        iata_code: "1O",
        icao_code: "",
        name: "Phoenix Systems Dertours",
        alias: "",
    },
    {
        iata_code: "1P",
        icao_code: "WSP",
        name: "Travelport Worldspan",
        alias: "Worldspan",
    },
    {
        iata_code: "1Q",
        icao_code: "",
        name: "Joint Stock Company Ital",
        alias: "",
    },
    {
        iata_code: "1R",
        icao_code: "",
        name: "Bird Information Systems",
        alias: "",
    },
    {
        iata_code: "1S",
        icao_code: "",
        name: "Sabre",
        alias: "",
    },
    {
        iata_code: "1T",
        icao_code: "",
        name: "East Line",
        alias: "",
    },
    {
        iata_code: "1U",
        icao_code: "",
        name: "ITA Software",
        alias: "Google ITA",
    },
    {
        iata_code: "1V",
        icao_code: "",
        name: "Galileo International",
        alias: "Covia",
    },
    {
        iata_code: "1W",
        icao_code: "",
        name: "Sabre",
        alias: "",
    },
    {
        iata_code: "1X",
        icao_code: "",
        name: "Branson Air Express",
        alias: "",
    },
    {
        iata_code: "1Y",
        icao_code: "",
        name: "Electronic Data Systems (EDS)",
        alias: "",
    },
    {
        iata_code: "1Z",
        icao_code: "APD",
        name: "Fantasia Info Network",
        alias: "Asia Pacific",
    },
    {
        iata_code: "2A",
        icao_code: "DBB",
        name: "Deutsche Bahn",
        alias: "",
    },
    {
        iata_code: "2B",
        icao_code: "AWT",
        name: "Albawings",
        alias: "",
    },
    {
        iata_code: "2C",
        icao_code: "",
        name: "SNCF",
        alias: "",
    },
    {
        iata_code: "2D",
        icao_code: "EAL",
        name: "Eastern Airlines",
        alias: "LLC",
    },
    {
        iata_code: "2E",
        icao_code: "EIX",
        name: "Smokey Bay Air",
        alias: "Ei Air Export",
    },
    {
        iata_code: "2F",
        icao_code: "FAV",
        name: "Fair Aviation",
        alias: "",
    },
    {
        iata_code: "2G",
        icao_code: "",
        name: "Angara Airlines",
        alias: "",
    },
    {
        iata_code: "2H",
        icao_code: "",
        name: "Thalys",
        alias: "",
    },
    {
        iata_code: "2I",
        icao_code: "SRU",
        name: "Star Perú",
        alias: "",
    },
    {
        iata_code: "2J",
        icao_code: "VBW",
        name: "Air Burkina",
        alias: "",
    },
    {
        iata_code: "2K",
        icao_code: "GLG",
        name: "Aerolineas Galapagos",
        alias: "Aerogal",
    },
    {
        iata_code: "2L",
        icao_code: "OAW",
        name: "Helvetic Airways",
        alias: "2L Helvetic",
    },
    {
        iata_code: "2M",
        icao_code: "MYD",
        name: "Maya Island Air",
        alias: "",
    },
    {
        iata_code: "2N",
        icao_code: "NTJ",
        name: "Nextjet",
        alias: "",
    },
    {
        iata_code: "2O",
        icao_code: "RNE",
        name: "Redemption",
        alias: "",
    },
    {
        iata_code: "2P",
        icao_code: "GAP",
        name: "PAL Express",
        alias: "",
    },
    {
        iata_code: "2Q",
        icao_code: "SNC",
        name: "Air Cargo Carriers",
        alias: "",
    },
    {
        iata_code: "2R",
        icao_code: "VRR",
        name: "VIA Rail Canada",
        alias: "",
    },
    {
        iata_code: "2S",
        icao_code: "",
        name: "Air Carnival",
        alias: "",
    },
    {
        iata_code: "2T",
        icao_code: "TBS",
        name: "Timbis Air",
        alias: "",
    },
    {
        iata_code: "2U",
        icao_code: "ERO",
        name: "Ero Sun d'Or",
        alias: "",
    },
    {
        iata_code: "2V",
        icao_code: "",
        name: "Amtrak",
        alias: "",
    },
    {
        iata_code: "2Z",
        icao_code: "PTB",
        name: "Passaredo Transportes",
        alias: "",
    },
    {
        iata_code: "3A",
        icao_code: "",
        name: "Chu Kong Passenger Transport",
        alias: "Mafira Air",
    },
    {
        iata_code: "3B",
        icao_code: "JBR",
        name: "Job Air",
        alias: "",
    },
    {
        iata_code: "3C",
        icao_code: "",
        name: "Calima Aviacion",
        alias: "Corporate Exp",
    },
    {
        iata_code: "3D",
        icao_code: "",
        name: "Dokasch",
        alias: "",
    },
    {
        iata_code: "3E",
        icao_code: "OMQ",
        name: "Multi Aero",
        alias: "Air Bretagne",
    },
    {
        iata_code: "3F",
        icao_code: "",
        name: "Pacific Airways",
        alias: "",
    },
    {
        iata_code: "3G",
        icao_code: "CXM",
        name: "AsiaCargo Express",
        alias: "",
    },
    {
        iata_code: "3H",
        icao_code: "AIE",
        name: "Air Inuit",
        alias: "",
    },
    {
        iata_code: "3I",
        icao_code: "LFM",
        name: "Pison Airways Ltd",
        alias: "Virgin Austra",
    },
    {
        iata_code: "3J",
        icao_code: "JBW",
        name: "Jubba Airways Kenya",
        alias: "",
    },
    {
        iata_code: "3K",
        icao_code: "JSA",
        name: "Jetstar Asia Airways",
        alias: "",
    },
    {
        iata_code: "3M",
        icao_code: "GFT",
        name: "Silver Airways",
        alias: "",
    },
    {
        iata_code: "3N",
        icao_code: "URG",
        name: "Air Urga",
        alias: "Airvantage",
    },
    {
        iata_code: "3O",
        icao_code: "MAC",
        name: "Air Arabia Maroc",
        alias: "",
    },
    {
        iata_code: "3Q",
        icao_code: "",
        name: "Carib Aviation Limited",
        alias: "Yunnan Air",
    },
    {
        iata_code: "3R",
        icao_code: "DVR",
        name: "Divi Divi Air",
        alias: "",
    },
    {
        iata_code: "3S",
        icao_code: "",
        name: "Air Antilles Express",
        alias: "",
    },
    {
        iata_code: "3T",
        icao_code: "TRQ",
        name: "Tarco Air",
        alias: "Turan Air",
    },
    {
        iata_code: "3U",
        icao_code: "CSC",
        name: "Sichuan Airlines",
        alias: "",
    },
    {
        iata_code: "3V",
        icao_code: "TAY",
        name: "Tnt Airways",
        alias: "Viking Air",
    },
    {
        iata_code: "3W",
        icao_code: "MWI",
        name: "Malawian Airlines",
        alias: "",
    },
    {
        iata_code: "3X",
        icao_code: "",
        name: "Premier Trans Aire",
        alias: "",
    },
    {
        iata_code: "3Z",
        icao_code: "TVP",
        name: "Travel Service Polska",
        alias: "",
    },
    {
        iata_code: "4A",
        icao_code: "AMP",
        name: "Aero Transporte",
        alias: "ATSA",
    },
    {
        iata_code: "4B",
        icao_code: "TUP",
        name: "Aviastar TU",
        alias: "",
    },
    {
        iata_code: "4C",
        icao_code: "ARE",
        name: "LATAM Colombia",
        alias: "",
    },
    {
        iata_code: "4D",
        icao_code: "ASD",
        name: "Air Sinai",
        alias: "",
    },
    {
        iata_code: "4E",
        icao_code: "TNR",
        name: "Tanana Air Service",
        alias: "",
    },
    {
        iata_code: "4F",
        icao_code: "FDT",
        name: "Freedom Airline Express",
        alias: "",
    },
    {
        iata_code: "4G",
        icao_code: "GZP",
        name: "Gazpromavia",
        alias: "",
    },
    {
        iata_code: "4H",
        icao_code: "",
        name: "United Airways Bangladesh",
        alias: "Trans Pacific",
    },
    {
        iata_code: "4I",
        icao_code: "IZM",
        name: "Ihy Izmir Havayollari",
        alias: "",
    },
    {
        iata_code: "4J",
        icao_code: "",
        name: "Flydamas Airlines",
        alias: "",
    },
    {
        iata_code: "4K",
        icao_code: "KBA",
        name: "Kenn Borek Air",
        alias: "",
    },
    {
        iata_code: "4L",
        icao_code: "",
        name: "Palau National Airlines",
        alias: "",
    },
    {
        iata_code: "4M",
        icao_code: "DSM",
        name: "LATAM Argentina",
        alias: "",
    },
    {
        iata_code: "4N",
        icao_code: "",
        name: "Air North",
        alias: "",
    },
    {
        iata_code: "4O",
        icao_code: "AIJ",
        name: "Interjet",
        alias: "",
    },
    {
        iata_code: "4P",
        icao_code: "",
        name: "Travel Air",
        alias: "",
    },
    {
        iata_code: "4Q",
        icao_code: "SFW",
        name: "Safi Airways",
        alias: "",
    },
    {
        iata_code: "4R",
        icao_code: "SEK",
        name: "Star East Airline",
        alias: "",
    },
    {
        iata_code: "4S",
        icao_code: "STB",
        name: "Star Airways",
        alias: "Aerohonduras",
    },
    {
        iata_code: "4T",
        icao_code: "",
        name: "Belair Airlines",
        alias: "Russian Air",
    },
    {
        iata_code: "4U",
        icao_code: "GWI",
        name: "Germanwings",
        alias: "",
    },
    {
        iata_code: "4V",
        icao_code: "",
        name: "Lacbravo",
        alias: "",
    },
    {
        iata_code: "4W",
        icao_code: "VNA",
        name: "Warbelows Air Ventures",
        alias: "",
    },
    {
        iata_code: "4X",
        icao_code: "ANX",
        name: "Atlantic Express Airline",
        alias: "",
    },
    {
        iata_code: "4Y",
        icao_code: "TUD",
        name: "Flight Alaska",
        alias: "Yute Air Alaska",
    },
    {
        iata_code: "4Z",
        icao_code: "LNK",
        name: "Airlink",
        alias: "",
    },
    {
        iata_code: "5B",
        icao_code: "BSX",
        name: "Bassaka Air",
        alias: "",
    },
    {
        iata_code: "5C",
        icao_code: "",
        name: "Nature Air",
        alias: "",
    },
    {
        iata_code: "5D",
        icao_code: "SLI",
        name: "Aeroméxico Connect",
        alias: "Aeromexico Connect",
    },
    {
        iata_code: "5E",
        icao_code: "JTM",
        name: "East Coast Flight Services",
        alias: "",
    },
    {
        iata_code: "5F",
        icao_code: "FIA",
        name: "Fly One",
        alias: "",
    },
    {
        iata_code: "5H",
        icao_code: "FFV",
        name: "Five Fourty Aviation",
        alias: "",
    },
    {
        iata_code: "5I",
        icao_code: "",
        name: "ALSA",
        alias: "Alsa Grupo SLU",
    },
    {
        iata_code: "5J",
        icao_code: "CEB",
        name: "Cebu Pacific",
        alias: "",
    },
    {
        iata_code: "5K",
        icao_code: "HFY",
        name: "Hi Fly",
        alias: "",
    },
    {
        iata_code: "5L",
        icao_code: "",
        name: "Fly Salone",
        alias: "",
    },
    {
        iata_code: "5M",
        icao_code: "MNT",
        name: "Montserrat Airways",
        alias: "Sibaviatrans",
    },
    {
        iata_code: "5N",
        icao_code: "AUL",
        name: "Jsc Nordavia",
        alias: "Jsc Nordavia",
    },
    {
        iata_code: "5O",
        icao_code: "FPO",
        name: "ASL Airlines France",
        alias: "",
    },
    {
        iata_code: "5P",
        icao_code: "PCP",
        name: "PAL Airlines",
        alias: "Aerolinea Principal Chile",
    },
    {
        iata_code: "5R",
        icao_code: "RUC",
        name: "RUTACA Airlines",
        alias: "",
    },
    {
        iata_code: "5S",
        icao_code: "PSV",
        name: "Servicios Aereos Profesionales",
        alias: "",
    },
    {
        iata_code: "5T",
        icao_code: "MPE",
        name: "Canadian North",
        alias: "",
    },
    {
        iata_code: "5U",
        icao_code: "TGU",
        name: "Transportes Aéreos Guatemaltecos",
        alias: "",
    },
    {
        iata_code: "5V",
        icao_code: "",
        name: "Everts",
        alias: "Lviv Airlines",
    },
    {
        iata_code: "5W",
        icao_code: "",
        name: "WESTBahn",
        alias: "",
    },
    {
        iata_code: "5X",
        icao_code: "UPS",
        name: "UPS Airlines",
        alias: "",
    },
    {
        iata_code: "5Y",
        icao_code: "GTI",
        name: "Atlas Air",
        alias: "",
    },
    {
        iata_code: "5Z",
        icao_code: "KEM",
        name: "CemAir",
        alias: "",
    },
    {
        iata_code: "6A",
        icao_code: "AMW",
        name: "Armenia Airways",
        alias: "",
    },
    {
        iata_code: "6B",
        icao_code: "BLX",
        name: "Tuifly Nordic",
        alias: "Britannia",
    },
    {
        iata_code: "6C",
        icao_code: "",
        name: "Air Timor",
        alias: "",
    },
    {
        iata_code: "6D",
        icao_code: "TVQ",
        name: "Travel Service Slovensko",
        alias: "",
    },
    {
        iata_code: "6E",
        icao_code: "IGO",
        name: "IndiGo",
        alias: "",
    },
    {
        iata_code: "6F",
        icao_code: "PRW",
        name: "Primera Air Nordic",
        alias: "",
    },
    {
        iata_code: "6G",
        icao_code: "LTD",
        name: "Executive Express Aviation",
        alias: "",
    },
    {
        iata_code: "6H",
        icao_code: "ISR",
        name: "Israir Airlines",
        alias: "Israir Airlin",
    },
    {
        iata_code: "6I",
        icao_code: "",
        name: "Air Alsie",
        alias: "",
    },
    {
        iata_code: "6J",
        icao_code: "",
        name: "Solaseed Air",
        alias: "",
    },
    {
        iata_code: "6K",
        icao_code: "SGB",
        name: "Songbird Airways",
        alias: "",
    },
    {
        iata_code: "6L",
        icao_code: "AKK",
        name: "Aklak Air",
        alias: "",
    },
    {
        iata_code: "6M",
        icao_code: "",
        name: "Air Minas Linhas Aereas",
        alias: "",
    },
    {
        iata_code: "6N",
        icao_code: "NIN",
        name: "Niger Airlines",
        alias: "",
    },
    {
        iata_code: "6O",
        icao_code: "OBS",
        name: "Orbest",
        alias: "",
    },
    {
        iata_code: "6Q",
        icao_code: "SAW",
        name: "Cham Wings Airlines",
        alias: "Slovak Arlines",
    },
    {
        iata_code: "6R",
        icao_code: "DRU",
        name: "ALROSA",
        alias: "",
    },
    {
        iata_code: "6S",
        icao_code: "SGQ",
        name: "Saudi Gulf Airlines",
        alias: "",
    },
    {
        iata_code: "6T",
        icao_code: "",
        name: "Air Mandalay",
        alias: "",
    },
    {
        iata_code: "6V",
        icao_code: "",
        name: "Avanza",
        alias: "Mars Rk",
    },
    {
        iata_code: "6W",
        icao_code: "",
        name: "Saratov Airlines",
        alias: "",
    },
    {
        iata_code: "6Y",
        icao_code: "ART",
        name: "SmartLynx Airlines",
        alias: "",
    },
    {
        iata_code: "6Z",
        icao_code: "",
        name: "Eurasia Air",
        alias: "",
    },
    {
        iata_code: "7A",
        icao_code: "XRC",
        name: "Express Air Cargo",
        alias: "",
    },
    {
        iata_code: "7B",
        icao_code: "",
        name: "Fly Blue Crane",
        alias: "",
    },
    {
        iata_code: "7C",
        icao_code: "JJA",
        name: "Jeju Air",
        alias: "",
    },
    {
        iata_code: "7D",
        icao_code: "",
        name: "MGC Aviation",
        alias: "",
    },
    {
        iata_code: "7E",
        icao_code: "AWU",
        name: "Sylt Air",
        alias: "",
    },
    {
        iata_code: "7F",
        icao_code: "FAB",
        name: "First Air",
        alias: "First Air",
    },
    {
        iata_code: "7G",
        icao_code: "SFJ",
        name: "StarFlyer",
        alias: "",
    },
    {
        iata_code: "7H",
        icao_code: "RVF",
        name: "Ravn Alaska",
        alias: "",
    },
    {
        iata_code: "7I",
        icao_code: "INC",
        name: "Insel Air International",
        alias: "",
    },
    {
        iata_code: "7J",
        icao_code: "TJK",
        name: "Tajik Air",
        alias: "",
    },
    {
        iata_code: "7K",
        icao_code: "",
        name: "Kogalymavia Airlines",
        alias: "",
    },
    {
        iata_code: "7L",
        icao_code: "CRN",
        name: "Aero Caribbean",
        alias: "",
    },
    {
        iata_code: "7M",
        icao_code: "MYI",
        name: "Mayair",
        alias: "Aeromonterr",
    },
    {
        iata_code: "7N",
        icao_code: "PWD",
        name: "PAWA Dominicana",
        alias: "",
    },
    {
        iata_code: "7O",
        icao_code: "TVL",
        name: "Travel Service Hungary",
        alias: "",
    },
    {
        iata_code: "7P",
        icao_code: "PST",
        name: "Air Panama",
        alias: "",
    },
    {
        iata_code: "7Q",
        icao_code: "",
        name: "Elite Airways",
        alias: "",
    },
    {
        iata_code: "7R",
        icao_code: "RLU",
        name: "RusLine",
        alias: "",
    },
    {
        iata_code: "7S",
        icao_code: "RYA",
        name: "Ryan Air Services",
        alias: "",
    },
    {
        iata_code: "7T",
        icao_code: "",
        name: "Trenitalia",
        alias: "",
    },
    {
        iata_code: "7U",
        icao_code: "",
        name: "Alpha Air Transport",
        alias: "",
    },
    {
        iata_code: "7V",
        icao_code: "",
        name: "Federal Airlines",
        alias: "Federal Airlines",
    },
    {
        iata_code: "7W",
        icao_code: "WRC",
        name: "Wind Rose Aviation",
        alias: "",
    },
    {
        iata_code: "7Y",
        icao_code: "MYP",
        name: "Mann Yadanarpon Airlines",
        alias: "",
    },
    {
        iata_code: "7Z",
        icao_code: "",
        name: "Halcyon Air Cabo Verde",
        alias: "",
    },
    {
        iata_code: "8A",
        icao_code: "",
        name: "Panama Airways",
        alias: "",
    },
    {
        iata_code: "8B",
        icao_code: "TNU",
        name: "TransNusa Air Services",
        alias: "",
    },
    {
        iata_code: "8C",
        icao_code: "ATN",
        name: "Air Transport International",
        alias: "",
    },
    {
        iata_code: "8D",
        icao_code: "",
        name: "Servant Air",
        alias: "",
    },
    {
        iata_code: "8E",
        icao_code: "BRG",
        name: "Bering Air",
        alias: "",
    },
    {
        iata_code: "8F",
        icao_code: "STP",
        name: "STP Airways",
        alias: "",
    },
    {
        iata_code: "8G",
        icao_code: "MFG",
        name: "Mid Africa Aviation",
        alias: "",
    },
    {
        iata_code: "8H",
        icao_code: "BGH",
        name: "BH Air",
        alias: "",
    },
    {
        iata_code: "8I",
        icao_code: "NLU",
        name: "Insel Air Aruba",
        alias: "",
    },
    {
        iata_code: "8J",
        icao_code: "",
        name: "Línea Aerea Eco Jet",
        alias: "",
    },
    {
        iata_code: "8L",
        icao_code: "LKE",
        name: "Lucky Air",
        alias: "",
    },
    {
        iata_code: "8M",
        icao_code: "MMA",
        name: "Myanmar Airways International",
        alias: "",
    },
    {
        iata_code: "8N",
        icao_code: "REG",
        name: "Regional Air Services",
        alias: "",
    },
    {
        iata_code: "8P",
        icao_code: "PCO",
        name: "Pacific Coastal Airlines",
        alias: "Pac Coastal",
    },
    {
        iata_code: "8Q",
        icao_code: "OHY",
        name: "Onur Air",
        alias: "",
    },
    {
        iata_code: "8R",
        icao_code: "OLS",
        name: "Sol Líneas Aéreas",
        alias: "",
    },
    {
        iata_code: "8S",
        icao_code: "",
        name: "Shun Tak China",
        alias: "",
    },
    {
        iata_code: "8T",
        icao_code: "TID",
        name: "Air Tindi",
        alias: "",
    },
    {
        iata_code: "8U",
        icao_code: "AAW",
        name: "Afriqiyah Airways",
        alias: "",
    },
    {
        iata_code: "8V",
        icao_code: "ACP",
        name: "Astral Aviation",
        alias: "",
    },
    {
        iata_code: "8W",
        icao_code: "EDR",
        name: "Fly All Ways Airlines",
        alias: "",
    },
    {
        iata_code: "8Y",
        icao_code: "AAV",
        name: "Pan Pacific Airlines",
        alias: "",
    },
    {
        iata_code: "8Z",
        icao_code: "CGA",
        name: "Congo Airways",
        alias: "",
    },
    {
        iata_code: "9A",
        icao_code: "",
        name: "Aladia Airlines",
        alias: "Airatlantic",
    },
    {
        iata_code: "9B",
        icao_code: "",
        name: "AccesRail",
        alias: "",
    },
    {
        iata_code: "9C",
        icao_code: "CQH",
        name: "Spring Airlines",
        alias: "",
    },
    {
        iata_code: "9E",
        icao_code: "FLG",
        name: "Endeavor Air",
        alias: "",
    },
    {
        iata_code: "9F",
        icao_code: "",
        name: "Eurostar",
        alias: "",
    },
    {
        iata_code: "9G",
        icao_code: "",
        name: "9G Rail",
        alias: "",
    },
    {
        iata_code: "9H",
        icao_code: "CGN",
        name: "Chang An Airlines",
        alias: "",
    },
    {
        iata_code: "9I",
        icao_code: "LLR",
        name: "Air India Regional",
        alias: "",
    },
    {
        iata_code: "9J",
        icao_code: "DAN",
        name: "Dana Air",
        alias: "",
    },
    {
        iata_code: "9K",
        icao_code: "KAP",
        name: "Cape Air",
        alias: "",
    },
    {
        iata_code: "9L",
        icao_code: "WLN",
        name: "West Link Airways",
        alias: "",
    },
    {
        iata_code: "9M",
        icao_code: "GLR",
        name: "Central Mountain Air",
        alias: "",
    },
    {
        iata_code: "9N",
        icao_code: "TOS",
        name: "Tropic Air",
        alias: "",
    },
    {
        iata_code: "9P",
        icao_code: "PTR",
        name: "Air Arabia Jordan",
        alias: "Petra Airlines",
    },
    {
        iata_code: "9Q",
        icao_code: "CXE",
        name: "Caicos Express Airways",
        alias: "PB Air",
    },
    {
        iata_code: "9R",
        icao_code: "NSE",
        name: "SATENA",
        alias: "",
    },
    {
        iata_code: "9S",
        icao_code: "SOO",
        name: "Southern Air",
        alias: "",
    },
    {
        iata_code: "9T",
        icao_code: "RUN",
        name: "MyCargo Airlines",
        alias: "",
    },
    {
        iata_code: "9U",
        icao_code: "MLD",
        name: "Air Moldova",
        alias: "",
    },
    {
        iata_code: "9V",
        icao_code: "ROI",
        name: "Avior Airlines",
        alias: "",
    },
    {
        iata_code: "9W",
        icao_code: "JAI",
        name: "Jet Airways",
        alias: "",
    },
    {
        iata_code: "9X",
        icao_code: "FDY",
        name: "Southern Airways Express",
        alias: "",
    },
    {
        iata_code: "A0",
        icao_code: "MCJ",
        name: "Macair Jet",
        alias: "",
    },
    {
        iata_code: "A1",
        icao_code: "",
        name: "G Switch Works",
        alias: "",
    },
    {
        iata_code: "A2",
        icao_code: "AZI",
        name: "Astra Airlines",
        alias: "",
    },
    {
        iata_code: "A3",
        icao_code: "AEE",
        name: "Aegean Airlines",
        alias: "",
    },
    {
        iata_code: "A4",
        icao_code: "",
        name: "Azimuth",
        alias: "",
    },
    {
        iata_code: "A5",
        icao_code: "HOP",
        name: "HOP!",
        alias: "",
    },
    {
        iata_code: "A6",
        icao_code: "HTU",
        name: "Yunnan Hongtu Airline",
        alias: "",
    },
    {
        iata_code: "A7",
        icao_code: "CFV",
        name: "Aéreo Calafia",
        alias: "Aereo Calafia",
    },
    {
        iata_code: "A8",
        icao_code: "XAU",
        name: "Aerolink Uganda",
        alias: "",
    },
    {
        iata_code: "A9",
        icao_code: "",
        name: "Georgian Airways",
        alias: "Arizena Air",
    },
    {
        iata_code: "AA",
        icao_code: "AAL",
        name: "American Airlines",
        alias: "",
    },
    {
        iata_code: "AC",
        icao_code: "ACA",
        name: "Air Canada",
        alias: "",
    },
    {
        iata_code: "AD",
        icao_code: "AZU",
        name: "Azul Brazilian Airlines",
        alias: "Azul Linhas Aéreas Brasileiras",
    },
    {
        iata_code: "AE",
        icao_code: "MDA",
        name: "Mandarin Airlines",
        alias: "",
    },
    {
        iata_code: "AF",
        icao_code: "AFR",
        name: "Air France",
        alias: "",
    },
    {
        iata_code: "AG",
        icao_code: "",
        name: "Aruba Airlines",
        alias: "",
    },
    {
        iata_code: "AH",
        icao_code: "DAH",
        name: "Air Algerie",
        alias: "",
    },
    {
        iata_code: "AI",
        icao_code: "AIC",
        name: "Air India",
        alias: "",
    },
    {
        iata_code: "AJ",
        icao_code: "",
        name: "Aztec Worldwide Airlines",
        alias: "Aerocontracto",
    },
    {
        iata_code: "AK",
        icao_code: "AXM",
        name: "AirAsia",
        alias: "",
    },
    {
        iata_code: "AL",
        icao_code: "ALD",
        name: "Air Leisure",
        alias: "",
    },
    {
        iata_code: "AM",
        icao_code: "AMX",
        name: "AeroMexico",
        alias: "AeroMexico",
    },
    {
        iata_code: "AN",
        icao_code: "RLA",
        name: "HOP Airlinair",
        alias: "",
    },
    {
        iata_code: "AP",
        icao_code: "LAV",
        name: "AlbaStar",
        alias: "",
    },
    {
        iata_code: "AQ",
        icao_code: "",
        name: "9 Air",
        alias: "",
    },
    {
        iata_code: "AR",
        icao_code: "ARG",
        name: "Aerolíneas Argentinas",
        alias: "",
    },
    {
        iata_code: "AS",
        icao_code: "ASA",
        name: "Alaska Airlines",
        alias: "",
    },
    {
        iata_code: "AT",
        icao_code: "RAM",
        name: "Royal Air Maroc",
        alias: "",
    },
    {
        iata_code: "AU",
        icao_code: "AUT",
        name: "Austral Líneas Aéreas",
        alias: "Cielos del Sur",
    },
    {
        iata_code: "AV",
        icao_code: "AVA",
        name: "Avianca",
        alias: "",
    },
    {
        iata_code: "AW",
        icao_code: "AFW",
        name: "Africa World Airlines",
        alias: "",
    },
    {
        iata_code: "AX",
        icao_code: "LOF",
        name: "Trans States Airlines",
        alias: "",
    },
    {
        iata_code: "AY",
        icao_code: "FIN",
        name: "Finnair",
        alias: "",
    },
    {
        iata_code: "AZ",
        icao_code: "AZA",
        name: "Alitalia",
        alias: "Alitalia S.A.I.",
    },
    {
        iata_code: "B0",
        icao_code: "DJT",
        name: "La Compagnie",
        alias: "Dreamjet",
    },
    {
        iata_code: "B1",
        icao_code: "",
        name: "Cions Software",
        alias: "",
    },
    {
        iata_code: "B2",
        icao_code: "BRU",
        name: "Belavia",
        alias: "",
    },
    {
        iata_code: "B3",
        icao_code: "BTN",
        name: "Bhutan Airlines",
        alias: "Tashi Air",
    },
    {
        iata_code: "B4",
        icao_code: "TAN",
        name: "Zanair",
        alias: "",
    },
    {
        iata_code: "B5",
        icao_code: "EXZ",
        name: "Fly-SAX",
        alias: "",
    },
    {
        iata_code: "B6",
        icao_code: "JBU",
        name: "JetBlue",
        alias: "",
    },
    {
        iata_code: "B7",
        icao_code: "UIA",
        name: "Uni Airways",
        alias: "",
    },
    {
        iata_code: "B8",
        icao_code: "ERT",
        name: "Eritrean Airlines",
        alias: "",
    },
    {
        iata_code: "B9",
        icao_code: "IRB",
        name: "Iran Air Tours",
        alias: "",
    },
    {
        iata_code: "BA",
        icao_code: "BAW",
        name: "British Airways",
        alias: "",
    },
    {
        iata_code: "BB",
        icao_code: "SBS",
        name: "Seaborne Airlines",
        alias: "",
    },
    {
        iata_code: "BC",
        icao_code: "SKY",
        name: "Skymark Airlines",
        alias: "",
    },
    {
        iata_code: "BD",
        icao_code: "BYC",
        name: "Cambodia Bayon Airlines",
        alias: "",
    },
    {
        iata_code: "BE",
        icao_code: "BEE",
        name: "Flybe",
        alias: "Brit Europ",
    },
    {
        iata_code: "BF",
        icao_code: "FBU",
        name: "French Bee",
        alias: "",
    },
    {
        iata_code: "BG",
        icao_code: "BBC",
        name: "Biman Bangladesh Airline",
        alias: "",
    },
    {
        iata_code: "BH",
        icao_code: "BHA",
        name: "Hawkair",
        alias: "",
    },
    {
        iata_code: "BI",
        icao_code: "RBA",
        name: "Royal Brunei",
        alias: "Royalbrunei",
    },
    {
        iata_code: "BJ",
        icao_code: "LBT",
        name: "Nouvelair",
        alias: "",
    },
    {
        iata_code: "BK",
        icao_code: "OKA",
        name: "Okay Airways",
        alias: "Paradise Isl",
    },
    {
        iata_code: "BL",
        icao_code: "PIC",
        name: "Jetstar Pacific Airlines",
        alias: "Pacific Air",
    },
    {
        iata_code: "BM",
        icao_code: "BMR",
        name: "bmi regional",
        alias: "British Midland Regional Limited",
    },
    {
        iata_code: "BN",
        icao_code: "",
        name: "Bahrain Air",
        alias: "Horizon Airli",
    },
    {
        iata_code: "BO",
        icao_code: "",
        name: "BB Airways",
        alias: "",
    },
    {
        iata_code: "BP",
        icao_code: "BOT",
        name: "Air Botswana",
        alias: "",
    },
    {
        iata_code: "BR",
        icao_code: "EVA",
        name: "EVA Air",
        alias: "",
    },
    {
        iata_code: "BS",
        icao_code: "",
        name: "US-Bangla Airlines",
        alias: "",
    },
    {
        iata_code: "BT",
        icao_code: "BTI",
        name: "airBaltic",
        alias: "",
    },
    {
        iata_code: "BU",
        icao_code: "FPY",
        name: "Compagnie Africaine d'Aviation",
        alias: "",
    },
    {
        iata_code: "BV",
        icao_code: "BPA",
        name: "Blue Panorama Airlines",
        alias: "Blue Panorama",
    },
    {
        iata_code: "BW",
        icao_code: "BWA",
        name: "Caribbean Airlines",
        alias: "",
    },
    {
        iata_code: "BX",
        icao_code: "ABL",
        name: "Air Busan",
        alias: "",
    },
    {
        iata_code: "BY",
        icao_code: "TOM",
        name: "Thomson Airways",
        alias: "Britannia Airways",
    },
    {
        iata_code: "BZ",
        icao_code: "BBG",
        name: "Bluebird Airways",
        alias: "",
    },
    {
        iata_code: "C0",
        icao_code: "CLW",
        name: "Centralwings",
        alias: "",
    },
    {
        iata_code: "C1",
        icao_code: "",
        name: "Tectimes Sudamerica",
        alias: "",
    },
    {
        iata_code: "C2",
        icao_code: "",
        name: "Ceiba Intercontinental",
        alias: "Ceiba",
    },
    {
        iata_code: "C3",
        icao_code: "TDR",
        name: "Trade Air",
        alias: "",
    },
    {
        iata_code: "C4",
        icao_code: "",
        name: "Alma De Mexico",
        alias: "",
    },
    {
        iata_code: "C5",
        icao_code: "UCA",
        name: "CommutAir",
        alias: "Champlain Enterprises",
    },
    {
        iata_code: "C6",
        icao_code: "CJA",
        name: "Canjet Airlines",
        alias: "Bright Airlines",
    },
    {
        iata_code: "C7",
        icao_code: "RLE",
        name: "Cinnamon Air",
        alias: "Saffron Aviation",
    },
    {
        iata_code: "C8",
        icao_code: "CRA",
        name: "Cronos Airlines",
        alias: "",
    },
    {
        iata_code: "C9",
        icao_code: "SWZ",
        name: "Skywise",
        alias: "Cabolor",
    },
    {
        iata_code: "CA",
        icao_code: "CCA",
        name: "Air China",
        alias: "",
    },
    {
        iata_code: "CC",
        icao_code: "OMT",
        name: "CM Airlines",
        alias: "",
    },
    {
        iata_code: "CD",
        icao_code: "CND",
        name: "Corendon Dutch Airlines",
        alias: "Seaview Air",
    },
    {
        iata_code: "CE",
        icao_code: "CLG",
        name: "Chalair Aviation",
        alias: "Chalair",
    },
    {
        iata_code: "CF",
        icao_code: "CYZ",
        name: "China Postal Airlines",
        alias: "",
    },
    {
        iata_code: "CG",
        icao_code: "TOK",
        name: "PNG Air",
        alias: "",
    },
    {
        iata_code: "CH",
        icao_code: "BMJ",
        name: "Bemidji Airlines",
        alias: "",
    },
    {
        iata_code: "CI",
        icao_code: "CAL",
        name: "China Airlines",
        alias: "",
    },
    {
        iata_code: "CJ",
        icao_code: "CFE",
        name: "BA CityFlyer",
        alias: "China North",
    },
    {
        iata_code: "CK",
        icao_code: "NDR",
        name: "China Cargo Airlines",
        alias: "Andesmar Lin",
    },
    {
        iata_code: "CL",
        icao_code: "CLH",
        name: "Lufthansa CityLine",
        alias: "",
    },
    {
        iata_code: "CM",
        icao_code: "CMP",
        name: "Copa Airlines",
        alias: "Compañía Panameña de Aviación",
    },
    {
        iata_code: "CN",
        icao_code: "GDC",
        name: "Grand China Air",
        alias: "",
    },
    {
        iata_code: "CO",
        icao_code: "FCB",
        name: "Cobalt Air",
        alias: "",
    },
    {
        iata_code: "CP",
        icao_code: "CPZ",
        name: "Compass Airlines",
        alias: "",
    },
    {
        iata_code: "CQ",
        icao_code: "",
        name: "Coastal Travels",
        alias: "",
    },
    {
        iata_code: "CR",
        icao_code: "",
        name: "OAG Worldwide",
        alias: "",
    },
    {
        iata_code: "CS",
        icao_code: "CXB",
        name: "Comlux Aruba",
        alias: "",
    },
    {
        iata_code: "CT",
        icao_code: "CYL",
        name: "Alitalia CityLiner",
        alias: "Air One CityLiner",
    },
    {
        iata_code: "CU",
        icao_code: "CUB",
        name: "Cubana De Aviacion",
        alias: "Cubana",
    },
    {
        iata_code: "CV",
        icao_code: "CLX",
        name: "Cargolux",
        alias: "",
    },
    {
        iata_code: "CX",
        icao_code: "CPA",
        name: "Cathay Pacific",
        alias: "",
    },
    {
        iata_code: "CY",
        icao_code: "CYP",
        name: "Charlie Airlines",
        alias: "",
    },
    {
        iata_code: "CZ",
        icao_code: "CSN",
        name: "China Southern Airlines",
        alias: "",
    },
    {
        iata_code: "D0",
        icao_code: "DHK",
        name: "DHL Air UK",
        alias: "",
    },
    {
        iata_code: "D2",
        icao_code: "SSF",
        name: "Severstal Air Company",
        alias: "",
    },
    {
        iata_code: "D3",
        icao_code: "DAO",
        name: "Daallo Airlines",
        alias: "Daallo Airlin",
    },
    {
        iata_code: "D4",
        icao_code: "LID",
        name: "DART Ltd",
        alias: "",
    },
    {
        iata_code: "D6",
        icao_code: "INL",
        name: "Interair",
        alias: "",
    },
    {
        iata_code: "D7",
        icao_code: "XAX",
        name: "AirAsia X",
        alias: "",
    },
    {
        iata_code: "D8",
        icao_code: "IBK",
        name: "Norwegian Air International",
        alias: "",
    },
    {
        iata_code: "D9",
        icao_code: "DNV",
        name: "Donavia",
        alias: "",
    },
    {
        iata_code: "DA",
        icao_code: "GEO",
        name: "Air Georgia",
        alias: "Air Georgia",
    },
    {
        iata_code: "DB",
        icao_code: "BZH",
        name: "HOP Brit Air",
        alias: "",
    },
    {
        iata_code: "DC",
        icao_code: "BRX",
        name: "Braathens Regional",
        alias: "",
    },
    {
        iata_code: "DD",
        icao_code: "",
        name: "Nok Air",
        alias: "",
    },
    {
        iata_code: "DE",
        icao_code: "CFG",
        name: "Condor",
        alias: "",
    },
    {
        iata_code: "DF",
        icao_code: "CIB",
        name: "Condor Berlin",
        alias: "",
    },
    {
        iata_code: "DG",
        icao_code: "SRQ",
        name: "Cebgo",
        alias: "",
    },
    {
        iata_code: "DH",
        icao_code: "DTS",
        name: "Douniah Airlines",
        alias: "",
    },
    {
        iata_code: "DI",
        icao_code: "NRS",
        name: "Norwegian Air UK",
        alias: "",
    },
    {
        iata_code: "DJ",
        icao_code: "WAJ",
        name: "AirAsia Japan",
        alias: "",
    },
    {
        iata_code: "DK",
        icao_code: "VKG",
        name: "Thomas Cook Airlines",
        alias: "Eastland Air",
    },
    {
        iata_code: "DL",
        icao_code: "DAL",
        name: "Delta Air Lines",
        alias: "",
    },
    {
        iata_code: "DM",
        icao_code: "DEX",
        name: "Asian Air",
        alias: "Sterling Blue",
    },
    {
        iata_code: "DN",
        icao_code: "NAA",
        name: "Norwegian Air Argentina",
        alias: "",
    },
    {
        iata_code: "DO",
        icao_code: "",
        name: "Discovery Airways",
        alias: "Air Vallee",
    },
    {
        iata_code: "DP",
        icao_code: "",
        name: "Pobeda",
        alias: "",
    },
    {
        iata_code: "DQ",
        icao_code: "DCP",
        name: "Delta Connection",
        alias: "Coastal Air",
    },
    {
        iata_code: "DR",
        icao_code: "",
        name: "Ruili Airlines",
        alias: "Air Link",
    },
    {
        iata_code: "DS",
        icao_code: "EZS",
        name: "easyJet Switzerland",
        alias: "",
    },
    {
        iata_code: "DT",
        icao_code: "DTA",
        name: "Taag",
        alias: "",
    },
    {
        iata_code: "DU",
        icao_code: "NLH",
        name: "Norwegian Long Haul",
        alias: "",
    },
    {
        iata_code: "DV",
        icao_code: "VSV",
        name: "SCAT Airlines",
        alias: "",
    },
    {
        iata_code: "DW",
        icao_code: "GDE",
        name: "Great Dane Airlines",
        alias: "",
    },
    {
        iata_code: "DX",
        icao_code: "DTR",
        name: "Danish Air Transport",
        alias: "",
    },
    {
        iata_code: "DY",
        icao_code: "NAX",
        name: "Norwegian Air Shuttle (NAS)",
        alias: "",
    },
    {
        iata_code: "DZ",
        icao_code: "",
        name: "Donghai Airlines",
        alias: "",
    },
    {
        iata_code: "E2",
        icao_code: "EWE",
        name: "Eurowings Europe",
        alias: "",
    },
    {
        iata_code: "E3",
        icao_code: "VGO",
        name: "New Gen Airways",
        alias: "Sabaidee Airways",
    },
    {
        iata_code: "E4",
        icao_code: "ENT",
        name: "Enter Air",
        alias: "",
    },
    {
        iata_code: "E5",
        icao_code: "RBG",
        name: "Air Arabia Egypt",
        alias: "",
    },
    {
        iata_code: "E6",
        icao_code: "",
        name: "Bringer Air Cargo",
        alias: "",
    },
    {
        iata_code: "E7",
        icao_code: "",
        name: "Equaflight Service",
        alias: "Fly European",
    },
    {
        iata_code: "E8",
        icao_code: "GTA",
        name: "City Airways",
        alias: "",
    },
    {
        iata_code: "E9",
        icao_code: "EVE",
        name: "Evelop Airlines",
        alias: "",
    },
    {
        iata_code: "EB",
        icao_code: "PLM",
        name: "Wamos Air",
        alias: "Pullmantur Air",
    },
    {
        iata_code: "EC",
        icao_code: "BOS",
        name: "OpenSkies",
        alias: "",
    },
    {
        iata_code: "ED",
        icao_code: "AXE",
        name: "Air Explore",
        alias: "Airblue",
    },
    {
        iata_code: "EE",
        icao_code: "EST",
        name: "Nordica",
        alias: "",
    },
    {
        iata_code: "EF",
        icao_code: "",
        name: "Fly CamInter",
        alias: "",
    },
    {
        iata_code: "EG",
        icao_code: "ETJ",
        name: "East Air",
        alias: "Japan Asia",
    },
    {
        iata_code: "EH",
        icao_code: "AKX",
        name: "ANA Wings",
        alias: "",
    },
    {
        iata_code: "EI",
        icao_code: "EIN",
        name: "Aer Lingus",
        alias: "",
    },
    {
        iata_code: "EJ",
        icao_code: "NEA",
        name: "New England Airlines",
        alias: "New Englan",
    },
    {
        iata_code: "EK",
        icao_code: "UAE",
        name: "Emirates",
        alias: "",
    },
    {
        iata_code: "EL",
        icao_code: "ELB",
        name: "Ellinair",
        alias: "",
    },
    {
        iata_code: "EM",
        icao_code: "",
        name: "Aero Benin",
        alias: "",
    },
    {
        iata_code: "EN",
        icao_code: "DLA",
        name: "Air Dolomiti",
        alias: "Air Dolomiti",
    },
    {
        iata_code: "EO",
        icao_code: "AGY",
        name: "Air Go Egypt",
        alias: "",
    },
    {
        iata_code: "EP",
        icao_code: "IRC",
        name: "Iran Aseman Airlines",
        alias: "",
    },
    {
        iata_code: "EQ",
        icao_code: "TAE",
        name: "TAME",
        alias: "TAME EP Línea Aérea del Ecuador",
    },
    {
        iata_code: "ER",
        icao_code: "SEP",
        name: "SereneAir",
        alias: "",
    },
    {
        iata_code: "ES",
        icao_code: "DHX",
        name: "DHL International Aviation ME",
        alias: "",
    },
    {
        iata_code: "ET",
        icao_code: "ETH",
        name: "Ethiopian Airlines",
        alias: "",
    },
    {
        iata_code: "EU",
        icao_code: "",
        name: "Chengdu Airlines",
        alias: "Ecuatoriana",
    },
    {
        iata_code: "EV",
        icao_code: "ASQ",
        name: "ExpressJet Airlines",
        alias: "",
    },
    {
        iata_code: "EW",
        icao_code: "EWG",
        name: "Eurowings",
        alias: "",
    },
    {
        iata_code: "EY",
        icao_code: "ETD",
        name: "Etihad Airways",
        alias: "",
    },
    {
        iata_code: "EZ",
        icao_code: "SUS",
        name: "Sun Air of Scandinavia",
        alias: "",
    },
    {
        iata_code: "F1",
        icao_code: "",
        name: "Farelogix",
        alias: "",
    },
    {
        iata_code: "F2",
        icao_code: "XLK",
        name: "Safarilink Aviation",
        alias: "",
    },
    {
        iata_code: "F3",
        icao_code: "FAD",
        name: "Flyadeal",
        alias: "",
    },
    {
        iata_code: "F4",
        icao_code: "WAF",
        name: "Air Flamenco",
        alias: "Air Flamenco",
    },
    {
        iata_code: "F7",
        icao_code: "DWT",
        name: "Etihad Regional",
        alias: "Darwin Airline",
    },
    {
        iata_code: "F8",
        icao_code: "FLE",
        name: "Flair Airlines",
        alias: "",
    },
    {
        iata_code: "F9",
        icao_code: "FTT",
        name: "Frontier Airlines",
        alias: "",
    },
    {
        iata_code: "FA",
        icao_code: "SFR",
        name: "Safair",
        alias: "",
    },
    {
        iata_code: "FB",
        icao_code: "LZB",
        name: "Bulgaria Air",
        alias: "Balkan Air To",
    },
    {
        iata_code: "FC",
        icao_code: "",
        name: "Fly Corporate",
        alias: "",
    },
    {
        iata_code: "FD",
        icao_code: "AIQ",
        name: "Thai AirAsia",
        alias: "",
    },
    {
        iata_code: "FE",
        icao_code: "FEA",
        name: "Far Eastern Air Transport",
        alias: "",
    },
    {
        iata_code: "FG",
        icao_code: "AFG",
        name: "Ariana Afghan Airlines",
        alias: "Ariana",
    },
    {
        iata_code: "FH",
        icao_code: "FHY",
        name: "Freebird Airlines",
        alias: "Futura Intl",
    },
    {
        iata_code: "FI",
        icao_code: "ICE",
        name: "Icelandair",
        alias: "",
    },
    {
        iata_code: "FJ",
        icao_code: "FJI",
        name: "Fiji Airways",
        alias: "Fiji Airways",
    },
    {
        iata_code: "FK",
        icao_code: "KFA",
        name: "Kelowna Flightcraft Air Charter",
        alias: "",
    },
    {
        iata_code: "FL",
        icao_code: "TRS",
        name: "Airtran Airways",
        alias: "",
    },
    {
        iata_code: "FM",
        icao_code: "CSH",
        name: "Shanghai Airlines",
        alias: "",
    },
    {
        iata_code: "FN",
        icao_code: "FTZ",
        name: "Fastjet",
        alias: "",
    },
    {
        iata_code: "FO",
        icao_code: "FXX",
        name: "Felix Airways",
        alias: "",
    },
    {
        iata_code: "FP",
        icao_code: "",
        name: "Pelican Airlines",
        alias: "",
    },
    {
        iata_code: "FR",
        icao_code: "RYR",
        name: "Ryanair",
        alias: "",
    },
    {
        iata_code: "FT",
        icao_code: "FEG",
        name: "FlyEgypt",
        alias: "",
    },
    {
        iata_code: "FU",
        icao_code: "FZA",
        name: "Fuzhou Airlines",
        alias: "",
    },
    {
        iata_code: "FV",
        icao_code: "SDM",
        name: "Rossiya",
        alias: "",
    },
    {
        iata_code: "FW",
        icao_code: "IBX",
        name: "Ibex Airlines",
        alias: "",
    },
    {
        iata_code: "FX",
        icao_code: "FXA",
        name: "Fedex",
        alias: "Express Air",
    },
    {
        iata_code: "FY",
        icao_code: "FFM",
        name: "Flyfirefly",
        alias: "",
    },
    {
        iata_code: "FZ",
        icao_code: "FDB",
        name: "flydubai",
        alias: "Dubai Aviation Corporation",
    },
    {
        iata_code: "G2",
        icao_code: "",
        name: "Galeyr Airline",
        alias: "",
    },
    {
        iata_code: "G3",
        icao_code: "GLO",
        name: "Gol Transportes Aéreos",
        alias: "",
    },
    {
        iata_code: "G4",
        icao_code: "AAY",
        name: "Allegiant Air",
        alias: "",
    },
    {
        iata_code: "G5",
        icao_code: "HXA",
        name: "China Express Airlines",
        alias: "",
    },
    {
        iata_code: "G6",
        icao_code: "DNM",
        name: "Denim Air",
        alias: "",
    },
    {
        iata_code: "G7",
        icao_code: "GJS",
        name: "GoJet Airlines",
        alias: "",
    },
    {
        iata_code: "G8",
        icao_code: "GOW",
        name: "GoAir",
        alias: "",
    },
    {
        iata_code: "G9",
        icao_code: "ABY",
        name: "Air Arabia",
        alias: "",
    },
    {
        iata_code: "GA",
        icao_code: "GIA",
        name: "Garuda Indonesia",
        alias: "",
    },
    {
        iata_code: "GB",
        icao_code: "ABX",
        name: "ABX Air",
        alias: "",
    },
    {
        iata_code: "GC",
        icao_code: "",
        name: "Global Feeder Services",
        alias: "",
    },
    {
        iata_code: "GE",
        icao_code: "TNA",
        name: "TransAsia Airways",
        alias: "",
    },
    {
        iata_code: "GF",
        icao_code: "GFA",
        name: "Gulf Air",
        alias: "",
    },
    {
        iata_code: "GG",
        icao_code: "KYE",
        name: "Sky Lease Cargo",
        alias: "",
    },
    {
        iata_code: "GH",
        icao_code: "GHA",
        name: "Globus",
        alias: "Ghana Air",
    },
    {
        iata_code: "GI",
        icao_code: "IKA",
        name: "Itek Air",
        alias: "",
    },
    {
        iata_code: "GJ",
        icao_code: "CDC",
        name: "Zhejiang Loong Airlines",
        alias: "Eurofly",
    },
    {
        iata_code: "GK",
        icao_code: "",
        name: "Jetstar Japan",
        alias: "",
    },
    {
        iata_code: "GL",
        icao_code: "GRL",
        name: "Air Greenland",
        alias: "",
    },
    {
        iata_code: "GM",
        icao_code: "GSW",
        name: "Germania Flug",
        alias: "",
    },
    {
        iata_code: "GO",
        icao_code: "GOE",
        name: "Go",
        alias: "",
    },
    {
        iata_code: "GP",
        icao_code: "RIV",
        name: "APG Airlines",
        alias: "",
    },
    {
        iata_code: "GQ",
        icao_code: "SEH",
        name: "Sky Express",
        alias: "",
    },
    {
        iata_code: "GR",
        icao_code: "AUR",
        name: "Aurigny Air Services",
        alias: "",
    },
    {
        iata_code: "GS",
        icao_code: "GCR",
        name: "Tianjin Airlines",
        alias: "",
    },
    {
        iata_code: "GT",
        icao_code: "CGH",
        name: "Air Guilin",
        alias: "",
    },
    {
        iata_code: "GU",
        icao_code: "GUG",
        name: "Aviateca",
        alias: "",
    },
    {
        iata_code: "GV",
        icao_code: "SWV",
        name: "Grant Aviation",
        alias: "",
    },
    {
        iata_code: "GW",
        icao_code: "SKG",
        name: "SkyGreece Airlines",
        alias: "",
    },
    {
        iata_code: "GX",
        icao_code: "",
        name: "Guangxi Beibu Gulf Airlines",
        alias: "",
    },
    {
        iata_code: "GY",
        icao_code: "",
        name: "Colorful Guizhou Airlines",
        alias: "",
    },
    {
        iata_code: "GZ",
        icao_code: "",
        name: "Air Rarotonga",
        alias: "",
    },
    {
        iata_code: "H1",
        icao_code: "",
        name: "Hahn Air Systems",
        alias: "",
    },
    {
        iata_code: "H2",
        icao_code: "SKU",
        name: "Sky Airline",
        alias: "",
    },
    {
        iata_code: "H3",
        icao_code: "HES",
        name: "Harbour Air",
        alias: "",
    },
    {
        iata_code: "H4",
        icao_code: "AEH",
        name: "Aero4M",
        alias: "",
    },
    {
        iata_code: "H6",
        icao_code: "BUC",
        name: "Bulgarian Air Charter",
        alias: "",
    },
    {
        iata_code: "H7",
        icao_code: "EGU",
        name: "Eagle Air",
        alias: "",
    },
    {
        iata_code: "H8",
        icao_code: "",
        name: "Chilejet",
        alias: "",
    },
    {
        iata_code: "H9",
        icao_code: "HIM",
        name: "Himalaya Airlines",
        alias: "",
    },
    {
        iata_code: "HA",
        icao_code: "HAL",
        name: "Hawaiian Airlines",
        alias: "",
    },
    {
        iata_code: "HB",
        icao_code: "AAQ",
        name: "Asia Atlantic Airlines",
        alias: "",
    },
    {
        iata_code: "HC",
        icao_code: "SZN",
        name: "Air Senegal",
        alias: "",
    },
    {
        iata_code: "HD",
        icao_code: "ADO",
        name: "Air Do",
        alias: "Hokkaido International Airlines",
    },
    {
        iata_code: "HE",
        icao_code: "",
        name: "LGW Luftfahrtges Walter",
        alias: "",
    },
    {
        iata_code: "HF",
        icao_code: "",
        name: "Air Cote D Ivoire",
        alias: "Aircoteivoire",
    },
    {
        iata_code: "HH",
        icao_code: "TBM",
        name: "Taban Airlines",
        alias: "",
    },
    {
        iata_code: "HI",
        icao_code: "",
        name: "Papillon Airways",
        alias: "Papillonair",
    },
    {
        iata_code: "HJ",
        icao_code: "HEJ",
        name: "Hellas Jet",
        alias: "Hellenic Star",
    },
    {
        iata_code: "HK",
        icao_code: "",
        name: "Skippers Aviation",
        alias: "",
    },
    {
        iata_code: "HM",
        icao_code: "SEY",
        name: "Air Seychelles",
        alias: "",
    },
    {
        iata_code: "HN",
        icao_code: "",
        name: "Afghan Jet International",
        alias: "",
    },
    {
        iata_code: "HO",
        icao_code: "DKH",
        name: "Juneyao Airlines",
        alias: "",
    },
    {
        iata_code: "HP",
        icao_code: "APF",
        name: "Amapola Flyg",
        alias: "",
    },
    {
        iata_code: "HQ",
        icao_code: "TCW",
        name: "Thomas Cook Airlines Belgium",
        alias: "Harmony Air",
    },
    {
        iata_code: "HR",
        icao_code: "HHN",
        name: "Hahn Air",
        alias: "",
    },
    {
        iata_code: "HS",
        icao_code: "HLI",
        name: "Heli Sécurité",
        alias: "",
    },
    {
        iata_code: "HU",
        icao_code: "CHH",
        name: "Hainan Airlines",
        alias: "",
    },
    {
        iata_code: "HV",
        icao_code: "TRA",
        name: "Transavia Airlines",
        alias: "",
    },
    {
        iata_code: "HW",
        icao_code: "",
        name: "North Wright Air",
        alias: "North Wright",
    },
    {
        iata_code: "HX",
        icao_code: "CRK",
        name: "Hong Kong Airlines",
        alias: "",
    },
    {
        iata_code: "HY",
        icao_code: "UZB",
        name: "Uzbekistan Airways",
        alias: "",
    },
    {
        iata_code: "HZ",
        icao_code: "SHU",
        name: "Aurora Airlines",
        alias: "SAT Airlines",
    },
    {
        iata_code: "I1",
        icao_code: "",
        name: "CTS Viaggi",
        alias: "",
    },
    {
        iata_code: "I2",
        icao_code: "IBS",
        name: "Iberia Express",
        alias: "",
    },
    {
        iata_code: "I3",
        icao_code: "TBZ",
        name: "ATA Airlines",
        alias: "",
    },
    {
        iata_code: "I4",
        icao_code: "",
        name: "Scott Air",
        alias: "",
    },
    {
        iata_code: "I5",
        icao_code: "",
        name: "AirAsia India",
        alias: "",
    },
    {
        iata_code: "I6",
        icao_code: "MPK",
        name: "Air Indus",
        alias: "",
    },
    {
        iata_code: "I7",
        icao_code: "IIA",
        name: "Int'Air Îles",
        alias: "Int'Air Iles",
    },
    {
        iata_code: "I8",
        icao_code: "IZA",
        name: "Izhavia",
        alias: "Aboriginalair",
    },
    {
        iata_code: "IA",
        icao_code: "IAW",
        name: "Iraqi Airways",
        alias: "",
    },
    {
        iata_code: "IB",
        icao_code: "IBE",
        name: "Iberia",
        alias: "",
    },
    {
        iata_code: "IC",
        icao_code: "",
        name: "Nacil Indian Airline",
        alias: "",
    },
    {
        iata_code: "ID",
        icao_code: "BTK",
        name: "Batik Air",
        alias: "",
    },
    {
        iata_code: "IE",
        icao_code: "SOL",
        name: "Solomon Airlines",
        alias: "Solomon Air",
    },
    {
        iata_code: "IF",
        icao_code: "FBA",
        name: "FlyBaghdad",
        alias: "",
    },
    {
        iata_code: "IG",
        icao_code: "ISS",
        name: "Air Italy",
        alias: "",
    },
    {
        iata_code: "IH",
        icao_code: "MZA",
        name: "Jsc Irtysh Air",
        alias: "Falcon Air",
    },
    {
        iata_code: "II",
        icao_code: "CSQ",
        name: "IBC Airways",
        alias: "",
    },
    {
        iata_code: "IJ",
        icao_code: "",
        name: "Spring Airlines Japan",
        alias: "",
    },
    {
        iata_code: "IK",
        icao_code: "AKL",
        name: "Air Kiribati",
        alias: "",
    },
    {
        iata_code: "IL",
        icao_code: "",
        name: "Trigana Air Service",
        alias: "Istanbul Air",
    },
    {
        iata_code: "IM",
        icao_code: "",
        name: "Mint Líneas Aéreas",
        alias: "Intensive Air",
    },
    {
        iata_code: "IN",
        icao_code: "NIH",
        name: "NAM Air",
        alias: "",
    },
    {
        iata_code: "IO",
        icao_code: "IAE",
        name: "IrAero",
        alias: "",
    },
    {
        iata_code: "IP",
        icao_code: "IWD",
        name: "Iberworld Airlines",
        alias: "Atyrauauejoly",
    },
    {
        iata_code: "IQ",
        icao_code: "",
        name: "Qazaq Air",
        alias: "",
    },
    {
        iata_code: "IR",
        icao_code: "IRA",
        name: "Iran Air",
        alias: "",
    },
    {
        iata_code: "IS",
        icao_code: "PNX",
        name: "AIS Airlines",
        alias: "",
    },
    {
        iata_code: "IT",
        icao_code: "TTW",
        name: "Tigerair Taiwan",
        alias: "",
    },
    {
        iata_code: "IU",
        icao_code: "ASN",
        name: "Hevilift",
        alias: "Air Straubing",
    },
    {
        iata_code: "IV",
        icao_code: "",
        name: "Air Djibouti",
        alias: "",
    },
    {
        iata_code: "IW",
        icao_code: "WON",
        name: "Wings Air",
        alias: "",
    },
    {
        iata_code: "IX",
        icao_code: "AXB",
        name: "Air India Express",
        alias: "",
    },
    {
        iata_code: "IY",
        icao_code: "IYE",
        name: "Yemen Airways",
        alias: "",
    },
    {
        iata_code: "IZ",
        icao_code: "AIZ",
        name: "Arkia",
        alias: "Arkia Israeli",
    },
    {
        iata_code: "J0",
        icao_code: "",
        name: "Jetlink Express",
        alias: "",
    },
    {
        iata_code: "J1",
        icao_code: "",
        name: "OntJet",
        alias: "",
    },
    {
        iata_code: "J2",
        icao_code: "AHY",
        name: "Azerbaijan Airlines",
        alias: "",
    },
    {
        iata_code: "J3",
        icao_code: "PLR",
        name: "Northwestern Air Lease",
        alias: "Nw Air Lease",
    },
    {
        iata_code: "J4",
        icao_code: "BDR",
        name: "Badr Airlines",
        alias: "",
    },
    {
        iata_code: "J5",
        icao_code: "",
        name: "Alaska Seaplane Service",
        alias: "Stock Sochi",
    },
    {
        iata_code: "J6",
        icao_code: "OPS",
        name: "Jet-Ops",
        alias: "Seawings",
    },
    {
        iata_code: "J7",
        icao_code: "ABS",
        name: "Afrijet Business Service",
        alias: "",
    },
    {
        iata_code: "J8",
        icao_code: "BVT",
        name: "Berjaya Air",
        alias: "",
    },
    {
        iata_code: "J9",
        icao_code: "",
        name: "Jazeera Airways",
        alias: "",
    },
    {
        iata_code: "JA",
        icao_code: "",
        name: "Jetsmart",
        alias: "",
    },
    {
        iata_code: "JB",
        icao_code: "JBA",
        name: "Helijet International",
        alias: "Helijet",
    },
    {
        iata_code: "JC",
        icao_code: "JAC",
        name: "Japan Air Commuter",
        alias: "",
    },
    {
        iata_code: "JD",
        icao_code: "CBJ",
        name: "Beijing Capital Airlines",
        alias: "",
    },
    {
        iata_code: "JE",
        icao_code: "MNO",
        name: "Mango",
        alias: "",
    },
    {
        iata_code: "JF",
        icao_code: "JAA",
        name: "Jet Asia Airways",
        alias: "",
    },
    {
        iata_code: "JG",
        icao_code: "",
        name: "Jetgo Australia",
        alias: "",
    },
    {
        iata_code: "JH",
        icao_code: "FDA",
        name: "Fuji Dream Airlines",
        alias: "Nordeste",
    },
    {
        iata_code: "JI",
        icao_code: "",
        name: "Eastern Caribbean Airlines",
        alias: "",
    },
    {
        iata_code: "JJ",
        icao_code: "TAM",
        name: "LATAM Brasil",
        alias: "",
    },
    {
        iata_code: "JL",
        icao_code: "JAL",
        name: "Japan Airlines",
        alias: "JAL",
    },
    {
        iata_code: "JM",
        icao_code: "KQA",
        name: "Jambojet",
        alias: "",
    },
    {
        iata_code: "JO",
        icao_code: "JTG",
        name: "Jet Time",
        alias: "Jalways",
    },
    {
        iata_code: "JQ",
        icao_code: "JST",
        name: "Jetstar",
        alias: "Jetstar Airways",
    },
    {
        iata_code: "JR",
        icao_code: "JOY",
        name: "Joy Air",
        alias: "",
    },
    {
        iata_code: "JS",
        icao_code: "KOR",
        name: "Air Koryo",
        alias: "Kor Air Dpr",
    },
    {
        iata_code: "JT",
        icao_code: "LNI",
        name: "Lion Air",
        alias: "",
    },
    {
        iata_code: "JU",
        icao_code: "ASL",
        name: "Air Serbia",
        alias: "",
    },
    {
        iata_code: "JV",
        icao_code: "BLS",
        name: "Bearskin Airlines",
        alias: "",
    },
    {
        iata_code: "JW",
        icao_code: "VNL",
        name: "Vanilla Air",
        alias: "",
    },
    {
        iata_code: "JX",
        icao_code: "",
        name: "DAC East Africa",
        alias: "",
    },
    {
        iata_code: "JY",
        icao_code: "IWY",
        name: "InterCaribbean Airways",
        alias: "Air Turcks",
    },
    {
        iata_code: "K3",
        icao_code: "",
        name: "Taquan Air Services",
        alias: "",
    },
    {
        iata_code: "K4",
        icao_code: "",
        name: "ALS",
        alias: "",
    },
    {
        iata_code: "K5",
        icao_code: "SQH",
        name: "SeaPort",
        alias: "",
    },
    {
        iata_code: "K6",
        icao_code: "KHV",
        name: "Cambodia Angkor Air",
        alias: "",
    },
    {
        iata_code: "K7",
        icao_code: "KBZ",
        name: "Air KBZ",
        alias: "",
    },
    {
        iata_code: "K8",
        icao_code: "KND",
        name: "Kan Air",
        alias: "",
    },
    {
        iata_code: "K9",
        icao_code: "KFS",
        name: "Kalitta Charters",
        alias: "",
    },
    {
        iata_code: "KA",
        icao_code: "HDA",
        name: "Cathay Dragon",
        alias: "",
    },
    {
        iata_code: "KB",
        icao_code: "DRK",
        name: "Druk Air",
        alias: "",
    },
    {
        iata_code: "KC",
        icao_code: "KZR",
        name: "Air Astana",
        alias: "",
    },
    {
        iata_code: "KD",
        icao_code: "WGN",
        name: "Western Global Airlines",
        alias: "",
    },
    {
        iata_code: "KE",
        icao_code: "KAL",
        name: "Korean Air",
        alias: "",
    },
    {
        iata_code: "KF",
        icao_code: "ABB",
        name: "Air Belgium",
        alias: "",
    },
    {
        iata_code: "KG",
        icao_code: "GTV",
        name: "Aerogaviota",
        alias: "King Island",
    },
    {
        iata_code: "KH",
        icao_code: "AAH",
        name: "Aloha Air Cargo",
        alias: "",
    },
    {
        iata_code: "KI",
        icao_code: "",
        name: "KrasAvia",
        alias: "",
    },
    {
        iata_code: "KJ",
        icao_code: "IAH",
        name: "Air Incheon",
        alias: "",
    },
    {
        iata_code: "KK",
        icao_code: "KKK",
        name: "AtlasGlobal",
        alias: "",
    },
    {
        iata_code: "KL",
        icao_code: "KLM",
        name: "KLM",
        alias: "KLM Royal Dutch Airlines",
    },
    {
        iata_code: "KM",
        icao_code: "AMC",
        name: "Air Malta",
        alias: "",
    },
    {
        iata_code: "KN",
        icao_code: "CUA",
        name: "China United Airlines",
        alias: "",
    },
    {
        iata_code: "KO",
        icao_code: "KMA",
        name: "Komiaviatrans",
        alias: "",
    },
    {
        iata_code: "KP",
        icao_code: "SKK",
        name: "ASKY Airlines",
        alias: "",
    },
    {
        iata_code: "KQ",
        icao_code: "KQA",
        name: "Kenya Airways",
        alias: "",
    },
    {
        iata_code: "KR",
        icao_code: "EAA",
        name: "Air Bishkek",
        alias: "",
    },
    {
        iata_code: "KS",
        icao_code: "PEN",
        name: "Penair",
        alias: "Pen Air",
    },
    {
        iata_code: "KT",
        icao_code: "",
        name: "Fly Kiss",
        alias: "",
    },
    {
        iata_code: "KU",
        icao_code: "KAC",
        name: "Kuwait Airways",
        alias: "",
    },
    {
        iata_code: "KV",
        icao_code: "SKV",
        name: "Sky Regional Airlines",
        alias: "",
    },
    {
        iata_code: "KW",
        icao_code: "",
        name: "Kharkiv Airlines",
        alias: "",
    },
    {
        iata_code: "KX",
        icao_code: "CAY",
        name: "Cayman Airways",
        alias: "",
    },
    {
        iata_code: "KY",
        icao_code: "KNA",
        name: "Kunming Airlines",
        alias: "",
    },
    {
        iata_code: "KZ",
        icao_code: "NCA",
        name: "Nippon Cargo Airlines",
        alias: "",
    },
    {
        iata_code: "L1",
        icao_code: "",
        name: "E Savtravel",
        alias: "",
    },
    {
        iata_code: "L3",
        icao_code: "",
        name: "Lynx Aviation",
        alias: "Ltu Billa Luf",
    },
    {
        iata_code: "L4",
        icao_code: "",
        name: "Air Service Liege (ASL)",
        alias: "",
    },
    {
        iata_code: "L5",
        icao_code: "TLB",
        name: "Atlantique Air Assistance",
        alias: "",
    },
    {
        iata_code: "L6",
        icao_code: "",
        name: "Mauritanian Airlines Int",
        alias: "",
    },
    {
        iata_code: "L7",
        icao_code: "LHS",
        name: "Lugansk Airlines",
        alias: "",
    },
    {
        iata_code: "L8",
        icao_code: "",
        name: "Afric Aviation",
        alias: "",
    },
    {
        iata_code: "L9",
        icao_code: "BAL",
        name: "Belle Air Europe",
        alias: "",
    },
    {
        iata_code: "LA",
        icao_code: "LAN",
        name: "LATAM Chile",
        alias: "",
    },
    {
        iata_code: "LB",
        icao_code: "",
        name: "Air Costa",
        alias: "",
    },
    {
        iata_code: "LC",
        icao_code: "PTI",
        name: "ECAir",
        alias: "",
    },
    {
        iata_code: "LF",
        icao_code: "VTE",
        name: "Corporate Flight Management",
        alias: "",
    },
    {
        iata_code: "LG",
        icao_code: "LGL",
        name: "Luxair",
        alias: "Luxair",
    },
    {
        iata_code: "LH",
        icao_code: "GEC",
        name: "Lufthansa Cargo",
        alias: "",
    },
    {
        iata_code: "LI",
        icao_code: "LIA",
        name: "LIAT",
        alias: "",
    },
    {
        iata_code: "LJ",
        icao_code: "JNA",
        name: "Jin Air",
        alias: "",
    },
    {
        iata_code: "LK",
        icao_code: "LLL",
        name: "Lao Skyway",
        alias: "",
    },
    {
        iata_code: "LL",
        icao_code: "BSK",
        name: "Miami Air International",
        alias: "",
    },
    {
        iata_code: "LM",
        icao_code: "",
        name: "Loganair",
        alias: "Livingston",
    },
    {
        iata_code: "LN",
        icao_code: "LAA",
        name: "Libyan Airlines",
        alias: "",
    },
    {
        iata_code: "LO",
        icao_code: "LOT",
        name: "LOT Polish Airlines",
        alias: "",
    },
    {
        iata_code: "LP",
        icao_code: "LPE",
        name: "LATAM Perú",
        alias: "",
    },
    {
        iata_code: "LQ",
        icao_code: "MKR",
        name: "Lanmei Airlines",
        alias: "",
    },
    {
        iata_code: "LR",
        icao_code: "LRC",
        name: "Avianca Costa Rica",
        alias: "",
    },
    {
        iata_code: "LS",
        icao_code: "EXS",
        name: "Jet2.com",
        alias: "",
    },
    {
        iata_code: "LT",
        icao_code: "SNG",
        name: "Longjiang Airlines",
        alias: "",
    },
    {
        iata_code: "LU",
        icao_code: "LXP",
        name: "LAN Express",
        alias: "",
    },
    {
        iata_code: "LV",
        icao_code: "",
        name: "Level",
        alias: "",
    },
    {
        iata_code: "LW",
        icao_code: "ANV",
        name: "Pacific Wings",
        alias: "Air Nevada",
    },
    {
        iata_code: "LX",
        icao_code: "SWR",
        name: "Swiss International Air Lines",
        alias: "Swiss",
    },
    {
        iata_code: "LY",
        icao_code: "ELY",
        name: "El Al",
        alias: "El Al Israel Airlines",
    },
    {
        iata_code: "LZ",
        icao_code: "SWU",
        name: "Swiss Global Air Lines",
        alias: "",
    },
    {
        iata_code: "M0",
        icao_code: "MNG",
        name: "Aero Mongolia",
        alias: "",
    },
    {
        iata_code: "M2",
        icao_code: "",
        name: "MHS Aviation",
        alias: "",
    },
    {
        iata_code: "M3",
        icao_code: "NFA",
        name: "Air Norway",
        alias: "",
    },
    {
        iata_code: "M4",
        icao_code: "MSA",
        name: "Mistral Air",
        alias: "",
    },
    {
        iata_code: "M5",
        icao_code: "KEN",
        name: "Kenmore Air",
        alias: "",
    },
    {
        iata_code: "M6",
        icao_code: "AJT",
        name: "Amerijet International",
        alias: "",
    },
    {
        iata_code: "M8",
        icao_code: "MSJ",
        name: "SKYJET Airlines",
        alias: "",
    },
    {
        iata_code: "M9",
        icao_code: "",
        name: "Motor Sich Jsc",
        alias: "Motor Sich",
    },
    {
        iata_code: "MB",
        icao_code: "MNB",
        name: "Mng Airlines Cargo",
        alias: "",
    },
    {
        iata_code: "MC",
        icao_code: "",
        name: "Air Mobility Command",
        alias: "",
    },
    {
        iata_code: "MD",
        icao_code: "MDG",
        name: "Air Madagascar",
        alias: "",
    },
    {
        iata_code: "ME",
        icao_code: "MEA",
        name: "Middle East Airlines",
        alias: "",
    },
    {
        iata_code: "MF",
        icao_code: "CXA",
        name: "XiamenAir",
        alias: "",
    },
    {
        iata_code: "MG",
        icao_code: "MGD",
        name: "Miami Air Lease",
        alias: "",
    },
    {
        iata_code: "MH",
        icao_code: "MAS",
        name: "Malaysia Airlines",
        alias: "",
    },
    {
        iata_code: "MI",
        icao_code: "SLK",
        name: "SilkAir",
        alias: "",
    },
    {
        iata_code: "MJ",
        icao_code: "MLR",
        name: "Mihin Lanka",
        alias: "",
    },
    {
        iata_code: "MK",
        icao_code: "MAU",
        name: "Air Mauritius",
        alias: "",
    },
    {
        iata_code: "MM",
        icao_code: "APJ",
        name: "Peach Aviation",
        alias: "Medellin",
    },
    {
        iata_code: "MN",
        icao_code: "CAW",
        name: "Comair",
        alias: "",
    },
    {
        iata_code: "MO",
        icao_code: "CAV",
        name: "Calm Air International",
        alias: "",
    },
    {
        iata_code: "MP",
        icao_code: "MPH",
        name: "Martinair",
        alias: "",
    },
    {
        iata_code: "MQ",
        icao_code: "ENY",
        name: "Envoy Air",
        alias: "",
    },
    {
        iata_code: "MR",
        icao_code: "MML",
        name: "Hunnu Air",
        alias: "Mongolian Air",
    },
    {
        iata_code: "MS",
        icao_code: "MSR",
        name: "EgyptAir",
        alias: "",
    },
    {
        iata_code: "MT",
        icao_code: "TCX",
        name: "Thomas Cook Airlines",
        alias: "",
    },
    {
        iata_code: "MU",
        icao_code: "CES",
        name: "China Eastern Airlines",
        alias: "",
    },
    {
        iata_code: "MW",
        icao_code: "MKL",
        name: "Mokulele Airlines",
        alias: "",
    },
    {
        iata_code: "MY",
        icao_code: "MWG",
        name: "MASwings",
        alias: "",
    },
    {
        iata_code: "MZ",
        icao_code: "AHX",
        name: "Amakusa Airlines",
        alias: "",
    },
    {
        iata_code: "N2",
        icao_code: "",
        name: "Dagestan Airlines",
        alias: "",
    },
    {
        iata_code: "N3",
        icao_code: "",
        name: "Aerolineas Mas",
        alias: "Omskavia",
    },
    {
        iata_code: "N4",
        icao_code: "NWS",
        name: "Nord Wind",
        alias: "",
    },
    {
        iata_code: "N5",
        icao_code: "NRL",
        name: "Nolinor Aviation",
        alias: "",
    },
    {
        iata_code: "N6",
        icao_code: "",
        name: "Nomad Aviation",
        alias: "Aerocontinent",
    },
    {
        iata_code: "N7",
        icao_code: "WBA",
        name: "Nordic Regional Airlines",
        alias: "Norra",
    },
    {
        iata_code: "N8",
        icao_code: "MUA",
        name: "National Airlines",
        alias: "",
    },
    {
        iata_code: "N9",
        icao_code: "NVR",
        name: "Novair",
        alias: "",
    },
    {
        iata_code: "NA",
        icao_code: "",
        name: "Nesma Airlines",
        alias: "",
    },
    {
        iata_code: "NB",
        icao_code: "EAN",
        name: "Skypower Express Airways",
        alias: "",
    },
    {
        iata_code: "NC",
        icao_code: "JTE",
        name: "Cobham Aviation Services Australia",
        alias: "",
    },
    {
        iata_code: "ND",
        icao_code: "FMI",
        name: "FMI Air",
        alias: "",
    },
    {
        iata_code: "NE",
        icao_code: "NMA",
        name: "Nesma Airlines",
        alias: "",
    },
    {
        iata_code: "NF",
        icao_code: "AVN",
        name: "Air Vanuatu",
        alias: "",
    },
    {
        iata_code: "NH",
        icao_code: "ANA",
        name: "All Nippon Airways",
        alias: "ANA",
    },
    {
        iata_code: "NI",
        icao_code: "PGA",
        name: "Portugalia",
        alias: "Portugália–Companhia Portuguesa de Transportes Aéreos",
    },
    {
        iata_code: "NJ",
        icao_code: "",
        name: "Niger Airways",
        alias: "",
    },
    {
        iata_code: "NK",
        icao_code: "NKS",
        name: "Spirit Airlines",
        alias: "",
    },
    {
        iata_code: "NL",
        icao_code: "SAI",
        name: "Shaheen Air",
        alias: "",
    },
    {
        iata_code: "NM",
        icao_code: "",
        name: "Manx",
        alias: "",
    },
    {
        iata_code: "NN",
        icao_code: "MOV",
        name: "Vim Airlines",
        alias: "",
    },
    {
        iata_code: "NO",
        icao_code: "NOS",
        name: "Neos",
        alias: "",
    },
    {
        iata_code: "NP",
        icao_code: "NIA",
        name: "Nile Air",
        alias: "Nile Air",
    },
    {
        iata_code: "NQ",
        icao_code: "AJX",
        name: "Air Japan",
        alias: "",
    },
    {
        iata_code: "NR",
        icao_code: "MAV",
        name: "Manta Air",
        alias: "",
    },
    {
        iata_code: "NS",
        icao_code: "",
        name: "Hebei Airlines",
        alias: "Caucasus Air",
    },
    {
        iata_code: "NT",
        icao_code: "IBB",
        name: "Binter Canarias",
        alias: "",
    },
    {
        iata_code: "NU",
        icao_code: "JTA",
        name: "Japan Transocean Air",
        alias: "Japan Transoc",
    },
    {
        iata_code: "NV",
        icao_code: "IRG",
        name: "Iranian Naft Airline",
        alias: "",
    },
    {
        iata_code: "NW",
        icao_code: "",
        name: "Northwest Airlines",
        alias: "Nwest Airlines",
    },
    {
        iata_code: "NX",
        icao_code: "AMU",
        name: "Air Macau",
        alias: "",
    },
    {
        iata_code: "NY",
        icao_code: "FXI",
        name: "Air Iceland",
        alias: "",
    },
    {
        iata_code: "NZ",
        icao_code: "ANZ",
        name: "Air New Zealand",
        alias: "",
    },
    {
        iata_code: "O2",
        icao_code: "",
        name: "Jet Air",
        alias: "Jet Air",
    },
    {
        iata_code: "O4",
        icao_code: "",
        name: "Antrak Air",
        alias: "",
    },
    {
        iata_code: "O5",
        icao_code: "KMZ",
        name: "Comores Aviation International",
        alias: "",
    },
    {
        iata_code: "O7",
        icao_code: "",
        name: "Orenburg Region Airlines",
        alias: "",
    },
    {
        iata_code: "O9",
        icao_code: "NOV",
        name: "Nova Airways",
        alias: "",
    },
    {
        iata_code: "OA",
        icao_code: "OAL",
        name: "Olympic Air",
        alias: "",
    },
    {
        iata_code: "OB",
        icao_code: "BOV",
        name: "Boliviana de Aviación (BoA)",
        alias: "",
    },
    {
        iata_code: "OC",
        icao_code: "NGK",
        name: "Oriental Air Bridge",
        alias: "Omni Aviacao",
    },
    {
        iata_code: "OD",
        icao_code: "",
        name: "Malindo Airways",
        alias: "",
    },
    {
        iata_code: "OE",
        icao_code: "LDM",
        name: "Lauda",
        alias: "",
    },
    {
        iata_code: "OF",
        icao_code: "OLA",
        name: "Overland Airways",
        alias: "",
    },
    {
        iata_code: "OG",
        icao_code: "AFN",
        name: "Air Freight NZ",
        alias: "",
    },
    {
        iata_code: "OH",
        icao_code: "JIA",
        name: "PSA Airlines",
        alias: "",
    },
    {
        iata_code: "OI",
        icao_code: "HND",
        name: "Hinterland Aviation",
        alias: "Italiatour",
    },
    {
        iata_code: "OJ",
        icao_code: "FJM",
        name: "Fly Jamaica Airways",
        alias: "",
    },
    {
        iata_code: "OK",
        icao_code: "CSA",
        name: "Czech Airlines",
        alias: "",
    },
    {
        iata_code: "OL",
        icao_code: "PAO",
        name: "Polynesian Airlines",
        alias: "",
    },
    {
        iata_code: "OM",
        icao_code: "MGL",
        name: "Miat Mongolian Airlines",
        alias: "",
    },
    {
        iata_code: "ON",
        icao_code: "RON",
        name: "Nauru Airlines",
        alias: "Our Airline",
    },
    {
        iata_code: "OO",
        icao_code: "SKW",
        name: "SkyWest Airlines",
        alias: "",
    },
    {
        iata_code: "OP",
        icao_code: "PPL",
        name: "Air Pegasus",
        alias: "",
    },
    {
        iata_code: "OQ",
        icao_code: "",
        name: "Chongqing Airlines",
        alias: "",
    },
    {
        iata_code: "OR",
        icao_code: "TFL",
        name: "TUI Airlines Netherlands",
        alias: "",
    },
    {
        iata_code: "OS",
        icao_code: "AUA",
        name: "Austrian Airlines",
        alias: "",
    },
    {
        iata_code: "OU",
        icao_code: "CTN",
        name: "Croatia Airlines",
        alias: "",
    },
    {
        iata_code: "OW",
        icao_code: "SEW",
        name: "Skyward Express",
        alias: "",
    },
    {
        iata_code: "OX",
        icao_code: "OEA",
        name: "Orient Thai Airlines",
        alias: "",
    },
    {
        iata_code: "OY",
        icao_code: "ANS",
        name: "Andes Líneas Aéreas",
        alias: "",
    },
    {
        iata_code: "OZ",
        icao_code: "AAR",
        name: "Asiana Airlines",
        alias: "",
    },
    {
        iata_code: "P0",
        icao_code: "PFZ",
        name: "Proflight Commuter",
        alias: "Proflight",
    },
    {
        iata_code: "P1",
        icao_code: "",
        name: "Publiccharters Com",
        alias: "Publiccharter",
    },
    {
        iata_code: "P2",
        icao_code: "XAK",
        name: "Airkenya Express",
        alias: "",
    },
    {
        iata_code: "P3",
        icao_code: "CLU",
        name: "CargoLogicAir",
        alias: "",
    },
    {
        iata_code: "P4",
        icao_code: "",
        name: "Aerolineas Sosa",
        alias: "",
    },
    {
        iata_code: "P5",
        icao_code: "RPB",
        name: "Copa Airlines Colombia",
        alias: "AeroRepública",
    },
    {
        iata_code: "P6",
        icao_code: "PSC",
        name: "Pascan Aviation",
        alias: "",
    },
    {
        iata_code: "P8",
        icao_code: "SRN",
        name: "SprintAir",
        alias: "",
    },
    {
        iata_code: "P9",
        icao_code: "PVN",
        name: "Peruvian Airlines",
        alias: "",
    },
    {
        iata_code: "PA",
        icao_code: "ABQ",
        name: "Airblue",
        alias: "Florida Coast",
    },
    {
        iata_code: "PB",
        icao_code: "SPR",
        name: "Provincial Airlines",
        alias: "Provincial",
    },
    {
        iata_code: "PC",
        icao_code: "PGT",
        name: "Pegasus Airlines",
        alias: "",
    },
    {
        iata_code: "PD",
        icao_code: "POE",
        name: "Porter Airlines",
        alias: "",
    },
    {
        iata_code: "PE",
        icao_code: "PEV",
        name: "Peoples Viennaline",
        alias: "",
    },
    {
        iata_code: "PF",
        icao_code: "PRI",
        name: "Primera Air Scandinavia",
        alias: "",
    },
    {
        iata_code: "PG",
        icao_code: "BKP",
        name: "Bangkok Airways",
        alias: "",
    },
    {
        iata_code: "PH",
        icao_code: "",
        name: "Transavia Denmark",
        alias: "Polynesian",
    },
    {
        iata_code: "PI",
        icao_code: "RKA",
        name: "Polar Airlines",
        alias: "",
    },
    {
        iata_code: "PJ",
        icao_code: "",
        name: "Air Saint Pierre",
        alias: "",
    },
    {
        iata_code: "PK",
        icao_code: "PIA",
        name: "Pakistan International Airlines (PIA)",
        alias: "",
    },
    {
        iata_code: "PL",
        icao_code: "",
        name: "Southern Air Charter",
        alias: "",
    },
    {
        iata_code: "PM",
        icao_code: "CNF",
        name: "Canaryfly",
        alias: "",
    },
    {
        iata_code: "PN",
        icao_code: "",
        name: "China West Air",
        alias: "Pan Am",
    },
    {
        iata_code: "PO",
        icao_code: "PAC",
        name: "Polar Air Cargo",
        alias: "",
    },
    {
        iata_code: "PP",
        icao_code: "PJS",
        name: "Jet Aviation Business",
        alias: "",
    },
    {
        iata_code: "PQ",
        icao_code: "APG",
        name: "AirAsia Philippines",
        alias: "",
    },
    {
        iata_code: "PR",
        icao_code: "PAL",
        name: "Philippine Airlines",
        alias: "",
    },
    {
        iata_code: "PS",
        icao_code: "AUI",
        name: "Ukraine International Airlines",
        alias: "",
    },
    {
        iata_code: "PT",
        icao_code: "PDT",
        name: "Piedmont Airlines",
        alias: "",
    },
    {
        iata_code: "PU",
        icao_code: "",
        name: "Plus Ultra Líneas Aéreas",
        alias: "",
    },
    {
        iata_code: "PV",
        icao_code: "SBU",
        name: "Saint Barth Commuter",
        alias: "Pauknair",
    },
    {
        iata_code: "PW",
        icao_code: "PRF",
        name: "Precision Air",
        alias: "",
    },
    {
        iata_code: "PX",
        icao_code: "ANG",
        name: "Air Niugini",
        alias: "",
    },
    {
        iata_code: "PY",
        icao_code: "SLM",
        name: "Surinam Airways",
        alias: "Surinam Air",
    },
    {
        iata_code: "PZ",
        icao_code: "LAP",
        name: "LATAM Paraguay",
        alias: "",
    },
    {
        iata_code: "Q0",
        icao_code: "",
        name: "Quebecair Express",
        alias: "",
    },
    {
        iata_code: "Q1",
        icao_code: "",
        name: "Sqiva Sistem",
        alias: "",
    },
    {
        iata_code: "Q2",
        icao_code: "DQA",
        name: "Maldivian",
        alias: "",
    },
    {
        iata_code: "Q3",
        icao_code: "AAS",
        name: "Anguilla Air Services",
        alias: "",
    },
    {
        iata_code: "Q4",
        icao_code: "",
        name: "Aviation Starlink",
        alias: "Swazi Express",
    },
    {
        iata_code: "Q5",
        icao_code: "MLA",
        name: "Forty Mile Air",
        alias: "",
    },
    {
        iata_code: "Q6",
        icao_code: "SKP",
        name: "Skytrans Regional",
        alias: "Mineralnye Vo",
    },
    {
        iata_code: "Q7",
        icao_code: "SBM",
        name: "Sky Bahamas Airlines",
        alias: "",
    },
    {
        iata_code: "Q8",
        icao_code: "TSG",
        name: "Trans Air Congo",
        alias: "",
    },
    {
        iata_code: "Q9",
        icao_code: "",
        name: "Airliaison",
        alias: "Afrinat Intl",
    },
    {
        iata_code: "QA",
        icao_code: "CIM",
        name: "Cimber",
        alias: "",
    },
    {
        iata_code: "QB",
        icao_code: "IRQ",
        name: "Qeshm Air",
        alias: "Sky Georgia",
    },
    {
        iata_code: "QC",
        icao_code: "CRC",
        name: "Camair-Co",
        alias: "",
    },
    {
        iata_code: "QD",
        icao_code: "DOB",
        name: "Dobrolet Airlines",
        alias: "",
    },
    {
        iata_code: "QE",
        icao_code: "QFZ",
        name: "Fars Qeshm Airlines",
        alias: "Crossair Eur",
    },
    {
        iata_code: "QF",
        icao_code: "QFA",
        name: "Qantas",
        alias: "",
    },
    {
        iata_code: "QG",
        icao_code: "CTV",
        name: "Citilink Indonesia",
        alias: "Prima Air",
    },
    {
        iata_code: "QH",
        icao_code: "LYN",
        name: "Air Kyrgyzstan",
        alias: "",
    },
    {
        iata_code: "QJ",
        icao_code: "LTP",
        name: "Latpass Airlines",
        alias: "",
    },
    {
        iata_code: "QK",
        icao_code: "JZA",
        name: "Jazz Aviation",
        alias: "Jazz Air",
    },
    {
        iata_code: "QL",
        icao_code: "LER",
        name: "Linea Aerea De Servicio",
        alias: "",
    },
    {
        iata_code: "QM",
        icao_code: "",
        name: "Monacair",
        alias: "",
    },
    {
        iata_code: "QO",
        icao_code: "",
        name: "Quantum Air",
        alias: "",
    },
    {
        iata_code: "QP",
        icao_code: "",
        name: "Air Kenya Aviation",
        alias: "Airkenya Avn",
    },
    {
        iata_code: "QQ",
        icao_code: "FWQ",
        name: "Alliance Airlines",
        alias: "",
    },
    {
        iata_code: "QR",
        icao_code: "QTR",
        name: "Qatar Airways",
        alias: "",
    },
    {
        iata_code: "QS",
        icao_code: "TVS",
        name: "Smartwings",
        alias: "Travel Service",
    },
    {
        iata_code: "QT",
        icao_code: "TPA",
        name: "Tampa Cargo",
        alias: "Regional",
    },
    {
        iata_code: "QU",
        icao_code: "UTN",
        name: "UTair Ukraine",
        alias: "",
    },
    {
        iata_code: "QV",
        icao_code: "LAO",
        name: "Lao Airlines",
        alias: "",
    },
    {
        iata_code: "QW",
        icao_code: "",
        name: "Qingdao Airlines",
        alias: "Qw BLUE Wings",
    },
    {
        iata_code: "QX",
        icao_code: "QXE",
        name: "Horizon Air",
        alias: "",
    },
    {
        iata_code: "QY",
        icao_code: "",
        name: "European Air Transport",
        alias: "",
    },
    {
        iata_code: "QZ",
        icao_code: "AWQ",
        name: "Indonesia AirAsia",
        alias: "Awair",
    },
    {
        iata_code: "R0",
        icao_code: "",
        name: "MS Royal Airlines",
        alias: "",
    },
    {
        iata_code: "R3",
        icao_code: "SYL",
        name: "Yakutia Airlines",
        alias: "",
    },
    {
        iata_code: "R4",
        icao_code: "RLT",
        name: "Real Tonga",
        alias: "",
    },
    {
        iata_code: "R5",
        icao_code: "JAV",
        name: "Jordan Aviation",
        alias: "",
    },
    {
        iata_code: "R6",
        icao_code: "DNU",
        name: "DOT LT",
        alias: "",
    },
    {
        iata_code: "R7",
        icao_code: "OCA",
        name: "Aserca",
        alias: "Aserca",
    },
    {
        iata_code: "R8",
        icao_code: "KGA",
        name: "Kyrgyzstan Airlines",
        alias: "",
    },
    {
        iata_code: "R9",
        icao_code: "TSD",
        name: "TAF Linhas Aereas",
        alias: "Air Charter",
    },
    {
        iata_code: "RA",
        icao_code: "RNA",
        name: "Nepal Airlines",
        alias: "",
    },
    {
        iata_code: "RB",
        icao_code: "SYR",
        name: "Syrian Arab Airlines",
        alias: "",
    },
    {
        iata_code: "RC",
        icao_code: "FLI",
        name: "Atlantic Airways",
        alias: "",
    },
    {
        iata_code: "RE",
        icao_code: "STK",
        name: "Stobart Air",
        alias: "Aer Arann",
    },
    {
        iata_code: "RF",
        icao_code: "",
        name: "Ord Air Charter",
        alias: "",
    },
    {
        iata_code: "RG",
        icao_code: "RJD",
        name: "Rotana Jet",
        alias: "",
    },
    {
        iata_code: "RH",
        icao_code: "",
        name: "Robin Hood Aviation",
        alias: "",
    },
    {
        iata_code: "RI",
        icao_code: "MDL",
        name: "Air Costa Rica",
        alias: "",
    },
    {
        iata_code: "RJ",
        icao_code: "RJA",
        name: "Royal Jordanian",
        alias: "Ryljordania",
    },
    {
        iata_code: "RK",
        icao_code: "",
        name: "Region Airline",
        alias: "",
    },
    {
        iata_code: "RL",
        icao_code: "ABG",
        name: "Royal Flight",
        alias: "",
    },
    {
        iata_code: "RM",
        icao_code: "NGT",
        name: "Aircompany Armenia",
        alias: "",
    },
    {
        iata_code: "RN",
        icao_code: "RKT",
        name: "Rayani Air",
        alias: "",
    },
    {
        iata_code: "RO",
        icao_code: "ROT",
        name: "TAROM",
        alias: "",
    },
    {
        iata_code: "RP",
        icao_code: "CHQ",
        name: "Chautauqua Airlines",
        alias: "",
    },
    {
        iata_code: "RQ",
        icao_code: "KMF",
        name: "Kam Air",
        alias: "",
    },
    {
        iata_code: "RR",
        icao_code: "RFR",
        name: "Royal Air Force",
        alias: "RAF NO",
    },
    {
        iata_code: "RS",
        icao_code: "ASV",
        name: "Air Seoul",
        alias: "",
    },
    {
        iata_code: "RT",
        icao_code: "RAT",
        name: "Rainbow Airlines",
        alias: "",
    },
    {
        iata_code: "RU",
        icao_code: "SKI",
        name: "Air Bridge Cargo",
        alias: "Tci Skyking",
    },
    {
        iata_code: "RV",
        icao_code: "",
        name: "Air Canada Rouge",
        alias: "",
    },
    {
        iata_code: "RW",
        icao_code: "RYL",
        name: "Royal Air Charter Service",
        alias: "",
    },
    {
        iata_code: "RX",
        icao_code: "",
        name: "Regent Airways",
        alias: "",
    },
    {
        iata_code: "RY",
        icao_code: "CJX",
        name: "Jiangxi Air",
        alias: "",
    },
    {
        iata_code: "RZ",
        icao_code: "LRS",
        name: "SANSA Airlines",
        alias: "",
    },
    {
        iata_code: "S0",
        icao_code: "OKS",
        name: "Slok Air International",
        alias: "",
    },
    {
        iata_code: "S1",
        icao_code: "LSY",
        name: "Lufthansa Systems",
        alias: "",
    },
    {
        iata_code: "S2",
        icao_code: "RSH",
        name: "Jet Lite",
        alias: "",
    },
    {
        iata_code: "S3",
        icao_code: "BBR",
        name: "SBA Airlines",
        alias: "",
    },
    {
        iata_code: "S4",
        icao_code: "RZO",
        name: "SATA International",
        alias: "",
    },
    {
        iata_code: "S5",
        icao_code: "TCF",
        name: "Shuttle America",
        alias: "",
    },
    {
        iata_code: "S6",
        icao_code: "SNR",
        name: "Sunrise Airways",
        alias: "",
    },
    {
        iata_code: "S7",
        icao_code: "SBI",
        name: "S7 Airlines",
        alias: "",
    },
    {
        iata_code: "S8",
        icao_code: "SDA",
        name: "Sounds Air",
        alias: "",
    },
    {
        iata_code: "S9",
        icao_code: "IKM",
        name: "Starbow",
        alias: "Starbow",
    },
    {
        iata_code: "SA",
        icao_code: "SAA",
        name: "South African Airways",
        alias: "SAA",
    },
    {
        iata_code: "SB",
        icao_code: "ACI",
        name: "Aircalin",
        alias: "Cal Int",
    },
    {
        iata_code: "SC",
        icao_code: "CDG",
        name: "Shandong Airlines",
        alias: "",
    },
    {
        iata_code: "SD",
        icao_code: "SUD",
        name: "Sudan Airways",
        alias: "",
    },
    {
        iata_code: "SF",
        icao_code: "DTH",
        name: "Tassili Airlines",
        alias: "",
    },
    {
        iata_code: "SG",
        icao_code: "SEJ",
        name: "SpiceJet",
        alias: "",
    },
    {
        iata_code: "SH",
        icao_code: "",
        name: "Sharp Aviation",
        alias: "Fly Me",
    },
    {
        iata_code: "SI",
        icao_code: "BCI",
        name: "Blue Islands",
        alias: "",
    },
    {
        iata_code: "SJ",
        icao_code: "SJY",
        name: "Sriwijaya Air",
        alias: "",
    },
    {
        iata_code: "SK",
        icao_code: "SAS",
        name: "Scandinavian Airlines",
        alias: "",
    },
    {
        iata_code: "SL",
        icao_code: "TLM",
        name: "Thai Lion Air",
        alias: "Rio Sul",
    },
    {
        iata_code: "SM",
        icao_code: "MSC",
        name: "Air Cairo",
        alias: "",
    },
    {
        iata_code: "SN",
        icao_code: "BEL",
        name: "Brussels Airlines",
        alias: "",
    },
    {
        iata_code: "SO",
        icao_code: "",
        name: "Apex Airline",
        alias: "",
    },
    {
        iata_code: "SP",
        icao_code: "RZO",
        name: "SATA Air Açores",
        alias: "",
    },
    {
        iata_code: "SQ",
        icao_code: "SIA",
        name: "Singapore Airlines",
        alias: "",
    },
    {
        iata_code: "SS",
        icao_code: "CRL",
        name: "Corsair",
        alias: "",
    },
    {
        iata_code: "ST",
        icao_code: "GMI",
        name: "Germania",
        alias: "",
    },
    {
        iata_code: "SU",
        icao_code: "AFL",
        name: "Aeroflot",
        alias: "",
    },
    {
        iata_code: "SV",
        icao_code: "SVA",
        name: "Saudia",
        alias: "Saudi Arabian Airlines",
    },
    {
        iata_code: "SW",
        icao_code: "NMB",
        name: "Air Namibia",
        alias: "",
    },
    {
        iata_code: "SX",
        icao_code: "SRK",
        name: "SkyWork Airlines",
        alias: "",
    },
    {
        iata_code: "SY",
        icao_code: "SCX",
        name: "Sun Country",
        alias: "",
    },
    {
        iata_code: "SZ",
        icao_code: "SMR",
        name: "Somon Air",
        alias: "",
    },
    {
        iata_code: "T0",
        icao_code: "TPU",
        name: "Avianca Perú",
        alias: "TACA Peru",
    },
    {
        iata_code: "T1",
        icao_code: "",
        name: "Tik Systems",
        alias: "",
    },
    {
        iata_code: "T2",
        icao_code: "FLB",
        name: "Fly Art",
        alias: "",
    },
    {
        iata_code: "T3",
        icao_code: "EZE",
        name: "Eastern Airways",
        alias: "",
    },
    {
        iata_code: "T4",
        icao_code: "",
        name: "Trip",
        alias: "",
    },
    {
        iata_code: "T5",
        icao_code: "TUA",
        name: "Turkmenistan Airlines",
        alias: "",
    },
    {
        iata_code: "T6",
        icao_code: "ITI",
        name: "ITI Air",
        alias: "Island Transvoyager",
    },
    {
        iata_code: "T7",
        icao_code: "TJT",
        name: "Twin Jet",
        alias: "",
    },
    {
        iata_code: "T9",
        icao_code: "",
        name: "Aviator Aviation",
        alias: "",
    },
    {
        iata_code: "TA",
        icao_code: "TAI",
        name: "Avianca El Salvador",
        alias: "TACA Airlines",
    },
    {
        iata_code: "TB",
        icao_code: "JAF",
        name: "TUIfly Belgium",
        alias: "",
    },
    {
        iata_code: "TC",
        icao_code: "ATC",
        name: "Air Tanzania",
        alias: "",
    },
    {
        iata_code: "TD",
        icao_code: "",
        name: "Atlantis European Airways",
        alias: "Atlantis",
    },
    {
        iata_code: "TE",
        icao_code: "IGA",
        name: "Sky Taxi",
        alias: "",
    },
    {
        iata_code: "TF",
        icao_code: "BRA",
        name: "BRA",
        alias: "",
    },
    {
        iata_code: "TG",
        icao_code: "THA",
        name: "Thai Airways",
        alias: "",
    },
    {
        iata_code: "TH",
        icao_code: "RMY",
        name: "Raya Airways",
        alias: "",
    },
    {
        iata_code: "TI",
        icao_code: "TWI",
        name: "Tailwind Hava Yollari As",
        alias: "Baltic Int",
    },
    {
        iata_code: "TJ",
        icao_code: "GPD",
        name: "Tradewind Aviation",
        alias: "",
    },
    {
        iata_code: "TK",
        icao_code: "THY",
        name: "Turkish Airlines",
        alias: "",
    },
    {
        iata_code: "TL",
        icao_code: "ANO",
        name: "Airnorth",
        alias: "Capiteq Limited",
    },
    {
        iata_code: "TM",
        icao_code: "LAM",
        name: "Lam Mozambique",
        alias: "",
    },
    {
        iata_code: "TN",
        icao_code: "THT",
        name: "Air Tahiti Nui",
        alias: "",
    },
    {
        iata_code: "TO",
        icao_code: "",
        name: "Transavia France",
        alias: "President Air",
    },
    {
        iata_code: "TP",
        icao_code: "TAP",
        name: "TAP Portugal",
        alias: "",
    },
    {
        iata_code: "TQ",
        icao_code: "TDM",
        name: "Tandem Aero",
        alias: "",
    },
    {
        iata_code: "TR",
        icao_code: "TGW",
        name: "Tigerair",
        alias: "",
    },
    {
        iata_code: "TS",
        icao_code: "TSC",
        name: "Air Transat",
        alias: "",
    },
    {
        iata_code: "TT",
        icao_code: "TGW",
        name: "Tigerair Australia",
        alias: "",
    },
    {
        iata_code: "TU",
        icao_code: "TAR",
        name: "Tunisair",
        alias: "Tunis Air",
    },
    {
        iata_code: "TV",
        icao_code: "TBA",
        name: "Tibet Airlines",
        alias: "",
    },
    {
        iata_code: "TW",
        icao_code: "TWB",
        name: "T'way Air",
        alias: "",
    },
    {
        iata_code: "TX",
        icao_code: "FWI",
        name: "Air Caraibes",
        alias: "",
    },
    {
        iata_code: "TY",
        icao_code: "TPC",
        name: "Air Caledonie",
        alias: "",
    },
    {
        iata_code: "TZ",
        icao_code: "SCO",
        name: "Scoot",
        alias: "",
    },
    {
        iata_code: "U1",
        icao_code: "",
        name: "Videcom International",
        alias: "",
    },
    {
        iata_code: "U2",
        icao_code: "EZY",
        name: "easyJet",
        alias: "",
    },
    {
        iata_code: "U3",
        icao_code: "AIA",
        name: "Avies",
        alias: "",
    },
    {
        iata_code: "U4",
        icao_code: "BHA",
        name: "Buddha Air",
        alias: "",
    },
    {
        iata_code: "U5",
        icao_code: "",
        name: "Karinou Airlines",
        alias: "USA 3000",
    },
    {
        iata_code: "U6",
        icao_code: "SVR",
        name: "Ural Airlines",
        alias: "",
    },
    {
        iata_code: "U7",
        icao_code: "",
        name: "Air Uganda",
        alias: "Norcanair",
    },
    {
        iata_code: "U8",
        icao_code: "CYF",
        name: "Tus Airways",
        alias: "",
    },
    {
        iata_code: "U9",
        icao_code: "",
        name: "Tatarstan Air",
        alias: "",
    },
    {
        iata_code: "UA",
        icao_code: "UAL",
        name: "United Airlines",
        alias: "",
    },
    {
        iata_code: "UB",
        icao_code: "UBA",
        name: "Myanmar National Airlines",
        alias: "UTD Myanmar",
    },
    {
        iata_code: "UC",
        icao_code: "LCO",
        name: "LAN Cargo",
        alias: "Ladeco",
    },
    {
        iata_code: "UD",
        icao_code: "HER",
        name: "Hex Air",
        alias: "",
    },
    {
        iata_code: "UE",
        icao_code: "",
        name: "Nasair",
        alias: "",
    },
    {
        iata_code: "UF",
        icao_code: "UKM",
        name: "Um Air",
        alias: "",
    },
    {
        iata_code: "UG",
        icao_code: "",
        name: "Tunisair Express",
        alias: "Tuninter",
    },
    {
        iata_code: "UH",
        icao_code: "UJX",
        name: "AtlasJet Ukraine",
        alias: "US Helicopter",
    },
    {
        iata_code: "UI",
        icao_code: "AUK",
        name: "Auric Air Services",
        alias: "",
    },
    {
        iata_code: "UJ",
        icao_code: "LMU",
        name: "AlMasria Universal Airlines",
        alias: "",
    },
    {
        iata_code: "UK",
        icao_code: "VTI",
        name: "Vistara",
        alias: "Tata SIA Airlines",
    },
    {
        iata_code: "UL",
        icao_code: "ALK",
        name: "SriLankan Airlines",
        alias: "",
    },
    {
        iata_code: "UM",
        icao_code: "AZW",
        name: "Air Zimbabwe",
        alias: "",
    },
    {
        iata_code: "UN",
        icao_code: "TSO",
        name: "Transaero Airlines",
        alias: "",
    },
    {
        iata_code: "UO",
        icao_code: "HKE",
        name: "Hong Kong Express Airways",
        alias: "",
    },
    {
        iata_code: "UP",
        icao_code: "BHS",
        name: "Bahamasair",
        alias: "",
    },
    {
        iata_code: "UQ",
        icao_code: "",
        name: "Business Aviation Centre",
        alias: "O Connor",
    },
    {
        iata_code: "UR",
        icao_code: "UGD",
        name: "Uganda Airlines",
        alias: "",
    },
    {
        iata_code: "US",
        icao_code: "AWE",
        name: "US Airways",
        alias: "",
    },
    {
        iata_code: "UT",
        icao_code: "UTA",
        name: "UTair Aviation",
        alias: "UTair",
    },
    {
        iata_code: "UU",
        icao_code: "REU",
        name: "Air Austral",
        alias: "",
    },
    {
        iata_code: "UV",
        icao_code: "",
        name: "Helicopteros Del Sureste",
        alias: "",
    },
    {
        iata_code: "UX",
        icao_code: "AEA",
        name: "Air Europa",
        alias: "",
    },
    {
        iata_code: "UY",
        icao_code: "SPD",
        name: "Sky Prime Charter",
        alias: "",
    },
    {
        iata_code: "UZ",
        icao_code: "BRQ",
        name: "Buraq Air",
        alias: "",
    },
    {
        iata_code: "V0",
        icao_code: "VCV",
        name: "Conviasa",
        alias: "Conviasa",
    },
    {
        iata_code: "V1",
        icao_code: "",
        name: "IBS Software Services Americas",
        alias: "",
    },
    {
        iata_code: "V2",
        icao_code: "RBY",
        name: "Vision Airlines",
        alias: "",
    },
    {
        iata_code: "V3",
        icao_code: "KRP",
        name: "Carpatair",
        alias: "",
    },
    {
        iata_code: "V4",
        icao_code: "VES",
        name: "Vieques Air Link",
        alias: "",
    },
    {
        iata_code: "V5",
        icao_code: "DAP",
        name: "Aerovias DAP",
        alias: "",
    },
    {
        iata_code: "V6",
        icao_code: "",
        name: "Air Link",
        alias: "",
    },
    {
        iata_code: "V7",
        icao_code: "VOL",
        name: "Volotea",
        alias: "",
    },
    {
        iata_code: "V8",
        icao_code: "IAR",
        name: "Iliamna Air",
        alias: "Contact Air",
    },
    {
        iata_code: "V9",
        icao_code: "",
        name: "Van Air Europe",
        alias: "",
    },
    {
        iata_code: "VA",
        icao_code: "VOZ",
        name: "Virgin Australia",
        alias: "",
    },
    {
        iata_code: "VB",
        icao_code: "VIV",
        name: "Aeroenlaces Nacionales",
        alias: "",
    },
    {
        iata_code: "VC",
        icao_code: "SRY",
        name: "ViaAir",
        alias: "",
    },
    {
        iata_code: "VD",
        icao_code: "",
        name: "Hennan Airlines",
        alias: "",
    },
    {
        iata_code: "VE",
        icao_code: "EFY",
        name: "EasyFly",
        alias: "",
    },
    {
        iata_code: "VG",
        icao_code: "VLM",
        name: "VLM Airlines",
        alias: "",
    },
    {
        iata_code: "VH",
        icao_code: "VVC",
        name: "Viva Air Colombia",
        alias: "",
    },
    {
        iata_code: "VI",
        icao_code: "",
        name: "Volga Dnepr Airline",
        alias: "",
    },
    {
        iata_code: "VJ",
        icao_code: "VJC",
        name: "VietJet Air",
        alias: "",
    },
    {
        iata_code: "VK",
        icao_code: "FOO",
        name: "Anisec Luftfahrt",
        alias: "",
    },
    {
        iata_code: "VL",
        icao_code: "VIM",
        name: "Air VIA",
        alias: "",
    },
    {
        iata_code: "VN",
        icao_code: "HVN",
        name: "Vietnam Airlines",
        alias: "",
    },
    {
        iata_code: "VO",
        icao_code: "WLM",
        name: "VLM Airlines Slovenia",
        alias: "",
    },
    {
        iata_code: "VP",
        icao_code: "VQI",
        name: "Villa Air",
        alias: "",
    },
    {
        iata_code: "VQ",
        icao_code: "",
        name: "Novoair",
        alias: "",
    },
    {
        iata_code: "VR",
        icao_code: "TCV",
        name: "Tacv Cabo Verde Airlines",
        alias: "TACV",
    },
    {
        iata_code: "VS",
        icao_code: "VIR",
        name: "Virgin Atlantic",
        alias: "",
    },
    {
        iata_code: "VT",
        icao_code: "VTA",
        name: "Air Tahiti",
        alias: "",
    },
    {
        iata_code: "VU",
        icao_code: "VAR",
        name: "Vuelos Economicos",
        alias: "",
    },
    {
        iata_code: "VV",
        icao_code: "VPE",
        name: "Viva Airlines Peru",
        alias: "",
    },
    {
        iata_code: "VW",
        icao_code: "TAO",
        name: "Aeromar",
        alias: "",
    },
    {
        iata_code: "VX",
        icao_code: "VRD",
        name: "Virgin America",
        alias: "",
    },
    {
        iata_code: "VY",
        icao_code: "VLG",
        name: "Vueling",
        alias: "",
    },
    {
        iata_code: "VZ",
        icao_code: "TVJ",
        name: "Thai Vietjet Air",
        alias: "",
    },
    {
        iata_code: "W1",
        icao_code: "",
        name: "WorldTicket",
        alias: "",
    },
    {
        iata_code: "W2",
        icao_code: "FXT",
        name: "Flexflight",
        alias: "Flexflight",
    },
    {
        iata_code: "W3",
        icao_code: "ARA",
        name: "Arik Air",
        alias: "",
    },
    {
        iata_code: "W5",
        icao_code: "IRM",
        name: "Mahan Airlines",
        alias: "",
    },
    {
        iata_code: "W6",
        icao_code: "WZZ",
        name: "Wizz Air",
        alias: "",
    },
    {
        iata_code: "W7",
        icao_code: "SAH",
        name: "Sayakhat Airlines",
        alias: "Sayakhat Air",
    },
    {
        iata_code: "W8",
        icao_code: "WND",
        name: "Caribbean Winds Airlines",
        alias: "La Costena",
    },
    {
        iata_code: "W9",
        icao_code: "",
        name: "Air Bagan",
        alias: "",
    },
    {
        iata_code: "WA",
        icao_code: "KLC",
        name: "KLM Cityhopper",
        alias: "",
    },
    {
        iata_code: "WB",
        icao_code: "RWD",
        name: "Rwandair",
        alias: "",
    },
    {
        iata_code: "WC",
        icao_code: "ISV",
        name: "Islena Airlines",
        alias: "Islena Air",
    },
    {
        iata_code: "WD",
        icao_code: "AAN",
        name: "Amsterdam Airlines Bv",
        alias: "Das Air",
    },
    {
        iata_code: "WE",
        icao_code: "THD",
        name: "Thai Smile Airways",
        alias: "Rheintalflg",
    },
    {
        iata_code: "WF",
        icao_code: "WIF",
        name: "Widerøe",
        alias: "Wideroe",
    },
    {
        iata_code: "WG",
        icao_code: "SWG",
        name: "Sunwing Airlines",
        alias: "Sunwing Airlines",
    },
    {
        iata_code: "WH",
        icao_code: "",
        name: "West African Airlines",
        alias: "",
    },
    {
        iata_code: "WI",
        icao_code: "WHT",
        name: "White Airways",
        alias: "",
    },
    {
        iata_code: "WJ",
        icao_code: "LAL",
        name: "Air Labrador",
        alias: "Labrador Airways",
    },
    {
        iata_code: "WK",
        icao_code: "EDW",
        name: "Edelweiss Air",
        alias: "",
    },
    {
        iata_code: "WL",
        icao_code: "ECB",
        name: "European Coastal Airlines",
        alias: "",
    },
    {
        iata_code: "WM",
        icao_code: "WIA",
        name: "Windward Island Airways",
        alias: "",
    },
    {
        iata_code: "WN",
        icao_code: "SWA",
        name: "Southwest Airlines",
        alias: "",
    },
    {
        iata_code: "WO",
        icao_code: "WOA",
        name: "World Airways",
        alias: "",
    },
    {
        iata_code: "WP",
        icao_code: "MKU",
        name: "Island Air",
        alias: "",
    },
    {
        iata_code: "WQ",
        icao_code: "",
        name: "Romavia",
        alias: "",
    },
    {
        iata_code: "WR",
        icao_code: "WEN",
        name: "WestJet Encore",
        alias: "",
    },
    {
        iata_code: "WS",
        icao_code: "WJA",
        name: "WestJet",
        alias: "",
    },
    {
        iata_code: "WT",
        icao_code: "SWT",
        name: "Swiftair",
        alias: "",
    },
    {
        iata_code: "WV",
        icao_code: "RVP",
        name: "Aero VIP",
        alias: "",
    },
    {
        iata_code: "WW",
        icao_code: "",
        name: "Wow Air",
        alias: "",
    },
    {
        iata_code: "WX",
        icao_code: "BCY",
        name: "CityJet",
        alias: "",
    },
    {
        iata_code: "WY",
        icao_code: "OAS",
        name: "Oman Air",
        alias: "",
    },
    {
        iata_code: "WZ",
        icao_code: "RWZ",
        name: "Red Wings Airlines",
        alias: "",
    },
    {
        iata_code: "X3",
        icao_code: "TUI",
        name: "TUIfly",
        alias: "",
    },
    {
        iata_code: "X4",
        icao_code: "",
        name: "Air Excursions",
        alias: "",
    },
    {
        iata_code: "X6",
        icao_code: "ATA",
        name: "Airlines Reporting Corp",
        alias: "ARC",
    },
    {
        iata_code: "X7",
        icao_code: "",
        name: "Exec Air",
        alias: "Air Service",
    },
    {
        iata_code: "X9",
        icao_code: "NVD",
        name: "Avion Express",
        alias: "City Star Air",
    },
    {
        iata_code: "XB",
        icao_code: "",
        name: "IATA",
        alias: "",
    },
    {
        iata_code: "XC",
        icao_code: "CAI",
        name: "Corendon Airlines",
        alias: "",
    },
    {
        iata_code: "XD",
        icao_code: "",
        name: "Scheduled Airlines Traffic Offices",
        alias: "",
    },
    {
        iata_code: "XE",
        icao_code: "JSX",
        name: "Delux Public Charter",
        alias: "",
    },
    {
        iata_code: "XF",
        icao_code: "VLK",
        name: "Vladivostok Air",
        alias: "",
    },
    {
        iata_code: "XG",
        icao_code: "",
        name: "Sun Express Germany",
        alias: "",
    },
    {
        iata_code: "XH",
        icao_code: "",
        name: "Non Iata Codeshare Carrier",
        alias: "",
    },
    {
        iata_code: "XJ",
        icao_code: "TAX",
        name: "Thai AirAsia X",
        alias: "Mesaba Air",
    },
    {
        iata_code: "XK",
        icao_code: "CCM",
        name: "Air Corsica",
        alias: "",
    },
    {
        iata_code: "XL",
        icao_code: "LNE",
        name: "LATAM Ecuador",
        alias: "",
    },
    {
        iata_code: "XM",
        icao_code: "IMX",
        name: "Zimex Aviation",
        alias: "",
    },
    {
        iata_code: "XN",
        icao_code: "XAR",
        name: "XpressAir",
        alias: "",
    },
    {
        iata_code: "XO",
        icao_code: "",
        name: "Seair",
        alias: "Xinjiang",
    },
    {
        iata_code: "XP",
        icao_code: "CXP",
        name: "Xtra Airways",
        alias: "",
    },
    {
        iata_code: "XQ",
        icao_code: "SXS",
        name: "SunExpress",
        alias: "",
    },
    {
        iata_code: "XS",
        icao_code: "SIT",
        name: "SITA",
        alias: "",
    },
    {
        iata_code: "XT",
        icao_code: "INX",
        name: "Indonesia AirAsia X",
        alias: "",
    },
    {
        iata_code: "XU",
        icao_code: "",
        name: "African Express Airways",
        alias: "Link Airways",
    },
    {
        iata_code: "XW",
        icao_code: "NCT",
        name: "NokScoot",
        alias: "NokScoot Airlines Company Limited",
    },
    {
        iata_code: "XY",
        icao_code: "KNE",
        name: "Flynas",
        alias: "Nas Air",
    },
    {
        iata_code: "XZ",
        icao_code: "EXY",
        name: "South African Express",
        alias: "SA Express",
    },
    {
        iata_code: "Y0",
        icao_code: "",
        name: "Yellow Airtaxi",
        alias: "",
    },
    {
        iata_code: "Y1",
        icao_code: "",
        name: "Travel Technology Interactive",
        alias: "",
    },
    {
        iata_code: "Y2",
        icao_code: "RGN",
        name: "Ygnus Air",
        alias: "",
    },
    {
        iata_code: "Y3",
        icao_code: "VOS",
        name: "Gryphon Airlines",
        alias: "",
    },
    {
        iata_code: "Y4",
        icao_code: "VOI",
        name: "Volaris",
        alias: "Concesionaria Vuela Compañía de Aviación",
    },
    {
        iata_code: "Y5",
        icao_code: "",
        name: "Golden Myanmar Airlines",
        alias: "",
    },
    {
        iata_code: "Y6",
        icao_code: "AYD",
        name: "AB Aviation",
        alias: "",
    },
    {
        iata_code: "Y7",
        icao_code: "TYA",
        name: "NordStar",
        alias: "",
    },
    {
        iata_code: "Y8",
        icao_code: "YZR",
        name: "Yangtze River Express",
        alias: "",
    },
    {
        iata_code: "Y9",
        icao_code: "IRK",
        name: "Kish Airlines",
        alias: "Trans Air",
    },
    {
        iata_code: "YA",
        icao_code: "",
        name: "Nego Airline One",
        alias: "",
    },
    {
        iata_code: "YC",
        icao_code: "LLM",
        name: "Yamal Airlines",
        alias: "",
    },
    {
        iata_code: "YD",
        icao_code: "",
        name: "Mauritania Airways",
        alias: "",
    },
    {
        iata_code: "YE",
        icao_code: "",
        name: "Yan Air",
        alias: "",
    },
    {
        iata_code: "YG",
        icao_code: "OTL",
        name: "South Airlines",
        alias: "",
    },
    {
        iata_code: "YH",
        icao_code: "AYG",
        name: "Yangon Airways",
        alias: "",
    },
    {
        iata_code: "YI",
        icao_code: "",
        name: "Yunnan Yingan Airline",
        alias: "",
    },
    {
        iata_code: "YJ",
        icao_code: "AWM",
        name: "Asian Wings Airways",
        alias: "",
    },
    {
        iata_code: "YK",
        icao_code: "",
        name: "Avia Traffic Company",
        alias: "Avia Traffic",
    },
    {
        iata_code: "YL",
        icao_code: "LWA",
        name: "Libyan Wings",
        alias: "",
    },
    {
        iata_code: "YM",
        icao_code: "MGX",
        name: "Montenegro Airlines",
        alias: "",
    },
    {
        iata_code: "YN",
        icao_code: "CRQ",
        name: "Air Creebec",
        alias: "Air Creebec",
    },
    {
        iata_code: "YO",
        icao_code: "MCM",
        name: "Heli Air Monaco",
        alias: "",
    },
    {
        iata_code: "YP",
        icao_code: "PAG",
        name: "Perimeter Aviation",
        alias: "",
    },
    {
        iata_code: "YQ",
        icao_code: "LCT",
        name: "TAR Aerolíneas",
        alias: "TAR Aerolineas",
    },
    {
        iata_code: "YR",
        icao_code: "EGJ",
        name: "Scenic Airlines",
        alias: "",
    },
    {
        iata_code: "YS",
        icao_code: "RAE",
        name: "Regional",
        alias: "Régional Compagnie Aérienne Européenne",
    },
    {
        iata_code: "YU",
        icao_code: "MMZ",
        name: "Euroatlantic Airways",
        alias: "",
    },
    {
        iata_code: "YV",
        icao_code: "ASH",
        name: "Mesa Airlines",
        alias: "",
    },
    {
        iata_code: "YW",
        icao_code: "ANE",
        name: "Air Nostrum",
        alias: "",
    },
    {
        iata_code: "YX",
        icao_code: "MEP",
        name: "Republic Airline",
        alias: "",
    },
    {
        iata_code: "YZ",
        icao_code: "ALY",
        name: "Alas Uruguay",
        alias: "",
    },
    {
        iata_code: "Z2",
        icao_code: "EZD",
        name: "AirAsia Zest",
        alias: "",
    },
    {
        iata_code: "Z3",
        icao_code: "",
        name: "PM Air",
        alias: "",
    },
    {
        iata_code: "Z4",
        icao_code: "GZQ",
        name: "Zagrosjet",
        alias: "",
    },
    {
        iata_code: "Z5",
        icao_code: "GMG",
        name: "GMG Airlines",
        alias: "",
    },
    {
        iata_code: "Z6",
        icao_code: "UDN",
        name: "Dniproavia",
        alias: "",
    },
    {
        iata_code: "Z7",
        icao_code: "AUZ",
        name: "Amaszonas Uruguay",
        alias: "",
    },
    {
        iata_code: "Z8",
        icao_code: "AZN",
        name: "Línea Aérea Amaszonas",
        alias: "",
    },
    {
        iata_code: "Z9",
        icao_code: "BEK",
        name: "Jsc Bek Air",
        alias: "",
    },
    {
        iata_code: "ZA",
        icao_code: "SWM",
        name: "Sky Angkor Airlines",
        alias: "Skywings Asia Airlines",
    },
    {
        iata_code: "ZB",
        icao_code: "MON",
        name: "Monarch Airlines",
        alias: "",
    },
    {
        iata_code: "ZD",
        icao_code: "EWR",
        name: "Ewa Air",
        alias: "Flying Dandy",
    },
    {
        iata_code: "ZE",
        icao_code: "ESR",
        name: "Eastar Jet",
        alias: "",
    },
    {
        iata_code: "ZF",
        icao_code: "KTK",
        name: "Azur Air",
        alias: "",
    },
    {
        iata_code: "ZG",
        icao_code: "GOZ",
        name: "Groznyy Avia",
        alias: "",
    },
    {
        iata_code: "ZH",
        icao_code: "CSZ",
        name: "Shenzhen Airlines",
        alias: "",
    },
    {
        iata_code: "ZI",
        icao_code: "AAF",
        name: "Aigle Azur",
        alias: "",
    },
    {
        iata_code: "ZJ",
        icao_code: "ZMA",
        name: "Zambezi Airlines",
        alias: "",
    },
    {
        iata_code: "ZK",
        icao_code: "GLA",
        name: "Great Lakes Airlines",
        alias: "",
    },
    {
        iata_code: "ZL",
        icao_code: "RXA",
        name: "Regional Express",
        alias: "",
    },
    {
        iata_code: "ZM",
        icao_code: "MBB",
        name: "Pegasus Asia",
        alias: "Air Manas",
    },
    {
        iata_code: "ZN",
        icao_code: "NAY",
        name: "Naysa",
        alias: "Air Bourbon",
    },
    {
        iata_code: "ZP",
        icao_code: "AZP",
        name: "Paranair",
        alias: "",
    },
    {
        iata_code: "ZR",
        icao_code: "",
        name: "Punto Azul",
        alias: "",
    },
    {
        iata_code: "ZS",
        icao_code: "",
        name: "Kazaviaspas",
        alias: "",
    },
    {
        iata_code: "ZT",
        icao_code: "",
        name: "Titan Airways",
        alias: "",
    },
    {
        iata_code: "ZU",
        icao_code: "",
        name: "Sunair",
        alias: "",
    },
    {
        iata_code: "ZV",
        icao_code: "VAX",
        name: "V Air",
        alias: "",
    },
    {
        iata_code: "ZW",
        icao_code: "AWI",
        name: "Air Wisconsin",
        alias: "",
    },
    {
        iata_code: "ZX",
        icao_code: "GGN",
        name: "Air Georgian",
        alias: "Air Alliance",
    },
    {
        iata_code: "ZY",
        icao_code: "",
        name: "China Air Cargo",
        alias: "",
    },
    {
        iata_code: "ZZ",
        icao_code: "",
        name: "Airline Service",
        alias: "",
    },
];
const mapIataCode = (airlineCode) => { var _a; return ((_a = data.find((item) => item.iata_code === airlineCode)) === null || _a === void 0 ? void 0 : _a.name) || airlineCode; };
exports.mapIataCode = mapIataCode;
exports.default = data;
