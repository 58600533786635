import React, { useCallback, useEffect, useRef, useState } from "react";
import { LocationMap as FigmaLocationMap } from "./LocationMap.figma";
import * as d3 from "d3";
import { useSize } from "../../hooks/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { infrastructureTemplates } from "shared/Infrastructure";

const LocationMap = () => {
  const [node, setNode] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const infrastructureId = searchParams.get("infrastructureId");
  const ref = useCallback((node) => {
    if (node) setNode(node);
  }, []);
  const { width, height } = useSize(node);
  const infrastructures = infrastructureTemplates;
  const navigate = useNavigate();

  useEffect(() => {
    d3.select(node).style("height", "100%").select(".vector").style("height", "100%");
    width &&
      height &&
      d3
        .select(node)
        .select("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("width", width)
        .attr("height", height - 20);
  }, [width, height, node]);
  useEffect(() => {
    infrastructures.forEach(({ name, id }) => {
      const isCurrent = infrastructureId === id;
      d3.select(node)
        .select("svg")
        .selectAll("#" + name.replaceAll(" ", "_").replaceAll("&", "AND"))
        .style("cursor", "pointer")
        .style("filter", !isCurrent ? "grayscale(100%)" : "grayscale(50%)")
        .on("click", () => {
          navigate(`/simulations${id ? `?infrastructureId=${id}` : ""}`, { replace: true });
        })
        .on("mouseover", (d) => {
          d3.select("#" + d.srcElement.id).style("filter", "grayscale(0%)");
        })
        .on("mouseout", (d) => {
          d3.select("#" + d.srcElement.id).style("filter", !isCurrent ? "grayscale(100%)" : "grayscale(50%)");
        });
    });
  }, [infrastructures, node, navigate]);
  return <FigmaLocationMap ref={ref} />;
};

export default LocationMap;
