import { CheckCircleTwoTone, UploadOutlined, BulbOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Upload, Typography, Tooltip } from "antd";
import { RcFile } from "antd/es/upload";
import React from "react";

const renderFileName = (value) => value?.split("/").pop();

type FileUploadProps = {
  value?: string;
  onChange?: (value: string) => void;
  onUpload: (file: RcFile) => Promise<string>;
  accept?: string;
  disabled?: boolean;
  renderFileName?: (value?: string) => React.ReactNode;
};
const FileUpload: React.FC<FileUploadProps> = ({ value, onChange, onUpload, accept, disabled }) => {
  const [loading, setLoading] = React.useState(false);

  async function customRequest({ file }: { file: RcFile }) {
    setLoading(true);
    try {
      const url = await onUpload(file as RcFile);
      onChange?.(url);
    } catch (e) {
      Modal.error({
        title: "Failed to upload file",
        content: (e as Error).message,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Space>
      <Upload
        customRequest={({ file }) => {
          if (file instanceof File) customRequest({ file: file as RcFile });
        }}
        accept={accept}
        disabled={disabled}
        showUploadList={false}
      >
        <Space>
          <Button icon={<UploadOutlined />} loading={loading} disabled={disabled}>
            Click to Upload
          </Button>
          {value && (
            <span>
              <CheckCircleTwoTone twoToneColor="#52c41a" /> {renderFileName(value)}
            </span>
          )}
        </Space>
      </Upload>
      <Tooltip
        color={"#fff"}
        trigger={["click"]}
        title={
          <>
            <Typography.Text type="secondary" style={{ marginBottom: "10px", marginRight: "25px" }}>
              Upload the allocation file in csv format. Make sure the csv file contains the required columns and use comma (,) as field separator.
              Check the data structure for more details.
            </Typography.Text>
            <a
              href={require("../../assets/strategic-checkin-templates/allocation_file_example.csv")}
              download="allocation_file_example.csv"
              onClick={(e) => {
                console.log(e);
                e.stopPropagation();
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ whiteSpace: "nowrap", fontSize: "0.8rem", textAlign: "center" }}>Download allocation file template (.csv)</div>
              </div>
            </a>
          </>
        }
      >
        <Button type="link" icon={<BulbOutlined />} />
      </Tooltip>
    </Space>
  );
};

export default FileUpload;
