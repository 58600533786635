import Poisson from "poisson-disk-sampling";
import _ from "lodash";
import seedrandom from "seedrandom";
import { falsyFilter } from "shared/utils";
import { rect } from "@observablehq/plot";

type Options = {
  rectFillingMode?: "top" | "bottom" | "left" | "right" | "center" | "random";
  lineFillingMode?: "start" | "end" | "random";
  invert?: boolean;
};
const DEFAULT_OPTIONS = {
  rectFillingMode: "random",
  lineFillingMode: "start",
  invert: false,
};

/**
 * based on a SVG path, returns the position of the pax at the given index
 * return null if the index is out of the path
 */
export function getPaxPosition(
  elt: SVGPathElement | SVGRectElement,
  socialDistancing: { paxDiameter: number; distance: number },
  i: number,
  options?: Options
): { x: number; y: number } | null {
  if (elt instanceof SVGPathElement) {
    return getPaxPositionForPath(elt, socialDistancing, i, options);
  } else if (elt instanceof SVGRectElement) {
    return getPaxPositionForRect(elt, socialDistancing, i, options);
  }
  console.error(elt);
  throw new Error(`Unsupported SVG element ${elt?.["tagName"] || ""}`);
}

function getPaxPositionForPath(
  elt: SVGPathElement,
  socialDistancing: { paxDiameter: number; distance: number },
  i: number,
  options?: Options
): { x: number; y: number } | null {
  const id = elt.getAttribute("id")!;
  const pathLength = elt.getTotalLength();
  const options_ = { ...DEFAULT_OPTIONS, ...options };
  if (i < 0 || i > pathLength) {
    return null;
  }
  const rnd = seedrandom(`this_is_random_seed_${id}`, {
    global: true,
  });
  const queueCapacity = getQueueCapacityForPath(elt, socialDistancing, options);

  if (options_.lineFillingMode === "random") {
    const __ = _.runInContext();
    const randomPoints = __.shuffle(
      Array(queueCapacity)
        .fill(0)
        .map((_, i) => i)
    );
    const randomPoint = elt.getPointAtLength((randomPoints[i] ?? 0) * (socialDistancing.paxDiameter + socialDistancing.distance));
    return { x: randomPoint.x, y: randomPoint.y };
  }

  if (options_.lineFillingMode === "end") {
    const nextPoint = elt.getPointAtLength((queueCapacity - i) * (socialDistancing.paxDiameter + socialDistancing.distance));
    return { x: nextPoint.x, y: nextPoint.y };
  }
  const nextPoint = elt.getPointAtLength(i * (socialDistancing.paxDiameter + socialDistancing.distance));
  return { x: nextPoint.x, y: nextPoint.y };
}

let __cache_rect_poisson: WeakMap<SVGRectElement, [number, number][]>;
let __cache_last_social_distancing: unknown;
function getPaxPositionForRect(
  elt: SVGRectElement,
  socialDistancing: { paxDiameter: number; distance: number },
  i: number,
  options?: Options
): { x: number; y: number } | null {
  const elemWidth = +elt.getAttribute("width")!;
  const elemHeight = +elt.getAttribute("height")!;
  const x = +elt.getAttribute("x")!;
  const y = +elt.getAttribute("y")!;
  const id = elt.getAttribute("id")!;
  const padding = socialDistancing.paxDiameter / 2; //socialDistancing.distance + socialDistancing.paxDiameter / 2;
  const options_ = { ...DEFAULT_OPTIONS, ...options };

  if (!_.isEqual(__cache_last_social_distancing, socialDistancing)) {
    __cache_rect_poisson = new WeakMap();
    __cache_last_social_distancing = socialDistancing;
  }

  const rng = seedrandom(`this_is_random_seed_${id}`, {
    global: true,
  });
  let rectPos = __cache_rect_poisson.has(elt)
    ? __cache_rect_poisson.get(elt)!
    : (new Poisson({
        rng,
        shape: [elemWidth - padding * 2, elemHeight - padding * 2],
        minDistance: socialDistancing.paxDiameter / 2 + socialDistancing.distance,
        tries: 100,
      }).fill() as [number, number][]);
  if (!__cache_rect_poisson.has(elt)) __cache_rect_poisson.set(elt, rectPos);

  rectPos = rectPos.map((d: [number, number]) => [padding + d[0], padding + d[1]] as [number, number]);
  rectPos = rectPos.map((d: [number, number]) => [d[0] * (options_.invert ? -1 : 1), d[1] * (options_.invert ? -1 : 1)] as [number, number]);
  rectPos = rectPos.map((d: [number, number]) => [x + d[0], y + d[1]] as [number, number]);
  rectPos =
    options_.rectFillingMode === "center"
      ? rectPos
      : options_.rectFillingMode === "random"
      ? _.shuffle(rectPos)
      : _(rectPos)
          .orderBy(
            (d) => d[["top", "bottom"].includes(options_.rectFillingMode) ? 1 : 0],
            ["top", "left"].includes(options_.rectFillingMode) ? "asc" : "desc"
          )
          .value();

  if (i >= rectPos.length) {
    return null;
  }
  return {
    x: rectPos[i][0],
    y: rectPos[i][1],
  };
}

/**
 * Returns the max capacity of a queue based on the SVG element
 */
export function getQueueCapacity(
  elt: SVGPathElement | SVGRectElement,
  socialDistancing: { paxDiameter: number; distance: number },
  options?: Options
): number {
  if (elt instanceof SVGPathElement) {
    return getQueueCapacityForPath(elt, socialDistancing, options);
  } else if (elt instanceof SVGRectElement) {
    return getQueueCapacityForRect(elt, socialDistancing, options);
  }
  console.error(elt);
  throw new Error(`Unsupported SVG element ${elt?.["tagName"] || ""}`);
}

function getQueueCapacityForPath(elt: SVGPathElement, socialDistancing: { paxDiameter: number; distance: number }, options?: Options): number {
  const pathLength = elt.getTotalLength();
  return Math.floor(pathLength / (socialDistancing.paxDiameter + socialDistancing.distance));
}

function getQueueCapacityForRect(elt: SVGRectElement, socialDistancing: { paxDiameter: number; distance: number }, options?: Options): number {
  const elemWidth = +elt.getAttribute("width")!;
  const elemHeight = +elt.getAttribute("height")!;
  const id = elt.getAttribute("id")!;
  const padding = socialDistancing.paxDiameter / 2; //socialDistancing.distance + socialDistancing.paxDiameter / 2;

  if (!_.isEqual(__cache_last_social_distancing, socialDistancing)) {
    __cache_rect_poisson = new WeakMap();
    __cache_last_social_distancing = socialDistancing;
  }

  if (__cache_rect_poisson.has(elt)) {
    return __cache_rect_poisson.get(elt)!.length;
  }

  const rng = seedrandom(`this_is_random_seed_${id}`, {
    global: true,
  });
  const rectPos = __cache_rect_poisson.has(elt)
    ? __cache_rect_poisson.get(elt)!
    : (new Poisson({
        rng,
        shape: [elemWidth - padding * 2, elemHeight - padding * 2],
        minDistance: socialDistancing.paxDiameter / 2 + socialDistancing.distance,
        tries: 100,
      }).fill() as [number, number][]);
  if (!__cache_rect_poisson.has(elt)) __cache_rect_poisson.set(elt, rectPos);
  return rectPos.length;
}
