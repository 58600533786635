import { Slider } from "antd";
import { useContext, useState, createContext } from "react";

export const SocialDistancingSelectionContext = createContext([{ distance: 0.5, diameter: 0.5 }]);

const SocialDistancingSelection = () => {
  const [context, setContext] = useContext(SocialDistancingSelectionContext);
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center", padding: 10 }}>
        <div style={{ textAlign: "center" }}>Distance between pax</div>
        <Slider
          style={{ width: "100%" }}
          value={context.distance}
          onChange={(val) => {
            setContext({ ...context, distance: val });
          }}
          min={0.1}
          max={3}
          step={0.1}
          tooltip={{ formatter: (val) => `${val} m` }}
          marks={{
            0.1: "0.1m",
            1: "1m",
            2: "2m",
            3: "3m",
          }}
        />
      </div>
      <div style={{ width: 20 }} />
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center", padding: 10 }}>
        <div style={{ textAlign: "center" }}>Pax diameter</div>
        <Slider
          style={{ width: "100%" }}
          value={context.diameter}
          onChange={(val) => {
            setContext({ ...context, diameter: val });
          }}
          min={0.3}
          max={1}
          step={0.1}
          tooltip={{ formatter: (val) => `${val} m` }}
          marks={{
            0.3: "0.3m",
            0.5: "0.5m",
            0.7: "0.7m",
            1: "1m",
          }}
        />
      </div>
    </div>
  );
};

const Provider = ({ children }) => {
  const [state, setState] = useState({ distance: 0.4, diameter: 0.8 });
  return <SocialDistancingSelectionContext.Provider value={[state, setState]}>{children}</SocialDistancingSelectionContext.Provider>;
};

SocialDistancingSelection.Provider = Provider;

export default SocialDistancingSelection;
