import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

type User = {
  email: string;
  attributes: {
    email_verified: boolean;
    sub: string;
    "cognito:groups": string[];
    featureFlagAccess: boolean;
    DepartureSimulation: boolean;
  };
};

export const useUser = (): Partial<User> => {
  const [user, setUser] = useState();
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups?.indexOf("developers") > -1) {
        user.attributes.featureFlagAccess = true;
      } else {
        user.attributes.featureFlagAccess = false;
      }
      if (groups && groups.includes("DepartureSimulation")) {
        user.attributes.DepartureSimulation = true;
      } else {
        user.attributes.DepartureSimulation = false;
      }
      setUser(user.attributes);
    });
  }, []);
  return user || {};
};
