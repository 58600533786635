import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Layout } from "antd";
import jetpackFullLogo from "./assets/jetpack-logo-full-white.png";
import BAC_logo from "./assets/bac-logo.svg";
import BAC_logo_full from "./assets/bac-logo-full.svg";
import NavigationMenu from "./components/NavigationMenu/NavigationMenu";
import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import TstWarning from "./components/UserMenu/TstWarning";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { useUser } from "./services/auth";
import logrocket from "logrocket";

const { Content, Sider } = Layout;

const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => setCollapsed(!collapsed);

  const { email: username } = useUser();
  useEffect(() => {
    if (username) {
      logrocket.identify(username);
    }
  }, [username]);

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Layout style={{ height: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          width={250}
          onCollapse={toggleCollapse}
          trigger={null}
          style={{
            zIndex: 5,
            position: "relative",
            color: "white",
            backgroundColor: "#17405c",
          }}
        >
          <Link to="/">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: 70,
              }}
            >
              <img
                src={collapsed ? BAC_logo : BAC_logo_full}
                alt="baclogo"
                style={{
                  maxWidth: "100%",
                  maxHeight: 70,
                  padding: 10,
                  marginBottom: 24,
                }}
              />
            </div>
          </Link>
          <NavigationMenu />
          <div style={{ position: "absolute", bottom: "0", padding: 10 }}>
            <a href="https://jetpack.ai" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
              <div style={{ color: "#fff", fontSize: collapsed ? 11 : 16, textAlign: collapsed ? "center" : undefined }}>Made with ♥ by</div>
              <img
                src={jetpackFullLogo}
                alt="jetpackfulllogo"
                style={{
                  width: "100%",
                }}
              />
            </a>
          </div>
        </Sider>
        <Layout>
          <TstWarning />
          <HeaderMenu toggleCollapse={toggleCollapse} collapsed={collapsed} />
          <Content
            style={{
              height: "calc(100vh - 64px)",
              overflowY: "scroll",
              position: "relative",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </QueryParamProvider>
  );
};

export default AppLayout;
