"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STRATEGIC_INFRASTRUCTURE = void 0;
const misc_1 = require("./misc");
exports.STRATEGIC_INFRASTRUCTURE = {
    id: "check-in-strategic",
    name: "Check-in strategic layout",
    category: misc_1.InfrastructureCategories.Planification,
    airport_component: "DepartureSBD",
};
