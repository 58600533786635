import { useQuery } from "react-query";
import getAPI from "../services/api";

const useAllocations = (key) => {
  return useQuery(
    ["allocations", key],
    async () => {
      const api = await getAPI();
      const { data } = await api.get(`/strategic-allocations?key=${key}`);
      return data;
    },
    {
      enabled: !!key,
    }
  );
};

export default useAllocations;
