import { Button, Col, Collapse, Form, Input, InputNumber, Modal, notification, Row, Select, Space, Spin, Tag } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

import { NEW, SimulationStrategic } from "shared/Simulation";
import { useFlightSchedule } from "../../../hooks/useFlightSchedule";
import dayjs from "dayjs";
import FileUpload from "../../UI/FileUpload";
import SBDQueueChart from "../SBDQueueChart";
import WithWidth from "../../UI/WithWidth";
import { EMPTY_LAYOUT, StrategicLayout } from "shared/StrategicLayout";
import LayoutBuilder from "./LayoutBuilder";
import getAPI from "../../../services/api";
import axios from "axios";
import AllocationRendering from "./AllocationRendering";
import { EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import FlightScheduleRendering from "./FlightScheduleRendering";
import useAllocations from "../../../hooks/useAllocations";
import numeral from "numeral";
import { mapIataCode } from "shared/constants/iata_airlines";
import AirlineHandlingTimes from "./AirlineHandlingTimes";

interface Props {
  initialValues: SimulationStrategic;
  simulations: Array<{ name: string }>;
  onClose: () => void;
}

const ParameterForm: React.FC<Props> = ({ initialValues, simulations, onClose }) => {
  const [form] = Form.useForm<SimulationStrategic>();
  const assistanceSelfCheckinRatio =
    Form.useWatch(["parameters", "assistance_self_checkin_ratio"], form) || initialValues.parameters.assistance_self_checkin_ratio;
  const assistanceBagTagRatio = Form.useWatch(["parameters", "assistance_bag_tag_ratio"], form) || initialValues.parameters.assistance_bag_tag_ratio;
  const assistanceBagDropRatio =
    Form.useWatch(["parameters", "assistance_bag_drop_ratio"], form) || initialValues.parameters.assistance_bag_drop_ratio;

  let totalAssistanceRatio = assistanceSelfCheckinRatio;
  totalAssistanceRatio += assistanceBagTagRatio * (1 - totalAssistanceRatio);
  totalAssistanceRatio += assistanceBagDropRatio * (1 - totalAssistanceRatio);

  const parameters = initialValues.parameters;
  const [layout, setLayout] = useState<StrategicLayout>(parameters?.layout || EMPTY_LAYOUT);
  const [submitting, setSubmitting] = useState(false);
  const [date, setDate] = useState<string>(initialValues?.parameters?.date);

  const [allocationsFileKeyMap, setAllocationsFileKeyMap] = useState({});
  const allocationsFileKey = allocationsFileKeyMap[date] || initialValues.parameters.flight_allocation_path;

  const { data: flightSchedule, error: flightScheduleError } = useFlightSchedule(dayjs(date || "").toDate());

  useEffect(() => {
    if (flightScheduleError) {
      notification.error({
        message: "Failed to fetch flight schedule for selected date",
        duration: 0,
      });
    }
  }, [flightScheduleError]);

  const airlineOptions = flightSchedule
    ? _.uniq(flightSchedule.map((flight) => flight.flightNumber.split(" ")[0])).map((iataCode) => ({
        label: mapIataCode(iataCode),
        value: iataCode,
      }))
    : [];
  const { data: allocations } = useAllocations(allocationsFileKey);
  const [dateOptions, setDateOptions] = useState([]);

  useEffect(() => {
    if (allocations?.length) {
      const conventionalRows = _(allocations)
        .filter((allocation) => allocation.checkinDesk <= 7)
        .map((allocation) => allocation.checkinRow)
        .uniq()
        .value();
      setLayout({
        sbd: layout.sbd.filter((row) => !conventionalRows.includes(row)),
        new_gen: layout.new_gen.filter(
          (row) => !conventionalRows.includes(parseInt(row.split("-")[0]) && !conventionalRows.includes(parseInt(row.split("-")[1])))
        ),
      });
    }
  }, [allocations, form]);

  return (
    <Modal
      open={true}
      title={initialValues.status === NEW ? "New simulation" : "Edit simulation"}
      width={"80%"}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose={true}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={async () => {
            form.submit();
          }}
          icon={submitting && <LoadingOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={async (values) => {
          setSubmitting(true);
          const api = await getAPI();
          values.parameters.layout = layout;
          // @ts-ignore
          await api.post("/strategic-simulation", values);
          onClose();
          setSubmitting(false);
        }}
        initialValues={initialValues}
        autoComplete="off"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelWrap
        scrollToFirstError={true}
        onValuesChange={(changedValue) => {
          if (changedValue?.parameters?.date) {
            const { parameters } = changedValue;
            const { date } = parameters;
            const allocationFilePath = allocationsFileKeyMap[date];
            form.setFieldValue(["parameters", "flight_allocation_path"], allocationFilePath);
            setDate(date);
          }
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          className="simulation-name"
          rules={[
            {
              required: true,
              message: "Missing name",
            },
            {
              validator: async (_, value) => {
                if (initialValues?.status === NEW && !!(simulations || []).find((s) => s.name === value)) {
                  throw new Error("Note: a simulation with the same name already exists");
                }
              },
              warningOnly: true,
            },
          ]}
        >
          <Input
            className="simulation-name"
            placeholder="Simulation Name"
            disabled={initialValues?.status !== NEW}
            autoFocus={initialValues?.status === NEW}
          />
        </Form.Item>
        <Form.Item
          name={["parameters", "allocation_filename"]}
          label="Allocation file"
          rules={[
            {
              required: true,
              message: "Missing allocation file",
            },
          ]}
        >
          <FileUpload
            accept=".csv"
            onUpload={async (file) => {
              try {
                const api = await getAPI();
                const { data: url } = await api.get(
                  `/files-presigned-url/${encodeURIComponent(`strategic_layout_allocations/${new Date().getTime()}_${file.name}`)}`,
                  {
                    params: {
                      kind: "strategicAllocations",
                    },
                  }
                );
                await axios.put(url, file);
                const bucket = url.split("https://")[1].split(".s3.")[0];
                const key = url.split(".com/")[1].split("?")[0];
                const filePath = `s3://${bucket}/${key}`;
                const { data: paths } = await api.post(`/process-strategic-flight-allocations-file`, {
                  params: {
                    path: filePath,
                  },
                });

                const { errorMessage: maybeError, errorType } = paths;

                if (maybeError) {
                  throw new Error("Failed to process file: " + errorType + ": " + maybeError);
                }

                const dateOptions = [].concat(
                  paths.map((path) => {
                    const datePattern = /\d{4}-\d{2}-\d{2}/g;
                    const [date] = path.match(datePattern) || [];
                    return { label: date, value: date };
                  })
                );

                const allocationsFileKeyMap = {};
                paths.map((path) => {
                  const datePattern = /\d{4}-\d{2}-\d{2}/g;
                  const date = path.match(datePattern);
                  allocationsFileKeyMap[date] = path;
                });

                setAllocationsFileKeyMap(allocationsFileKeyMap);
                setDateOptions(dateOptions);

                return filePath;
              } catch (e) {
                throw new Error("Failed to process file");
              }
            }}
          />
        </Form.Item>
        {(dateOptions.length !== 0 || date.length) && (
          <Form.Item
            name={["parameters", "date"]}
            label="Date"
            rules={[
              {
                required: true,
                message: "Missing date",
              },
            ]}
            extra={
              <>
                {allocations && (
                  <Button
                    icon={<EyeOutlined />}
                    type={"link"}
                    onClick={() => {
                      Modal.info({
                        title: "Allocations",
                        content: <AllocationRendering allocations={allocations || []} />,
                        width: "65%",
                      });
                    }}
                  >
                    View allocations
                  </Button>
                )}
                {flightSchedule && (
                  <Button
                    type={"link"}
                    icon={<EyeOutlined />}
                    onClick={() => {
                      Modal.info({
                        title: "Flight Schedule",
                        content: <FlightScheduleRendering flightSchedule={flightSchedule || []} />,
                        width: "65%",
                      });
                    }}
                  >
                    View flight schedule
                  </Button>
                )}
              </>
            }
          >
            <Select options={dateOptions}></Select>
          </Form.Item>
        )}
        <Form.Item hidden={true} name={["parameters", "flight_allocation_path"]}></Form.Item>
        <div>
          <WithWidth>
            <SBDQueueChart layout={layout || EMPTY_LAYOUT} simulation={null} stackBy={"company"} />
          </WithWidth>
          <Form.Item
            name={["parameters", "sbd_allocations"]}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                validator: async (__, value) => {
                  const flightsNotAssigned = (flightSchedule || []).filter(
                    (flight) => !(allocations || []).find((allocation) => allocation.flightNumber === flight.flightNumber)
                  );
                  const airlinesToAssign = _.uniq(flightsNotAssigned?.map((flight) => flight.flightNumber.split(" ")[0]));
                  const unassignedAirlines: string[] = [];

                  airlinesToAssign.forEach((airline) => {
                    const assignedAirlines = _.flatten(Object.keys(value || {}).map((key) => value[key]));
                    if (!assignedAirlines.includes(airline)) {
                      unassignedAirlines.push(airline);
                    }
                  });

                  if (!(unassignedAirlines.length === 0))
                    throw new Error(`Airlines ${unassignedAirlines.join(", ")} are not assigned to any SBD row`);
                },
              },
            ]}
          >
            <LayoutBuilder
              allocations={allocations}
              layout={layout}
              setLayout={setLayout}
              flightSchedule={flightSchedule}
              disabled={!allocations || !flightSchedule}
              disabledSbdRows={_(allocations || [])
                .filter((allocation) => allocation["check-inDesk"] <= 7)
                .map((allocation) => allocation["check-inRow"])
                .uniq()
                .map((row) => parseInt(row))
                .value()}
              disabledNewGenRows={_(allocations || [])
                .map((allocation) => allocation["check-inRow"])
                .uniq()
                .map((row) => parseInt(row))
                .value()}
            />
          </Form.Item>
        </div>
        <Collapse ghost>
          <Collapse.Panel key="1" header="Extra parameters" forceRender={true}>
            <Row>
              <Col span={10}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "conventional_checkin_handling_time"]}
                  label="Conventional check-in handling time"
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "self_checkin_handling_time"]}
                  label="Self check-in handling time"
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "bag_tag_handling_time"]}
                  label="Bag tag handling time"
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "bag_drop_handling_time"]}
                  label="Bag drop handling time"
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "assistance_desk_handling_time"]}
                  label="Assistance desk handling time"
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "assistance_self_checkin_ratio"]}
                  label="Assistance self check-in ratio"
                  normalize={(e) => e / 100}
                  getValueProps={(e) => ({ value: Number(e) * 100 })}
                >
                  <InputNumber step={5} min={0} max={100} formatter={(d) => numeral(Number(d) / 100).format("%")} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "assistance_bag_tag_ratio"]}
                  label="Assistance bag tag ratio"
                  normalize={(e) => e / 100}
                  getValueProps={(e) => ({ value: Number(e) * 100 })}
                >
                  <InputNumber step={5} min={0} max={100} formatter={(d) => numeral(Number(d) / 100).format("%")} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "assistance_bag_drop_ratio"]}
                  label="Assistance bag drop ratio"
                  normalize={(e) => e / 100}
                  getValueProps={(e) => ({ value: Number(e) * 100 })}
                >
                  <InputNumber step={5} min={0} max={100} formatter={(d) => numeral(Number(d) / 100).format("%")} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  label="Total assistance ratio"
                  normalize={(e) => e / 100}
                  getValueProps={(e) => ({ value: Number(e) * 100 })}
                >
                  <InputNumber
                    step={5}
                    min={0}
                    max={100}
                    value={totalAssistanceRatio}
                    disabled={true}
                    formatter={(d) => numeral(Number(d)).format("%")}
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "pax_having_bag_ratio"]}
                  label="Pax having bag ratio"
                  normalize={(e) => e / 100}
                  getValueProps={(e) => ({ value: Number(e) * 100 })}
                >
                  <InputNumber step={5} min={0} max={100} formatter={(d) => numeral(Number(d) / 100).format("%")} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "online_checkin_ratio"]}
                  label="Online check-in ratio"
                  normalize={(e) => e / 100}
                  getValueProps={(e) => ({ value: Number(e) * 100 })}
                >
                  <InputNumber step={5} min={0} max={100} formatter={(d) => numeral(Number(d) / 100).format("%")} />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "business_pax_ratio"]}
                  label="Business pax ratio"
                  normalize={(e) => e / 100}
                  getValueProps={(e) => ({ value: Number(e) * 100 })}
                >
                  <InputNumber step={5} min={0} max={100} formatter={(d) => numeral(Number(d) / 100).format("%")} />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "sbd_zone_n_servers", "check-in-kiosk"]}
                  label={"Number of check-in kiosks per SBD zone"}
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "sbd_zone_n_servers", "bag-tag-kiosk"]}
                  label={"Number of bag tag kiosks per SBD zone"}
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "sbd_zone_n_servers", "bag-drop"]}
                  label={"Number of bag drops per SBD zone"}
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing value",
                    },
                  ]}
                  name={["parameters", "sbd_zone_n_servers", "assistance-desk"]}
                  label={"Number of assistance desks per SBD zone"}
                >
                  <InputNumber step={1} min={0} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 12 }}
                  name={["parameters", "airline_handling_times"]}
                  label="Conventional check-in handling time by airline"
                >
                  <AirlineHandlingTimes airlineOptions={airlineOptions} />
                </Form.Item>

                {/*<Form.Item name={["parameters", "extra"]} label="By airlines">*/}
                {/*  <CustomAirlineParameters airlines={} />*/}
                {/*</Form.Item>*/}
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};

export default ParameterForm;
