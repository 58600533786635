import React, { useEffect, useState, useRef } from "react";
import { ScenarioDay } from "shared/ScenarioDay";
import * as Plot from "@observablehq/plot";
import moment from "moment";
import _ from "lodash";
import { useSize } from "../../hooks/hooks";
import Explanation from "../UI/Explanation";
import { FlightSchedule } from "shared/FlightSchedule";

const TrafficChart: React.FC<{
  scenarioDay: {
    data: {
      flightSchedule: Omit<FlightSchedule[number], "date">[];
    };
  };
}> = ({ scenarioDay }) => {
  const ref = useRef();
  const { width } = useSize(ref.current);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (scenarioDay) {
      const flightSchedule = _(scenarioDay?.data?.flightSchedule || [])
        .map((d) => ({
          ...d,
          departingTime: moment(d.departingTime).hour() + Math.round((moment(d.departingTime).minute() / 60) * 4) / 4,
        }))
        .groupBy("departingTime")
        .map((v, k) => {
          const k_ = parseFloat(k);
          return {
            departingTime: moment
              .utc(0)
              .hour(k_)
              .minute(k_ % 1 === 0.25 ? 15 : k_ % 1 === 0.5 ? 30 : k_ % 1 === 0.75 ? 45 : 0),
            // departingGate: string;
            economyPax: _(v).sumBy("economyPax"),
            businessPax: _(v).sumBy("businessPax"),
            totalPax: _(v).sumBy("totalPax"),
          };
        });

      const chart = Plot.plot({
        height: 200,
        y: {
          grid: true,
          label: "↑ pax",
        },
        x: {
          label: "",
          tickFormat: (d) => (moment(d).minute() === 0 && moment(d).hour() % 2 ? moment(d).format("HH:mm") : null),
        },
        //@ts-ignore
        marks: [Plot.barY(flightSchedule, { x: "departingTime", y: "totalPax", fill: "lightgrey" })],
      });

      //@ts-ignore
      ref.current.append(chart);
      //@ts-ignore
      setChart(chart);

      return () => chart.remove();
    }
  }, [scenarioDay, width]);

  return (
    <div>
      <h4>
        Traffic through time <Explanation>Traffic at departure time</Explanation>
      </h4>
      {/* @ts-ignore */}
      <div id="traffic-chart" ref={ref} style={{ width: "100%" }}></div>
    </div>
  );
};

export default TrafficChart;
