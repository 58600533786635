import { Col, Row, Statistic } from "antd";
import _ from "lodash";
import React from "react";
import { extractCompanyFromFlightNumber, ScenarioDay } from "shared/ScenarioDay";
import numeral from "numeral";
import moment from "moment";
import { FlightSchedule } from "shared/FlightSchedule";

const FlightStats: React.FC<{
  scenarioDay: {
    data: {
      flightSchedule: Omit<FlightSchedule[number], "date">[];
    };
  };
}> = ({ scenarioDay }) => {
  const flightSchedule = scenarioDay?.data?.flightSchedule;

  return (
    <Row gutter={30}>
      <Col>
        <Statistic title={"Total pax"} value={_(flightSchedule).sumBy("totalPax")} formatter={(v) => numeral(v).format("0.[0]a")} />
      </Col>
      <Col>
        <Statistic title={"Flights"} value={_(flightSchedule).map("flightNumber").uniq().size()} />
      </Col>
      <Col>
        <Statistic title={"Companies"} value={_(flightSchedule).map("flightNumber").map(extractCompanyFromFlightNumber).uniq().size()} />
      </Col>
      <Col>
        <Statistic
          title={"Average pax / flight"}
          value={_(flightSchedule).map("totalPax").mean()}
          formatter={(v) => numeral(v).format()}
          //suffix={<small>{numeral(_(scenarioDay.data.flightSchedule).map("businessPax").mean()).format()} business</small>}
        />
      </Col>

      <Col>
        <Statistic
          title={"Busiest flight"}
          value={_(flightSchedule).reduce((acc, d) => (d.totalPax > acc.totalPax ? d : acc), { totalPax: 0, flightNumber: "UNKNOWN" }).totalPax}
          formatter={(v) => numeral(v).format()}
          suffix={
            <small style={{ fontSize: 12, color: "grey" }}>
              {
                _(flightSchedule).reduce((acc, d) => (d.totalPax > acc.totalPax ? d : acc), {
                  totalPax: 0,
                  flightNumber: "UNKNOWN",
                }).flightNumber
              }
              {/*moment(
                _(scenarioDay.data.flightSchedule).reduce((acc, d) => (d.totalPax > acc.totalPax ? d : acc), { totalPax: 0, departureTime: null })
                  .departureTime
              ).format("HH:mm")*/}
            </small>
          }
          //suffix={<small>{numeral(_(scenarioDay.data.flightSchedule).map("businessPax").mean()).format()} business</small>}
        />
      </Col>
      <Col>
        <Statistic
          title={"Busiest time"}
          value={
            _(flightSchedule)
              .groupBy((d) => moment(d.departingTime).hour() + Math.round((moment(d.departingTime).minute() / 60) * 4) / 4)
              .map((v, k) => ({ hour: k, pax: _.sumBy(v, "totalPax") }))
              .maxBy("pax")?.hour
          }
          formatter={(v) => {
            if (!v) return "?";
            if (typeof v === "string") v = parseFloat(v);
            return moment
              .utc(0)
              .hours(v as number)
              .minute(v % 1 === 0.25 ? 15 : v % 1 === 0.5 ? 30 : v % 1 === 0.75 ? 45 : 0)
              .format("HH:mm");
          }}
          //suffix={<small>{numeral(_(scenarioDay.data.flightSchedule).map("businessPax").mean()).format()} business</small>}
        />
      </Col>
    </Row>
  );
};

export default FlightStats;
