import { Progress, Tooltip } from "antd";
import _ from "lodash";
import { useMemo } from "react";
import { extractCompanyFromFlightNumber } from "shared/ScenarioDay";
import numeral from "numeral";
import { mapIataCode } from "shared/constants/iata_airlines";

const mdPalette = [
  "#ffc107",
  "#2196f3",
  "#f44336",
  "#673ab7",
  "#8bc34a",
  "#e91e63",
  "#9c27b0",
  "#4caf50",
  "#795548",
  "#00bcd4",
  "#ff9800",
  "#3f51b5",
  "#cddc39",
  "#607d8b",
  "#009688",
  "#ff5722",
  "#03a9f4",
  "#ffeb3b",
];

function colorGenerator() {
  let index = 0;
  const cache = {};
  return function (item) {
    if (cache[item]) {
      return cache[item];
    }
    const color = mdPalette[index++ % mdPalette.length];
    cache[item] = color;
    return color;
  };
}

const CompanyRepartition = ({ scenarioDay }) => {
  const getColor = useMemo(() => colorGenerator(), []);

  const paxPerCompany = useMemo(
    () =>
      _(scenarioDay.data.flightSchedule)
        .groupBy((d) => extractCompanyFromFlightNumber(d.flightNumber))
        .map((v, k) => ({ company: k, pax: _.sumBy(v, "totalPax"), flights: v.length }))
        .value(),
    [scenarioDay.data.flightSchedule]
  );
  const totalPax = _.sumBy(paxPerCompany, "pax");

  const BIGGEST_THRESHOLD = 5;
  const biggestCompanies = useMemo(
    () =>
      _(paxPerCompany)
        .orderBy("pax", "desc")
        .filter((_, i) => i < BIGGEST_THRESHOLD)
        .map("company")
        .value(),
    [paxPerCompany]
  );
  const othersCompanies = useMemo(
    () =>
      _(paxPerCompany)
        .orderBy("pax", "desc")
        .filter((_, i) => i >= BIGGEST_THRESHOLD)
        .map("company")
        .value(),
    [paxPerCompany]
  );

  const companyColor = useMemo(
    () =>
      Object.fromEntries(
        _(biggestCompanies)
          .map((d) => [d, getColor(d)])
          .value()
      ),
    [biggestCompanies, getColor]
  );
  return (
    <div>
      <h4>Traffic by company</h4>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 20 }}>
        {biggestCompanies.map((company) => (
          <div key={company} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Progress
              type="dashboard"
              status="normal"
              width={80}
              strokeColor={companyColor[company]}
              percent={((paxPerCompany.find((d) => d.company === company)?.pax || 0) / totalPax) * 100}
              format={(percent) => (
                <>
                  {numeral((percent || 0) / 100).format("%")}
                  <br />
                  <small style={{ fontSize: 12, color: "grey" }}>{numeral(((percent || 0) / 100) * totalPax).format("0.[0]a")} pax</small>
                </>
              )}
            />
            {mapIataCode(company)}
          </div>
        ))}
        {(othersCompanies?.length || null) && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Progress
              type="dashboard"
              status="normal"
              width={80}
              strokeColor={"lightgrey"}
              percent={
                (_(paxPerCompany)
                  .filter((d) => othersCompanies.includes(d.company))
                  .sumBy("pax") /
                  totalPax) *
                100
              }
              format={(percent) => (
                <>
                  {numeral((percent || 0) / 100).format("%")}
                  <br />
                  <small style={{ fontSize: 12, color: "grey" }}>{numeral(((percent || 0) / 100) * totalPax).format("0.[0]a")} pax</small>
                </>
              )}
            />
            <Tooltip overlay={othersCompanies.map(mapIataCode).join(" | ")} placement="rightTop">
              Others
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyRepartition;
