/**
 * key is the regex to match the id of the queue element.
 * value is the svg id of the overfloxw area.
 * If the queue is full, place the passengers in the overflow area.
 */
export const OVERFLOW_AREA_BINDING: Record<string, string> = {
  "r1_queue[0-9]+": "overflow_1/2/3/4",
  "r2_queue[0-9]+": "overflow_1/2/3/4",
  "r3_queue[0-9]+": "overflow_1/2/3/4",
  "r4_queue[0-9]+": "overflow_1/2/3/4",
  "r5_queue[0-9]+": "overflow_5/6",
  "r6_queue[0-9]+": "overflow_5/6",
  "r7_queue[0-9]+": "overflow_7/8",
  "r8_queue[0-9]+": "overflow_7/8",
  "r9_queue[0-9]+": "overflow_9/10",
  "r10_queue[0-9]+": "overflow_9/10",
  "r11_queue[0-9]+": "overflow_11/12/14",
  "r12_queue[0-9]+": "overflow_11/12/14",
  "r14_queue[0-9]+": "overflow_11/12/14",
  r1_bagtag_queue: "overflow_1/2/3/4",
  r2_bagtag_queue: "overflow_1/2/3/4",
  r3_bagtag_queue: "overflow_1/2/3/4",
  r4_bagtag_queue: "overflow_1/2/3/4",
  r5_bagtag_queue: "overflow_5/6",
  r6_bagtag_queue: "overflow_5/6",
  r7_bagtag_queue: "overflow_7/8",
  r8_bagtag_queue: "overflow_7/8",
  r9_bagtag_queue: "overflow_9/10",
  r10_bagtag_queue: "overflow_9/10",
  r11_bagtag_queue: "overflow_11/12/14",
  r12_bagtag_queue: "overflow_11/12/14",
  r14_bagtag_queue: "overflow_11/12/14",
};
