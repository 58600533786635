import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";

const Explanation = ({ placement = "top", children }) => {
  return (
    <Tooltip placement={placement} title={children}>
      <Button size="small" type="link" style={{ padding: 0 }}>
        <InfoCircleOutlined />
      </Button>
    </Tooltip>
  );
};

export default Explanation;
